import { Navigate } from 'react-router-dom';
import storage from '../../../services/Share/storageWithEncryption';

type Props = {
  component: JSX.Element;
  roles: string[];
}

const ProtectedRoutes = ({ component, roles }: Props) => {
  const userRole = storage.getDataFromLocalStorage('userRole');

  if (!userRole) {
    return <Navigate to='/Login' replace />;
  }

  if (roles && !roles.includes(userRole)) {
    return <Navigate to='/Orders' replace />;
  }

  return component;
};

export default ProtectedRoutes;
import TextField from "@mui/material/TextField";
import { Alert, Box, Button, Grid, IconButton, Link, useMediaQuery } from "@mui/material";
import { Clear, Visibility, VisibilityOff } from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { FormEvent, useState } from "react";

import accountService from "../../../services/Account/AccountService";
import "./Login.module.css"
import { beta, materialiseLogo, login } from "../../../statics/images"
import LicenseAgreement from "../../Components/License/LicenseAgreement";
import { useNavigate } from "react-router-dom";



export default function Login({ setIsLogged }: { setIsLogged: (isLogged: boolean) => void })
    {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
    const [isWrong, setIsWrong] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const navigate = useNavigate();
    const desktopScreen = useMediaQuery('(min-width:900px)');

    function validateForm() {
        email === "" ? setIsEmailEmpty(true) : setIsEmailEmpty(false);
        password === "" ? setIsPasswordEmpty(true) : setIsPasswordEmpty(false);
        setIsWrong(false);
        return !(email === "" || password === "");
    }
    
    async function handleLogin() {
        try {
            await accountService.getToken(email, password);
            setIsLogged(true);
            navigate("/Orders");
        }
        catch {
            setIsWrong(true);
        }
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (validateForm())
            handleLogin();
    }

    const handleClickOpen = () => () => {
        setOpenDialog(true);
    };
    
    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex' }}>
            <Dialog
                maxWidth='lg'
                open={openDialog}
                onClose={handleClose}
                scroll={'body'}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogContent dividers>
                        <LicenseAgreement />
                </DialogContent>
            </Dialog>
            <Box sx={{ width: desktopScreen ? '40%' : '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box>
                    {desktopScreen ? null : <Alert severity="warning" sx={{ position: 'absolute' }}>This Web App is only supported on Desktop.</Alert>}
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: desktopScreen ? '5vh' : '7vh', lineHeight: "55px" }}>
                        <Box>
                            <Box sx={{ fontWeight: "300", fontSize: "71px" }}>Materialise</Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ fontWeight: "300", fontSize: "26px" }}>Order Flow Management</Box>
                            <img alt="Beta" src={beta} style={{ marginLeft: "5px", bottom: "-18px", alignSelf: 'start', position: "relative" }} />
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: '30px', marginBottom: '40px' }}>
                        <h3 style={{ maxHeight: "10px", textAlign: "left" }}>Log in</h3>
                    </Box>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={5} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email"
                                    sx={{ textAlign: 'left', maxWidth: '400px' }}
                                    InputLabelProps={{ shrink: true }}
                                    error={isEmailEmpty}
                                    value={email}
                                    type="text"
                                    variant="standard"
                                    placeholder="example@email.com"
                                    helperText={isEmailEmpty ? "The Email field is required." : ""}
                                    onChange={e => {
                                        setEmail(e.target.value);
                                        setIsEmailEmpty(false);
                                    }} fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton aria-label="delete"
                                            onClick={() => setEmail("")}
                                            ><Clear /></IconButton>
                                        )
                                    }}
                                    >
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Password"
                                    sx={{ textAlign: 'left', maxWidth: '400px' }}
                                    InputLabelProps={{ shrink: true }}
                                    error={isPasswordEmpty}
                                    value={password}
                                    type={showPassword ? 'text' : 'password'}
                                    variant="standard"
                                    placeholder="Enter password"
                                    helperText={isPasswordEmpty? "The Password field is required." : ""}
                                    onChange={e => {
                                        setPassword(e.target.value);
                                        setIsPasswordEmpty(false);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        )
                                    }}
                                    fullWidth
                                >
                                </TextField>
                                {isWrong ?
                                    <Box sx={{ marginTop: '30px', textAlign: 'center', color: 'red', fontSize: 'small' }}>
                                        Credentials are wrong.
                                    </Box>
                                    :
                                    <></>
                                }
                                   <Box sx={{ marginTop: '20px', textAlign: 'right', color: 'blue', fontSize: 'small' }}>
                                   <Link href="/ResetPassword" >Forgot Password</Link>
                                    </Box>
                                <Box sx={{ marginTop: '10px' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ borderRadius: '0', marginTop: '1rem', maxWidth: '400px' }}
                                        fullWidth    
                                    >LOG IN
                                    </Button>
                                </Box>
                                <Box sx={{ marginTop: '60px', textAlign: 'center' }}>
                                  
                                    <img width="150" alt="MaterialiseLogo" src={materialiseLogo}></img>
                                </Box>
                                <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                                    <Link href='https://www.materialise.com/en/about' target="_blank" sx={{marginLeft:'10px'}} underline="hover" color="inherit" variant="body2">About</Link>
                                    <Link href='mailto:support.motion@materialise.be' sx={{marginLeft:'10px'}} underline="hover" color="inherit" variant="body2">Contact support</Link>
                                    <Link onClick={handleClickOpen()} sx={{marginLeft:'10px', cursor:'pointer'}} underline="hover" color="inherit" variant="body2">End-User License Agreement</Link>
                                </Box>
                              
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box>
            {desktopScreen ? <Box sx={{ width: '60%', backgroundImage: `url(${login})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} ></Box> : null}
        </Box>
    );
}
import { IOrderListCustomerSupport } from "../../domain";
import { IFilter, IListRequestBody } from "../../domain/Common/RequestBody";
import { IRequestResponse } from "../../domain/Common/RequestResponse";
import { IDelayOrders } from "../../domain/Orders/DelayOrders";
import { IOrderIds } from "../../domain/Orders/OrderIds";
import { IOrderListAdmin } from "../../domain/Orders/OrderListAdmin";
import { IOrderListProductOperator } from "../../domain/Orders/OrderListProductOperator";

import http from "../Share/httpCommon";

class OrdersService {
  async getOrdersForCustomerSupportRole(body: IListRequestBody): Promise<IRequestResponse<IOrderListCustomerSupport>> {
    return await http.api.post<IRequestResponse<IOrderListCustomerSupport>>('/v1/order/getOrdersForCustomerSupportRole', body);
  }

  async getFiltersForCustomerSupportRole(): Promise<IFilter[]> {
    return await http.api.get<IFilter[]>('/v1/order/getFiltersForCustomerSupportRole');
  }

  async getOrdersForAdminRole(body: IListRequestBody): Promise<IRequestResponse<IOrderListAdmin>> {
    return await http.api.post<IRequestResponse<IOrderListAdmin>>('/v1/order/getOrdersForAdminRole', body);
  }

  async getFiltersForAdminRole(): Promise<IFilter[]> {
    return await http.api.get<IFilter[]>('/v1/order/getFiltersForAdminRole');
  }

  async getOrdersForProductionOperatorRole(body: IListRequestBody): Promise<IRequestResponse<IOrderListProductOperator>> {
    return await http.api.post<IRequestResponse<IOrderListProductOperator>>('/v1/order/getOrdersForProductionOperatorRole', body);
  }

  async getFiltersForProductOperatorRole(): Promise<IFilter[]> {
    return await http.api.get<IFilter[]>('/v1/order/getFiltersForProductOperatorRole');
  }

  async takeAutoDesignQC(orderId: number): Promise<void> {
    return await http.api.post(`/v1/design/takeAutoDesignQC?orderId=${orderId}`);
  }

  async takeManualDesign(orderId: number): Promise<void> {
    return await http.api.post(`/v1/design/takeManualDesign?orderId=${orderId}`, null);
  }

  async approveAutoDesignQC(orderId: number): Promise<void> {
    return await http.api.post(`/v1/design/approveAutoDesignQC?orderId=${orderId}`);
  }

  async failedAutoDesignQC(orderId: number): Promise<void> {
    return await http.api.post(`/v1/design/disapproveAutoDesignQC?orderId=${orderId}`);
  }

  async getDesignFile(orderId: number): Promise<any> {
    return await http.api.post(`/v1/Files/designFiles?orderId=${orderId}`, null, { responseType: 'blob' }, false);
  }

  async uploadDesignFile({ orderId, fileType, data }: { orderId: number, fileType: number, data: File }): Promise<any> {
    let form = new FormData();
    form.append("formFile", data, data.name);
    let config = { headers: { "Content-type": "multipart/form-data" }};
    return await http.api.post(`/v1/design/uploadManualDesign?orderId=${orderId}&fileType=${fileType}`, form, config);
  }

  async finishManualDesign(orderId: number): Promise<any> {
    return await http.api.post(`/v1/design/finishManualDesign?orderId=${orderId}`, null);
  }

  async getProductionFile(orderId: number): Promise<any> {
    return await http.api.post(`/v1/Files/productionFiles?orderId=${orderId}`, null, { responseType: "blob" }, false);
  }

  async cancelOrder(orderId: number): Promise<any> {
    return await http.api.post(`/v1/order/cancelOrder/${orderId}`, null);
  }

  async shipOrders(orderIds: IOrderIds): Promise<any> {
    return await http.api.post(`/v1/order/shipOrders`, orderIds);
  }

  async delayOrders(delayOrdersInfo: IDelayOrders): Promise<any> {
    return await http.api.post(`/v1/order/delayOrders`, delayOrdersInfo);
  }

  async getOrderDetails(id: string) {
    return await http.api.get(`/v1/order/getOrderDetails?id=${id}`);
  }

  async getOrderFile({ orderId, fileType }: { orderId: number, fileType: number }) {
    return await http.api.get(`/v1/Files/getOrderFile?orderId=${orderId}&fileType=${fileType}`, null, false);
  }

  [key: string]: (body?: any) => Promise<any>;
}

let ordersService = new OrdersService();
export default ordersService;
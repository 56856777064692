import Box from "@mui/material/Box";
import { NavigateFunction, useParams } from "react-router-dom";
import Grid from "../../Components/Grid/Grid";
import defaultColumnPreferences from "./ColumnDefaultPreferences";
import { IListRequestBody } from "../../../domain/Common/RequestBody";
import { useState } from "react";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import officesService from "../../../services/Offices/OfficesService";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

type Props = {
  navigate: NavigateFunction;
}

const titleStyle = { fontFamily: "Metronic Pro", fontSize: "1.2rem", fontWeight: "700", marginTop: "3px" };

export default function Offices({ navigate }: Props) {
  const officeColumns = require(`./OfficesColumns`).default;
  const gridPageSize = 50;
  const paginationSize = 100;
  const { companyId } = useParams();

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: gridPageSize });
  const [query, setQuery] = useState<IListRequestBody>({
    page: 0,
    pageSize: 100,
    sortingDirection: 0,
    sortBy: "",
    filters: [{
      filterType: "companyId",
      filterValues: [companyId ?? '']
    }],
  });

  const onRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel((r) => {
      if (newRowSelectionModel.length - r.length > gridPageSize) {
        const firstRow = paginationModel.page * gridPageSize;
        return newRowSelectionModel.slice(firstRow, firstRow + gridPageSize);
      }
      return newRowSelectionModel;
    });
  };

  const handleAddOfficeClick = () => {
    navigate(`Office/${companyId}`)
  }

  const handleEditOfficeClick = (params: any) => {
    navigate(`Office/${companyId}/${params.id}`)
  }

  return (
    <Card sx={{ height: '61.5vh' }}>
      <CardHeader title={'Offices'} titleTypographyProps={titleStyle} action={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button onClick={handleAddOfficeClick} startIcon={<AddCircleIcon />}
            sx={{ position: "absolute", right: "75px", marginTop:'85px', textTransform: "none", fontFamily: "Metronic Pro" }}>
              Office
          </Button>
        </Box>}
      />
      <CardContent sx={{ height: "88%" }}>
          <Grid
            defaultColumnPreferences={defaultColumnPreferences}
            columnPreferencesName="officesColumnPreferences"
            columns={officeColumns}
            gridPageSize={gridPageSize}
            paginationSize={paginationSize}
            isServerSideFilterData={true}
            isServerSideOrderData={true}
            getData={officesService.getOfficeList}
            getFilters={officesService.getOfficeFilters}
            onRowSelectionModelChange={onRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
            onRowDoubleClick={handleEditOfficeClick}
            paginationModel={[paginationModel, setPaginationModel]}
            queryOptions={[query, setQuery]}
            getRowId="id"
          />
      </CardContent>
    </Card>
  );
}

import { ChangeEvent, FormEvent, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Popup from "../../Components/Popup/Popup";
import CustomLabel from "../../Components/CustomLabel/CustomLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UserService from "../../../services/Users/UsersService";
import OfficesService from "../../../services/Offices/OfficesService";
import CompaniesService from "../../../services/Companies/CompaniesService";
import { NewUserLabel } from "../../../domain/Users/newUser";
import { isPossiblePhoneNumber , getCountryCallingCode, getCountries, Country } from 'react-phone-number-input'

import { Company } from "../../../domain/Common/Company";
import { Office } from "../../../domain/Common/Office";
import { AxiosError } from "axios";
import { IUser } from "../../../domain/Users/IUser";
import { NavigateFunction } from "react-router-dom";
import { Checkbox, Select, SelectChangeEvent } from "@mui/material";
import DetailContainer from "../../Components/Common/DetailContainer";
import { UserRoles } from "../../../domain/Users/userRoles";

const userRoles = [
  { "Sales Assistant": "CustomerSales" },
  { "Customer Production Operator": "ProductionOperator" },
  { "Customer Support": "CustomerSupport" },
  { "MTLS Engineer": "MTLSEngineers" },
  { "Motion Admin": "MotionAdmin" },
  { "Chief Practitioner": "ChiefPractitioner" },
  { "Practitioner": "Practitioner" },
];

const textFieldStyle = {
  boxShadow: "3",
  height: "60px",
  borderRadius: "5px",
  justifyContent: "center",
  textAlign: "left",
  paddingLeft: "20px",
  paddingRight: "10px",
};

interface Props {
  navigate: NavigateFunction;
}

export default function AddUser(props: Props) {
  const [tabIndex, setTabIndex] = useState(0);
  const [newUser, setNewUser] = useState<IUser>({
    emailAddress: "",
    office: "",
    name: "",
    middleName: "",
    familyName: "",
    phoneNumber: "",
    role: "",
    allowedCompanies: []
  });
  const [company, setCompany] = useState<string>('');
  const [countryPhone, setCountryPhone] = useState<Country>('BE');
  const [phone, setPhone] = useState<string>('');

  const [displayLabel, setDisplayLabel] = useState<NewUserLabel>({
    emailAddress: true,
    name: true,
    familyName: true,
    middleName: true,
    phoneNumber: true,
    role: true,
    office: true,
    company: true,
    allowedCompanies: true,
  });
  const [companyNames, setCompanyNames] = useState<Company[]>([]);
  const [officeNames, setOfficeNames] = useState<Office[]>([]);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [displayAction, setDisplayAction] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [disableAllowedCompanies, setDisableAllowedCompanies] = useState(true);

  useEffect(() => { }, []);
  function validateEmail(email: string): boolean {
    const emailRegExp: RegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegExp.test(email);
  }
  function handleSubmit() {
    setDisplayAction(false);

    if (!isPossiblePhoneNumber(newUser.phoneNumber)) {
      setPhoneError(true);
      return;
    }
    if (!validateEmail(newUser.emailAddress)) {
      setEmailError(true);
      return;
    }

    UserService.createUser(newUser)
      .then(() => setDisplayConfirmation(true))
      .catch((error: AxiosError<any>) => {
        if (error.response?.status === 409) alert("User with this email: " + newUser.emailAddress + " already Exist");
        else alert("Something happened trying to save the User");
      });
  }

  function onSubmitClick(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setDisplayAction(true);
    let completePhoneNumber = `+${getCountryCallingCode(countryPhone)}${phone}`;
    handleChange({ target : { value: completePhoneNumber} } as any, "phoneNumber");
    return;
  }

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) {
    let newValue = event.target.value;
    if (fieldName === "role") {
      setCompany('');
      setNewUser({ ...newUser, role: newValue, allowedCompanies: [] });
      newValue
        ? setDisplayLabel({ ...displayLabel, role: false, company: true, allowedCompanies: true })
        : setDisplayLabel({ ...displayLabel, role: true, company: true, allowedCompanies: true });
      return;
    }
    if (fieldName === "company") {
      setCompany(newValue);
      const company = companyNames.find((company) => company.name === newValue);
      setNewUser((oldState) => ({ ...oldState, allowedCompanies: [Number(company?.id)] }))
      newValue
        ? setDisplayLabel({ ...displayLabel, company: false, allowedCompanies: false })
        : setDisplayLabel({ ...displayLabel, company: true, allowedCompanies: true });
      return
    }
    setNewUser({ ...newUser, [fieldName]: newValue });
    newValue
      ? setDisplayLabel({ ...displayLabel, [fieldName]: false })
      : setDisplayLabel({ ...displayLabel, [fieldName]: true });
      return;
  }

  function handleRoleChange(event: ChangeEvent<HTMLInputElement>) {
    handleChange(event, "role");
    const role = event.target.value;
    if (role === UserRoles.CustomerSupport || role === UserRoles.ProductionOperator)
      setDisableAllowedCompanies(false);
    else setDisableAllowedCompanies(true);
    CompaniesService.getCompanies(event.target.value).then((result: Company[]) => setCompanyNames(result));
  }

  function handleConfirmation() {
    setDisplayConfirmation(false);
    props.navigate("/Users");
  }

  function handleUsersClick() {
    props.navigate("/Users");
  }

  return (
    <DetailContainer>
      <Box sx={{ marginLeft: "30px", marginRight: "20px" }}>
        <Box sx={{ display: "flex" }}>
          <Box component="span" className="navigation-link" style={{ marginLeft: "50px", textDecoration: "none" }} onClick={handleUsersClick}>Users</Box>
          <Box component="span" className="navigation-link" style={{ marginLeft: "50px" }} onClick={() => window.location.reload()}>Add New User</Box>
        </Box>
        <Box style={{ width: "100vw-50px", }}>
          <form onSubmit={onSubmitClick}>
            <Box sx={{ boxShadow: 1, paddingBottom: "30px" }}>
              <Tabs className="custom-tabs" value={tabIndex} onChange={(_, e) => setTabIndex(e)} sx={{ paddingLeft: "15px", marginBottom: "10px" }}>
                <Tab className="custom-tab" label="Information" />
              </Tabs>
              <Grid container spacing={2} sx={{ paddingLeft: "25px" }}>
                <Grid item xs={4} style={{ padding: '15px' }}>
                  <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* User Role</Box>
                  <Box sx={{ position: "relative" }}>
                    <CustomLabel displayLabel={displayLabel.role} labelText="Select User Role" />
                    <TextField required select fullWidth variant="standard" sx={textFieldStyle} value={newUser.role} InputProps={{ disableUnderline: true }}
                      SelectProps={{ IconComponent: KeyboardArrowDownIcon }} onChange={handleRoleChange}
                    >
                      {userRoles.map((userRole) => {
                        const roleKey = Object.keys(userRole)[0];
                        const roleValue = Object.values(userRole)[0];
                        return (<MenuItem key={roleValue} value={roleValue}>{ roleKey }</MenuItem>);
                      })}
                    </TextField>
                  </Box>
                </Grid>
                <Grid item xs={4} style={{ padding: '15px' }}>
                  <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* Company</Box>
                  <Box sx={{ position: "relative" }}>
                    <CustomLabel displayLabel={displayLabel.company} labelText="Select Company" />
                    <TextField required select fullWidth variant="standard" sx={textFieldStyle} value={company} InputProps={{ disableUnderline: true }} SelectProps={{ IconComponent: KeyboardArrowDownIcon }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, "company");
                        OfficesService.getOffices(event.target.value).then(
                          (result: Office[]) => setOfficeNames(result)
                        );
                      }}
                    >
                      {companyNames.map((companyName: Company) => ( <MenuItem key={companyName.id} value={companyName.name}>{ companyName.name }</MenuItem> ))}
                    </TextField>
                  </Box>
                </Grid>
                <Grid item xs={4} style={{ padding: '15px' }}>
                  <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* Office/Store</Box>
                  <Box sx={{ position: "relative" }}>
                    <CustomLabel displayLabel={displayLabel.office} labelText="Select Office" />
                    <TextField required select fullWidth variant="standard" sx={textFieldStyle} value={newUser.office} InputProps={{ disableUnderline: true }} SelectProps={{ IconComponent: KeyboardArrowDownIcon }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, "office")}
                    >
                      {officeNames.map((officeName: Office) => ( <MenuItem key={officeName.id} value={officeName.name}>{ officeName.name }</MenuItem> ))}
                    </TextField>
                  </Box>
                </Grid>
                <Box width="100%" />
                <Grid item xs={4} style={{ padding: '15px' }}>
                  <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* User First Name</Box>
                  <Box sx={{ position: "relative" }}>
                    <CustomLabel displayLabel={displayLabel.name} labelText="Enter User's First Name" />
                    <TextField required fullWidth variant="standard" sx={textFieldStyle} value={newUser.name} InputProps={{ disableUnderline: true }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, "name")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4} style={{ padding: '15px' }}>
                  <Box sx={{ textAlign: "left", marginBottom: "5px" }}>User Middle Name</Box>
                  <Box sx={{ position: "relative" }}>
                    <CustomLabel displayLabel={displayLabel.middleName} labelText="Enter User's Middle Name" />
                    <TextField fullWidth variant="standard" sx={textFieldStyle} value={newUser.middleName} InputProps={{ disableUnderline: true }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, "middleName")}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4} style={{ padding: '15px' }}>
                  <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* User Last Name</Box>
                  <Box sx={{ position: "relative" }}>
                    <CustomLabel displayLabel={displayLabel.familyName} labelText="Enter User's Last Name" />
                    <TextField required fullWidth variant="standard" sx={textFieldStyle} value={newUser.familyName} InputProps={{ disableUnderline: true }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, "familyName")}
                    />
                  </Box>
                </Grid>
                <Box width="100%" />
                <Grid item xs={4} style={{ padding: '15px' }}>
                  <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* User's Email Address</Box>
                  <Box sx={{ position: "relative" }}>
                    <CustomLabel displayLabel={displayLabel.emailAddress} labelText="Enter User's Email Address" />
                    <TextField required fullWidth type="email" variant="standard" error={emailError} helperText={emailError ? "User's email is not valid" : ""}
                      sx={textFieldStyle} value={newUser.emailAddress} InputProps={{ disableUnderline: true }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, "emailAddress");
                        setEmailError(false);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4} style={{ padding: '15px' }}>
                  <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* User's Phone Number</Box>
                  <Box sx={{ position: "relative", display: "flex" }}>
                    <Grid item xs={4}>
                      <TextField required select fullWidth variant="standard" sx={textFieldStyle} value={countryPhone} InputProps={{ disableUnderline: true }} SelectProps={{ IconComponent: KeyboardArrowDownIcon }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setCountryPhone(event.target.value as Country)}
                      >
                        { getCountries().map( m => ( <MenuItem key={m} value={m}> {`${m} +${getCountryCallingCode(m)}`} </MenuItem> )) }
                      </TextField>
                    </Grid>
                    <Grid item xs={8}>
                      <Box sx={{ position: "absolute", top: "50%", left: "calc(20px + 33%)", transform: "translate(0, -50%)", display: !phone ? 'block' : 'none' }}>
                        Enter User's Phone Number
                      </Box>
                      <TextField required fullWidth variant="standard" error={phoneError} helperText={phoneError ? "User's Phone is not valid" : ""} sx={textFieldStyle} value={phone}
                        InputProps={{ disableUnderline: true }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setPhone(event.target.value);
                          setPhoneError(false);
                        }}
                      />
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={4} style={{ padding: '15px', display: newUser.role === UserRoles.MotionAdmin || newUser.role === UserRoles.MTLSEngineers ? 'none' : '' }}>
                  <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* Allowed Companies</Box>
                  <Box sx={{ position: "relative" }}>
                    <CustomLabel displayLabel={displayLabel.allowedCompanies} labelText="Select Allowed Companies" disabled={disableAllowedCompanies} />
                    <Select disabled={disableAllowedCompanies} multiple fullWidth sx={textFieldStyle} value={newUser.allowedCompanies} IconComponent={KeyboardArrowDownIcon}
                      onChange={(event: SelectChangeEvent<number[]>) => {
                        setNewUser((oldState) => ({ ...oldState, allowedCompanies: event.target.value as number[] }));
                        event.target.value.length === 0
                          ? setDisplayLabel({...displayLabel, allowedCompanies: true})
                          : setDisplayLabel({...displayLabel, allowedCompanies: false})
                      }}
                      renderValue={(selectedCompanies) => companyNames.filter((company) => selectedCompanies.includes(company.id)).map((company) => company.name).join(', ')}>
                      {companyNames.map((company) => (
                        <MenuItem key={company.id} value={company.id}><Checkbox checked={newUser.allowedCompanies!.indexOf(company.id) > -1} />{ company.name }</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
              <Button sx={{ borderRadius: "5px", fontFamily: "Metronic Pro", width: "200px", margin: "10px 0 10px 0", textTransform: "none" }} size="small" type="submit" variant="contained">
                Submit
              </Button>
            </Box>
            <Popup title="Confirmation" description="Are you sure you want to perform this action?" approveText="YES" denyText="NO" open={displayAction} handleApprove={handleSubmit} handleDeny={() => setDisplayAction(false)} />
            <Popup title="Confirmation" description="User added sucessfully!" approveText="OK" open={displayConfirmation} handleApprove={handleConfirmation} />
          </form>
        </Box>
      </Box>
    </DetailContainer>
  );
}

import { ICompany } from "../../domain";
import IAddress from "../../domain/Common/Address";
import { Company } from "../../domain/Common/Company";
import ICustomer from "../../domain/Common/Customer";
import { IFilter, IListRequestBody } from "../../domain/Common/RequestBody";
import { IRequestResponse } from "../../domain/Common/RequestResponse";
import { ICompanyDetails } from "../../domain/Company/CompanyDetalis";
import ICompanyProductAssociation from "../../domain/Product/CompanyProductAssociation";
import http from "../Share/httpCommon";

class CompaniesService {
    getCompanies(role: string) {
        return http.api.get<Company[]>('/v1/company/GetCompanies', `role=${role}`);
    }

    async getCompanyList(body: IListRequestBody) : Promise<IRequestResponse<ICompany>> {
        return (await http.api.post<IRequestResponse<ICompany>>('/v1/company/getCompanyList', body));
    }

    async getCompanyFilters() : Promise<IFilter[]> {
        return await http.api.get<IFilter[]>('/v1/company/getCompanyListFilters');
    }

    addCompany(newCompany: ICompany) {
        return http.api.post('/v1/company/addCompany', newCompany);
    }

    getCompany(id: number): Promise<ICompanyDetails> {
        return http.api.get(`/v1/company/getCompany?id=${id}`);
    }

    editCompanyInfo(companyInfo: ICompanyDetails) {
        return http.api.post('/v1/company/editCompanyInfo', companyInfo);
    }

    editCompanyAddress(address: IAddress) {
        return http.api.post('/v1/company/editCompanyAddress', address);
    }

    editCompanyProductAssociation(productAssociation: ICompanyProductAssociation) {
        return http.api.post('/v1/company/editCompanyProductAssociation', productAssociation);
    }

    getAllCustomers(): Promise<ICustomer[]> {
        return http.api.get('/v1/company/getAllCustomers');
    }
}

let companiesService = new CompaniesService();
export default companiesService;
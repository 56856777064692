
export function getErrorMessage(messages: string | string[] | undefined){
  if(!messages)
    return '';

  if(typeof messages === "string")
    return messages;

  return (
    <span dangerouslySetInnerHTML={{ __html: messages.join('<br/>') }} />
  )
}

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import loginService from "../Account/AccountService";
import saveToken from "../Account/SaveToken";
import saveTokenAfterRefresh from "../Account/SaveTokenAfterRefresh";
import { CognitoTokens, LoginResponse, TokenMetadata } from "../../domain/Login/LoginResponse";
import storage from "../Share/storageWithEncryption";

class ApiCommon {
    private _axios: AxiosInstance | undefined;
    private _baseUrl: string;
    private _token: string = storage.getDataFromLocalStorage('token');
    private _refreshToken: string = storage.getDataFromLocalStorage('refreshToken');
    private _expirationTime: number = Number(storage.getDataFromLocalStorage('expirationTime'));

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    private async GetInstanse(validateToken: boolean = true): Promise<AxiosInstance> {
        if (validateToken) {
            await this.ValidateToken();
        }

        if (this._axios)
            return this._axios;

        let baseURL = `${process.env.REACT_APP_BACKEND_BASE_URL}`;
        if (this._baseUrl)
            baseURL = `${baseURL}/${this._baseUrl}`;

        this._axios = axios.create({
            baseURL: baseURL,
            headers: { "Content-type": "application/json", "Client": "Frontend", "Authorization": `Bearer ${this._token}` }
        });
        return this._axios;
    }
    private parseJwt(token: string) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
    private async ValidateToken(): Promise<void> {
        let _now = Date.now() + 30000;
        if (!this._token || this._expirationTime > _now)
            return;

        try{
            let refreshToken = await loginService.getRefreshedToken(this._refreshToken);
            if(!refreshToken.isSuccess) {
                alert("Your token has expired and could not regenerate. Please log in again.");
                localStorage.clear();
                window.location.reload();
                return;
            }

            this.setCredentialsAfterRefresh(refreshToken.value);
        } catch (e)
        {
            alert("Your token has expired and could not regenerate. Please log in again.");
            localStorage.clear();
            window.location.reload();
        }
    }

    setCredentials(loginResponse: LoginResponse) {
        let tokenMetadata = this.parseJwt(loginResponse.cognitoResponse.tokens.idToken) as TokenMetadata;
        let userInfo = JSON.parse(JSON.stringify(loginResponse.userInfo));
        saveToken(
            loginResponse.cognitoResponse.tokens.accessToken,
            loginResponse.cognitoResponse.tokens.refreshToken,
            Date.now() + loginResponse.cognitoResponse.tokens.expiresIn,
            tokenMetadata.email,
            tokenMetadata.name,
            tokenMetadata.family_name,
            userInfo.userInfo.permissionsByRole.role,
            userInfo.user_Id,
            userInfo.userInfo.officeId
        );
        if (this._axios)
            this._axios.defaults.headers["Authorization"] = `Bearer ${loginResponse.cognitoResponse.tokens.accessToken}`;
    }
    private setCredentialsAfterRefresh(refreshToken: CognitoTokens) {
        saveTokenAfterRefresh(refreshToken.accessToken, refreshToken.refreshToken, Date.now() + refreshToken.expiresIn);
        if (this._axios) this._axios.defaults.headers["Authorization"] = `Bearer ${refreshToken.accessToken}`;
    }

    async get<T>(path: string, params?: any, onlyData: boolean = true): Promise<T> {
        let instanse = await this.GetInstanse();
        if (params)
            path = `${path}?${new URLSearchParams(params).toString()}`;

        let response = await instanse.get<T>(path);
        if(onlyData)
            return response.data
        return response as any;
    }

    async post<T>(path: string, body?: any, config?: AxiosRequestConfig | undefined, onlyData: boolean = true): Promise<T> {
        let validateToken = !path.includes('Authentication') && !path.includes("refresh");
        let instanse = await this.GetInstanse(validateToken);
        let response = await instanse.post<T>(path, body, config);
        if(onlyData)
          return response.data
        return response as any;
    }

    async put<T>(path: string, body: any, config?: AxiosRequestConfig | undefined): Promise<T> {
        let instanse = await this.GetInstanse();
        let response = await instanse.put<T>(path, body, config);
        return response.data;
    }

    async getUri(): Promise<string> {
        let instanse = await this.GetInstanse();
        return instanse.getUri();
    }
}

let http = {
    api: new ApiCommon("api")
}

export default http;
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

type Props = {
  entity: any;
}

export default function PairPropertyTable({ entity }: Props) {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {Object.keys(entity ?? {}).map((key, index) => {
            const leftValue = entity[key][0];
            const rightValue = entity[key][1];
            const uniqueKey = `${key}-${index}`;
              return (
                <TableRow key={uniqueKey} style={ (index % 2) === 0 ? { backgroundColor: "#bcd9f73f" } : {}}>
                  <TableCell sx={{ fontWeight: "700", fontFamily: "Metronic Pro", padding: "10px 16px", borderBottom: 'none' }}>{key}</TableCell>
                  <TableCell sx={{ fontFamily: "Metronic Pro", padding: "10px 16px", borderBottom: 'none' }} align="right">{leftValue}</TableCell>
                  <TableCell sx={{ fontFamily: "Metronic Pro", padding: "10px 16px", borderBottom: 'none' }} align="right">{rightValue}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
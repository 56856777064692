import Box from "@mui/material/Box";
import { NavigateFunction } from "react-router-dom";
import Grid from "../../Components/Grid/Grid";
import userService from "../../../services/Users/UsersService";
import defaultColumnPreferences from "../Users/ColumnDefaultPreferences";
import storage from "../../../services/Share/storageWithEncryption";
import { IFilter, IListRequestBody } from "../../../domain/Common/RequestBody";
import { UserRoles } from "../../../domain/Users/userRoles";
import { useState } from "react";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import ChangeUserStateButton from "../../Components/Buttons/ChangeUserStateButton";
import ResetPasswordButton from "../../Components/Buttons/ResetPasswordButton";

interface Props {
  navigate: NavigateFunction;
}

export default function Users(props: Props) {
  const userRole = storage.getDataFromLocalStorage("userRole");
  const usersColumns = require(`./UsersColumns`).default;
  const gridPageSize = 50;
  const paginationSize = 100;

  const [changeUserStateButton, setChangeUserStateButton] = useState<boolean>(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [items, setItems] = useState<any[]>([]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: gridPageSize });
  const [query, setQuery] = useState<IListRequestBody>({
    page: 0,
    pageSize: 100,
    sortingDirection: 0,
    sortBy: "",
    filters: [] as IFilter[],
  });

  function handleUsersClick() {
    props.navigate("/Users");
  }

  function handleAddUserClick() {
    props.navigate("/AddUser");
  }

  function handleEditUser(params: any) {
    props.navigate(`/EditUser/${params.id}`);
  }

  const itemsCallback = (items: any[] ) => setItems(items);

  const onRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel((r) => {
      if (newRowSelectionModel.length - r.length > gridPageSize) {
        const firstRow = paginationModel.page * gridPageSize;
        return newRowSelectionModel.slice(firstRow, firstRow + gridPageSize);
      }
      return newRowSelectionModel;
    });

    if (!newRowSelectionModel.length || newRowSelectionModel.length > 1) {
      setChangeUserStateButton(false);
      return;
    }
    let user = items.find((x: any) => x.userId === newRowSelectionModel[0]);
    setCurrentUser(user);
    setChangeUserStateButton(userRole === UserRoles.MotionAdmin);
  };

  const refresh = () => {
    setQuery((s) => ({
      ...s,
      page: 0,
      filters: [{
        filterType : "userId",
        filterValues : [currentUser.userId]
      }]
    }));
    onRowSelectionModelChange([]);
  }

  function translateFilters(filters: IFilter[]) {
    let active = filters.find(f => f.filterType === 'Active');
    if(!!active) active.filterValues = ['Enabled', 'Disabled'];

    let status = filters.find(f => f.filterType === 'Status');
    if(!!status) status.filterValues = ['OK', 'In Resetting'];

    let role = filters.find(f => f.filterType === 'Role');
    if(!!role) role.filterValues = role.filterValues.map(f => UserRoles.addSpace(f));
  }

  function unTranslateFilters(filters: IFilter[]) {
    let active = filters.find(f => f.filterType === 'active');
    if(!!active)
      active.filterValues = active.filterValues.map(f => {
        if(f === 'Enabled') return 'true';
        if(f === 'Disabled') return 'false';
        return f;
      });

    let status = filters.find(f => f.filterType === 'status');
    if(!!status)
      status.filterValues = status.filterValues.flatMap(f => {
        if(f === 'OK') return ['CONFIRMED'];
        if(f === 'In Resetting') return ['UNCONFIRMED', 'FORCE_CHANGE_PASSWORD'];
        return [f];
      });

    let roles = filters.find(f => f.filterType === 'role');
    if(!!roles)
      roles.filterValues = roles.filterValues.map(f => UserRoles.removeSpace(f))}

  return (
    <Box sx={{ marginLeft: "30px", marginRight: "20px" }}>
      <Box sx={{ display: "flex" }}>
        <Box component="span" className="navigation-link" style={{ marginLeft: "50px" }} onClick={handleUsersClick}>Users</Box>
        <Box component="span" className="navigation-link" style={{ marginLeft: "50px", textDecoration: "none" }} onClick={handleAddUserClick}>Add New User</Box>
      </Box>
      <Box style={{ height: "75vh", width: "100vw-50px", padding: "0px 10px 30px 0px", marginLeft: 50 }}>
        <Grid
          getRowId="userId"
          defaultColumnPreferences={defaultColumnPreferences}
          columnPreferencesName="usersColumnPreferences"
          columns={usersColumns}
          gridPageSize={gridPageSize}
          paginationSize={paginationSize}
          isServerSideFilterData={true}
          isServerSideOrderData={true}
          getData={userService.getUserList}
          getFilters={userService.getUserFilters}
          itemsCallback={itemsCallback}
          onRowDoubleClick={userRole === UserRoles.MotionAdmin ? handleEditUser : undefined}
          translateFilters={translateFilters}
          unTranslateFilters={unTranslateFilters}
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
          paginationModel={[paginationModel, setPaginationModel]}
          queryOptions={[query, setQuery]}
        />
        {changeUserStateButton
        ? (<Box sx={{ display: "flex", flexDirection: "row-reverse", marginTop: "10px" }}>
            <Box sx={{ marginLeft: "10px" }}>
              <ResetPasswordButton email={currentUser.emailAddress} reloadData={refresh} />
            </Box>
            <Box>
              <ChangeUserStateButton isUserActive={currentUser.active} userId={currentUser.userId} reloadData={refresh} />
            </Box>
          </Box>)
        : null
      }
      </Box>
    </Box>
  );
}

import { Box, Button, Dialog, DialogContent, Grid, IconButton, Link } from "@mui/material";
import { Visibility,VisibilityOff } from "@mui/icons-material";
import { FormEvent, useState } from "react";
import { beta, materialiseLogo, login } from "../../../statics/images"
import TextField from "@mui/material/TextField";
import accountService from "../../../services/Account/AccountService";
import LicenseAgreement from "../../Components/License/LicenseAgreement";
import { useNavigate } from "react-router-dom";
import Popup from "../../Components/Popup/Popup";
import "./SetupPassword.module.css"

const popupDescription =
    `
    Your token session has expired. Please reset your password again to continue. 
    
    Click OK to reset your password.
    
    Thank you.
    Materialise Motion
    `

function SetupPassword() 
{
    const pathname = window.location.pathname;
    const [showAlert, setShowAlert] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get('email')|| '';
    const code = searchParams.get('code') || '';
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(0);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const navigate = useNavigate();

    const errors = ["", "Field required.", "Passwords don't match", "The password must be 6 characters long and contain a combination of uppercase, lowercase letters and numbers."];
    const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;

    const validateData = () => {
      var result = true;

      if(!passwordRegExp.test(password)) {
        result = false;
        setPasswordError(3);
      }
      if(password!==confirmPassword){
        result = false;
        setPasswordError(2);
        setConfirmPasswordError(2);
      }
      if(password===""){
        result = false;
        setPasswordError(1);
      }
      if(confirmPassword===""){
        result = false;
        setConfirmPasswordError(1);
      }

      return result;
     }
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!validateData())
        return;

        accountService.setNewPasswordWithCode("", code, password, email)
        .then(_ => setOpenConfirmation(true))
        .catch(e => {
            console.error(e);
            setShowAlert(true);
        });
    };

    const handleConfirm = () => {
        setOpenConfirmation(false);
        navigate("/Login");
    }

    const handleConfirmTokenExpired = () => {
        setShowAlert(false);
        navigate("/ResetPassword");
    };

    function handleClickOpen() {
        setOpenDialog(true);
    }

    function handleClose() {
        setOpenDialog(false);
    }
    
    return(

        <Box sx={{ height: '100vh', display: 'flex' }}>
            <Dialog
                maxWidth='lg'
                open={openDialog}
                onClose={handleClose}
                scroll={'body'}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogContent dividers>
                        <LicenseAgreement />
                </DialogContent>
            </Dialog>
             <Box sx={{ width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

             <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '5vh', lineHeight: "55px" }}>
                        <Box>
                            <Box sx={{ fontWeight: "300", fontSize: "71px" }}>Materialise</Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ fontWeight: "300", fontSize: "26px" }}>Order Flow Management</Box>
                            <img alt="Beta" src={beta} style={{ marginLeft: "5px", bottom: "-18px", alignSelf: 'start', position: "relative" }} />
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: '30px', marginBottom: '20px' }}>
                        <h3 style={{ maxHeight: "10px", textAlign: "left" }}>{pathname.includes("SetupPassword") ? "Setup Password" : "Reset Password"}</h3>
                    </Box>
                    <Box>Enter email and we will send you instructions on how</Box>
                    <Box sx={{ marginBottom: '20px' }}>to {pathname.includes("SetupPassword") ? "setup" : "reset"} your password</Box>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={5} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Password"
                                    sx={{ textAlign: 'left', maxWidth: '400px' }}
                                    InputLabelProps={{ shrink: true }}
                                    error={passwordError !== 0}
                                    value={password}
                                    type={showPassword ? 'text' : 'password'}
                                    variant="standard"
                                    placeholder="Enter password"
                                    helperText={errors[passwordError]}
                                    onChange={e => {
                                        setPassword(e.target.value);
                                        setPasswordError(0);
                                        setConfirmPasswordError(0);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        )
                                    }}
                                    fullWidth
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="ConfirmPassword"
                                    sx={{ textAlign: 'left', maxWidth: '400px' }}
                                    InputLabelProps={{ shrink: true }}
                                    error={confirmPasswordError !== 0}
                                    value={confirmPassword}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    variant="standard"
                                    placeholder="Enter password"
                                    helperText={errors[confirmPasswordError]}
                                    onChange={e => {
                                        setConfirmPassword(e.target.value);
                                        setPasswordError(0);
                                        setConfirmPasswordError(0);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            >
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        )
                                    }}
                                    fullWidth
                                >
                                </TextField>
                                <Box sx={{ marginTop: '10px' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ borderRadius: '0', marginTop: '1rem', maxWidth: '400px' }}
                                        fullWidth  
                                    >{pathname.includes("SetupPassword") ? "Apply Password" : "Reset Password"}
                                    </Button>
                                </Box>
                                <Box sx={{ marginTop: '60px', textAlign: 'center' }}>
                                  
                                    <img width="150" alt="MaterialiseLogo" src={materialiseLogo}></img>
                                </Box>
                                <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                                    <Link href='https://www.materialise.com/en/about' target="_blank" sx={{marginLeft:'10px'}} underline="hover" color="inherit" variant="body2">About</Link>
                                    <Link href='mailto:support.motion@materialise.be' sx={{marginLeft:'10px'}} underline="hover" color="inherit" variant="body2">Contact support</Link>
                                    <Link onClick={handleClickOpen} sx={{marginLeft:'10px', cursor:'pointer'}} underline="hover" color="inherit" variant="body2">End-User License Agreement</Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                    <Popup
                        title="Confirmation"
                        description="Password Setup Successful!"
                        approveText="OK"
                        open={openConfirmation}
                        handleApprove={handleConfirm}
                    />
                </Box>
             </Box>
             <Box sx={{ width: '60%', backgroundImage: `url(${login})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} >
             <Popup title="Reset Token session has expired" description={popupDescription} approveText="OK" open={showAlert} handleApprove={handleConfirmTokenExpired} />
            </Box>
        </Box>
    );

}
  
  export default SetupPassword;


import { Box, Button, Dialog, DialogContent, IconButton, Link } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { FormEvent, useState } from "react";
import { beta, materialiseLogo, login } from "../../../statics/images"
import TextField from "@mui/material/TextField";
import accountService from "../../../services/Account/AccountService";
import LicenseAgreement from "../../Components/License/LicenseAgreement";
import Popup from "../../Components/Popup/Popup";
import { useNavigate } from "react-router-dom";

const popupDescription =
    `
    An email with instructions to reset your password has been sent to your registered email address. Please check your inbox and follow the provided instructions to reset your password.
    
    If you don't receive the email within a few minutes, please check your spam folder. If you still encounter issues, contact our support team for assistance.

    Thank you.

    Materialise Motion
    `

function ResetPassword() 
{
    const [showAlert, setShowAlert] = useState(false);
    const [email, setEmail] = useState("");
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [ ,setIsWrong] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(validateForm()){
            accountService.resetPassword(email);
            setShowAlert(true);
        }     
    }

    function validateForm() {
        email === "" ? setIsEmailEmpty(true) : setIsEmailEmpty(false);       
        setIsWrong(false);
        return !(email === "" );
    }

    function handleClickOpen() {
        setOpenDialog(true);
    }

    function handleClose() {
        setOpenDialog(false);
    }

    function handleApprove() {
        setShowAlert(false);
        navigate("/Login");
    }

    return(

        <Box sx={{ height: '100vh', position:'absolute', width:'100%', display: "flex" }}>
            <Dialog
                maxWidth='lg'
                open={openDialog}
                onClose={handleClose}
                scroll={'body'}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogContent dividers>
                        <LicenseAgreement />
                </DialogContent>
            </Dialog>
             <Box sx={{ width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

             <Box>
             <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10vh', lineHeight: "55px" }}>
                        <Box>
                            <Box sx={{ fontWeight: "300", fontSize: "71px" }}>Materialise</Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ fontWeight: "300", fontSize: "26px" }}>Order Flow Management</Box>
                            <img alt="Beta" src={beta} style={{ marginLeft: "5px", bottom: "-18px", alignSelf: 'start', position: "relative" }} />
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: '40px', marginBottom: '50px' }}>
                        <h3 style={{ maxHeight: "10px", textAlign: "left" }}>Reset Password</h3>
                    </Box>
                    <Box>Enter email and we will send you instructions on how</Box>
                    <Box>to reset your password</Box>
                    <form onSubmit={handleSubmit}>
                    <TextField
                                   
                                    sx={{ textAlign: 'left', maxWidth: '400px' }}
                                    InputLabelProps={{ shrink: true }}
                                    error={isEmailEmpty}
                                    value={email}
                                    type="text"
                                    variant="standard"
                                    placeholder="example@email.com"
                                    helperText={isEmailEmpty ? "The Email field is required." : ""}
                                    onChange={e => {
                                        setEmail(e.target.value);
                                        setIsEmailEmpty(false);
                                    }} fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton aria-label="delete"
                                            onClick={() => setEmail("")}
                                            ><Clear /></IconButton>
                                        )
                                    }}
                                    >
                                </TextField>
                                <Box sx={{ marginTop: '20px' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ borderRadius: '0', marginTop: '1rem', maxWidth: '400px' }}
                                        fullWidth  
                                    >Reset password
                                    </Button>
                                </Box>
                                </form>
                                <Box sx={{ marginTop: '60px', textAlign: 'center' }}>
                                  
                                    <img width="150" alt="MaterialiseLogo" src={materialiseLogo}></img>
                                </Box>
                                <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
                                    <Link href='https://www.materialise.com/en/about' target="_blank" sx={{marginLeft:'10px'}} underline="hover" color="inherit" variant="body2">About</Link>
                                    <Link href='mailto:support.motion@materialise.be' sx={{marginLeft:'10px'}} underline="hover" color="inherit" variant="body2">Contact support</Link>
                                    <Link onClick={handleClickOpen} sx={{marginLeft:'10px', cursor:'pointer'}} underline="hover" color="inherit" variant="body2">End-User License Agreement</Link>
                                </Box>
                    </Box>
             </Box>
             <Box sx={{ width: '60%', backgroundImage: `url(${login})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            </Box>
            <Popup title="Password Reset Email Sent" description={popupDescription} approveText="OK" open={showAlert} handleApprove={handleApprove} />
        </Box>
    );

}
  
  export default ResetPassword;


import { GridColDef } from "@mui/x-data-grid";

const CompanyColumns: GridColDef[] = [
    { field: 'name', headerName: 'Name', type: 'string', flex: 1, description: 'listFilter', disableColumnMenu: true },
    { field: 'companyType', headerName: 'Company Type', type: 'string', flex: 1, description: 'listFilter', disableColumnMenu: true },
    { field: 'dateOfCreation', headerName: 'Creation Date', flex: 1, description: 'dateFilter', disableColumnMenu: true, align: 'left',
    renderCell: rowdata => rowdata.value ? new Date(rowdata.value).toLocaleString('es', {day: '2-digit', month: '2-digit', year: 'numeric', hour:'2-digit', minute:'2-digit'}) : null },
    { field: 'customerNumber', headerName: 'Customer Number', type: 'string', flex: 1, description: 'textFilter', disableColumnMenu: true },
    { field: 'address', headerName: 'Address', type: 'string', flex: 2, description: 'textFilter', disableColumnMenu: true },
]

export default CompanyColumns; 


import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import styles from "./Popup.module.css";

type Props = {
  title: string;
  description: string;
  denyText?: string;
  approveText: string;
  open: boolean;
  handleDeny?: () => void;
  handleApprove: () => void;
};

export default function Popup(props: Props) {
  return (
    <Dialog maxWidth="sm" open={props.open} fullWidth>
      <DialogTitle
        className={styles.dialog}
        sx={{ fontWeight: "600 !important" }}
      >
        {props.title}
      </DialogTitle>
      <DialogContent
        className={styles.dialog}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          minHeight: "5rem",
          whiteSpace: "pre-line",
        }}
      >
        {props.description}
      </DialogContent>
      <DialogActions className={styles.dialog}>
        {props.denyText ? (
          <Button
            sx={{
              fontFamily: "Metronic Pro",
              fontWeight: "600",
              color: "white",
            }}
            onClick={props.handleDeny}
          >
            {props.denyText}
          </Button>
        ) : null}
        <Button
          sx={{
            fontFamily: "Metronic Pro",
            fontWeight: "600",
            color: "white",
          }}
          onClick={props.handleApprove}
        >
          {props.approveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

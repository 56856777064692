const defaultColumnPreferences = {
    CustomerSales: {
        street: false,
        appartmentNumber: false,
        city: false,
        postalCode: false,
        country: false,
        productionCurrentActorId:false,
        practitionerUserId: false,
        designChangeActorId: false,
        currentActorId: false,
        delayReason: false,
    },
    ProductionOperator: {
        customerName: false,
        shippingAddress: false,
        country:false,
        delayReason: false,
    },
    MotionAdmin: {
        street: false,
        appartmentNumber: false,
        city: false,
        postalCode: false,
        country: false,
        productionCurrentActorId: false,
        practitionerUserId: false,
        designChangeActorId: false,
        currentActorId: false,
        delayReason: false,
    },
    MTLSEngineers: {
        street: false,
        appartmentNumber: false,
        city: false,
        postalCode: false,
        country: false,
        productionCurrentActorId: false,
        practitionerUserId: false,
        designChangeActorId: false,
        currentActorId: false
    },
    CustomerSupport: {
        country: false,
        shippingAddress: false,
        customerPhoneNumber: false,
        delayReason: false,
    },

};

export default defaultColumnPreferences;
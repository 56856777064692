import storage from "../Share/storageWithEncryption";

export default function saveToken(token: string, refreshToken: string, expirationTime: number, email: string, firstName: string, lastName: string, userRole: string, userId: string, officeId: string) {
    storage.setDataToLocalStorage('token', token);
    storage.setDataToLocalStorage('refreshToken', refreshToken);
    storage.setDataToLocalStorage('expirationTime', expirationTime.toString());
    storage.setDataToLocalStorage('email', email);
    storage.setDataToLocalStorage('firstName', firstName);
    storage.setDataToLocalStorage('lastName', lastName);
    storage.setDataToLocalStorage('userRole', userRole);
    storage.setDataToLocalStorage('userId', userId);
    storage.setDataToLocalStorage('officeId', officeId.toString());
}

import { Button } from "@mui/material";
import { useState } from "react";
import userService from "../../../services/Users/UsersService";
import Popup from "../Popup/Popup";

type Props = {
  isUserActive: boolean;
  userId: string;
  reloadData?: () => void;
};

export default function ChangeUserStateButton(props: Props) {
  const [displayApproveAction, setDisplayApproveAction] = useState(false);

  const title = props.isUserActive ? "Disable User" : "Enable User";

  function handleClick() {
    setDisplayApproveAction(true);
  }

  function handleApproveAction() {
    userService.changeUserState(props.userId, !props.isUserActive).then(() => {
      if (props.reloadData) props.reloadData();
    });
    setDisplayApproveAction(false);
  }

  function handleDenyAction() {
    setDisplayApproveAction(false);
  }

  return (
    <>
      <Button
        sx={{
          borderRadius: "5px",
          fontFamily: "Metronic Pro",
          width: "200px",
          textTransform: "none",
          backgroundColor: props.isUserActive ? "red" : "green",
          "&:hover": {
            backgroundColor: props.isUserActive ? "#d10e00" : "#007304",
          }
        }}
        size="small"
        variant="contained"
        onClick={handleClick}
      >
        {title}
      </Button>
      <Popup
        title={title}
        description="Are you sure you want to perform this action?"
        approveText="YES"
        denyText="NO"
        open={displayApproveAction}
        handleApprove={handleApproveAction}
        handleDeny={handleDenyAction}
      />
    </>
  );
}

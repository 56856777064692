
import { IFilter, IListRequestBody } from "../../domain/Common/RequestBody";
import { IRequestResponse } from "../../domain/Common/RequestResponse";
import { IUser } from "../../domain/Users/IUser";

import http from "../Share/httpCommon";

class UserService {
  createUser(newUser: IUser) {
    return http.api.post('/v1/userAction/create', newUser);
  }

  async getUserById(userId: string) {
    return await http.api.get<IUser>(`v1/userAction/${userId}`);
  }

  async editUser(userInfo: IUser) {
    return http.api.put('/v1/userAction', userInfo);
  }

  async getUserList(body: IListRequestBody) : Promise<IRequestResponse<IUser>> {
    return (await http.api.post<IRequestResponse<IUser>>('/v1/userAction/getUserList', body));
  }

  async getUserFilters() : Promise<IFilter[]> {
    return await http.api.get<IFilter[]>('/v1/userAction/getFiltersForUserList');
  }
  
  async changeUserState(userId: string, isActive: boolean): Promise<boolean> {
    let response = await http.api.post<boolean>('/v1/userAction/changeUserState', { userId: userId, isActive: isActive });
    return response;
  }
}

let userService = new UserService();
export default userService;
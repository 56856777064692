import { GridColDef } from "@mui/x-data-grid";
import AccountStatus from "../../Components/UserStatus/AccountStatus";
import AccountCredentials from "../../Components/UserStatus/AccountCredentials";
import { UserRoles } from "../../../domain/Users/userRoles";

const UsersColumns: GridColDef[] = [
    { field: 'creationTime', headerName: 'Creation date', flex: 0.75, description: 'dateFilter', disableColumnMenu: true, align: 'left',
    renderCell: rowdata => rowdata.value ? new Date(rowdata.value).toLocaleString('es', {day: '2-digit', month: '2-digit', year: 'numeric', hour:'2-digit', minute:'2-digit'}) : null},
    { field: 'emailAddress', headerName: 'Email Address', type: 'string', flex: 1, description: 'textFilter', disableColumnMenu: true},
    { field: 'active', headerName: 'Account Status', type: 'boolean', flex: 0.65, description: 'listFilter', disableColumnMenu: true, headerAlign: 'left', align: "left", renderCell: (value) => <AccountStatus status={value.value} />},
    { field: 'status', headerName: 'Credentials', type: 'string', flex: 0.65, description: 'listFilter', disableColumnMenu: true, align: "left", renderCell: (value) => <AccountCredentials credentials={value.value} />},
    { field: 'office', headerName: 'Office/Store', type: 'string', flex: 1, description: 'listFilter', disableColumnMenu: true},
    { field: 'name', headerName: 'Name', type: 'string', flex: 1, description: 'textFilter', disableColumnMenu: true},
    { field: 'phoneNumber', headerName: 'Phone Number', type: 'string', flex: 1, description: 'textFilter', disableColumnMenu: true},
    { field: 'role', headerName: 'Role', type: 'string', flex: 1, description: 'listFilter', disableColumnMenu: true, renderCell: (value) => <>{UserRoles.addSpace(value.value)}</>},
    { field: 'company', headerName: 'Company', type: 'string', flex: 1, description: 'listFilter', disableColumnMenu: true},
    { field: 'userId', headerName: 'User Id', type: 'string', flex: 1, description: 'textFilter', disableColumnMenu: true, align: 'center'}
]

export default UsersColumns; 


import { Button } from "@mui/material";
import Popup from "../Popup/Popup";
import { useState } from "react";
import accountService from "../../../services/Account/AccountService";

type Props = {
    email: string;
    reloadData?: () => void;
}

export default function ResetPasswordButton(props: Props) {
  const [displayApproveAction, setDisplayApproveAction] = useState(false);
  const [displayEmailSent, setDisplayEmailSent] = useState(false);

    function handleClick() {
        setDisplayApproveAction(true);
    }
    
    function handleApproveAction() {
        accountService.resetPassword(props.email);
        setDisplayApproveAction(false);
        setDisplayEmailSent(true);
    }

    function handleDenyAction() {
        setDisplayApproveAction(false);
    }

    function handleEmailSent() {
        setDisplayEmailSent(false);
        if (props.reloadData) {
            props.reloadData();
        }
    }

  return (
    <>
      <Button
        sx={{
          borderRadius: "5px",
          fontFamily: "Metronic Pro",
          width: "200px",
          textTransform: "none",
        }}
        size="small"
        variant="contained"
        onClick={handleClick}
      >
        Reset Password
      </Button>
      <Popup
        title="Reset Password"
        description="Are you sure you want to perform this action?"
        approveText="YES"
        denyText="NO"
        open={displayApproveAction}
        handleApprove={handleApproveAction}
        handleDeny={handleDenyAction}
      />
      <Popup
        title="Reset Password"
        description="The email has been sent to the user"
        approveText="OK"
        open={displayEmailSent}
        handleApprove={handleEmailSent}
      />
    </>
  );
}

import { Navigate, Route, Routes } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import './Application.css';
import AppMenu from '../../Components/AppMenu/AppMenu';
import ErrorPage from '../ErrorPage/ErrorPage';
import { Header } from '../../Components/Header';
import Health from '../../Health';
import Orders from '../Orders/Orders';
import Users from '../Users/Users';
import AddUser from '../AddUser/AddUser';
import EditUser from '../EditUser/EditUser';
import Companies from '../Companies/Companies';
import ProtectedRoutes from '../../Components/ProtectedRoutes/ProtectedRoutes';
import AddCompany from '../AddCompany/AddCompany';
import OrderDetails from '../OrderDetails/OrderDetails';
import OfficeDetail from '../OfficeDetail/OfficeDetail';
import CompanyDetails from '../CompanyDetails/CompanyDetails';

export default function Application() {
    const navigate = useNavigate();

    return (
        <div className="Application">
            <Header />
            <AppMenu/>
            <Routes>
                <Route path="/Orders"                       element={<Orders navigate={navigate} />} />
                <Route path="/OrderDetails/:orderId"        element={<OrderDetails navigate={navigate} />} />
                <Route path="/health"                       element={<Health />} />
                <Route path='*'                             element={<Navigate to="/Orders" />} errorElement={<ErrorPage />} />
                <Route path="/Users"                        element={<ProtectedRoutes component={<Users          navigate={navigate} />} roles={['MotionAdmin']} />} />
                <Route path="/AddUser"                      element={<ProtectedRoutes component={<AddUser        navigate={navigate} />} roles={['MotionAdmin']} />} />
                <Route path="/EditUser/:userId"             element={<ProtectedRoutes component={<EditUser       navigate={navigate} />} roles={['MotionAdmin']} />} />
                <Route path="/Companies"                    element={<ProtectedRoutes component={<Companies      navigate={navigate} />} roles={['MotionAdmin']} />} />
                <Route path="/AddCompany"                   element={<ProtectedRoutes component={<AddCompany     navigate={navigate} />} roles={['MotionAdmin']} />} />
                <Route path="/CompanyDetails/:companyId"    element={<ProtectedRoutes component={<CompanyDetails navigate={navigate} />} roles={['MotionAdmin']} />} />
                <Route path="/Office/:companyId/:officeId?" element={<ProtectedRoutes component={<OfficeDetail   navigate={navigate} />} roles={['MotionAdmin']} />} />
            </Routes>
        </div>
    );
}
import { Table, TableBody, TableCell, TableContainer, TableRow, TextField } from "@mui/material";

type Props = {
  entity: any;
  setEntity?: any;
  input?: Array<any>;
  editable?: boolean;
  ignore?: Array<string>;
}

function keyToLabel(key: string) {
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .replace(/^./, function(match) { return match.toUpperCase(); });
}

export default function PropertyTable({ entity, setEntity, input, editable, ignore }: Props) {
  return(
    <TableContainer>
      <Table>
        <TableBody>
          {Object.keys(entity ?? {}).map((key, index) => {
            const value = entity[key];
            const uniqueKey = setEntity ? `${key}-${index}` : `${key}-${index}-${value}`;
            const inputField = input?.find((field: any) => field.key === key)?.component;
              return (
                !ignore?.includes(key)
                  ? <TableRow key={uniqueKey} style={ (index % 2) === 0 ? { backgroundColor: "#bcd9f73f" } : {}}>
                      <TableCell sx={{ fontWeight: "700", fontFamily: "Metronic Pro", padding: "10px 16px", borderBottom: 'none' }}>{keyToLabel(key)}</TableCell>
                      <TableCell sx={{ fontFamily: "Metronic Pro", padding: "10px 16px", borderBottom: 'none' }} align="right">
                        {!editable || !inputField
                          ? <TextField size="small" fullWidth variant="standard" value={value} onChange={(e) => setEntity({...entity, [key]: e.target.value})}
                              InputProps={{ disableUnderline: true, readOnly: !editable }}
                              inputProps={{ style: {fontFamily: "Metronic Pro", fontSize: "0.875rem", textAlign: "end"} }}
                            />
                          : inputField
                        }
                      </TableCell>
                    </TableRow>
                  : null
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
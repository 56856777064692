import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import './AppMenu.css'
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import storage from '../../../services/Share/storageWithEncryption';
import { materialiseWhiteLogo } from '../../../statics/images';

const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'fixed',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const userRole = storage.getDataFromLocalStorage('userRole');
  const profileOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getUserFullName = (): string[] => {
    const firstName = storage.getDataFromLocalStorage('firstName');
    const lastName = storage.getDataFromLocalStorage('lastName');
    return [firstName, lastName];
  }

  return (

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ paddingRight: '8px !important' }}>
          <Box sx={{ width: '100vw', display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
            <Box sx={{ alignSelf: 'center', width: 'fit-content', display: 'flex' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ width: 'fit-content', alignSelf: 'center' }}>
                <Typography variant="h6" noWrap>
                  Order Flow Management
                </Typography>
              </Box>
            </Box>
              <Box sx={{ width: 'fit-content', alignSelf: 'center', textJustify: 'right', display: 'flex' }}>
                <Button
                  size='large'
                  id='profile-button'
                  aria-controls={profileOpen ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={profileOpen ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ color: 'white', textTransform: 'none', '.MuiButton-endIcon': { display: 'unset' } }}
                  endIcon={profileOpen ?
                    <Fade in={profileOpen}>
                      <KeyboardArrowUp sx={{ color: 'white',  }} />
                    </Fade> :
                    <Fade in={!profileOpen}>
                      <KeyboardArrowDown sx={{ color: 'white' }} />
                    </Fade>
                  }
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {getUserFullName().map((namePart: string, index: number) => <Box key={index} >{namePart}</Box>)}
                  </Box>
                </Button>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={profileOpen}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'profile-button',
                  }}
                  sx={{
                    "& .MuiMenu-paper": {
                      backgroundColor: 'rgb(25, 118, 210)'
                    }
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      localStorage.clear();
                      window.location.reload();
                    }}
                    sx={{ color: 'white' }}
                  >

                    <ListItemIcon sx={{ width: 'fit-content' }}>
                      <Logout sx={{ color: 'white' }} fontSize='small' />
                    </ListItemIcon>
                    <Typography fontSize='small' noWrap>
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
                <img alt="Materialise White Logo" src={materialiseWhiteLogo} style={{ width: "125px", margin: '5px 0 5px 15px' }} />
              </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <ListItem>
          <ListItemButton key='Orders'
            to='/Orders'
            component={Link}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <Tooltip title="Orders" arrow placement="right">
                <AssignmentIcon />
              </Tooltip>
              {open ? ('Orders') : ''}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        {userRole === "MotionAdmin" && <ListItem>
          <ListItemButton key='Companies'
            to='/Companies'
            component={Link}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <Tooltip title="Companies" arrow placement="right">
                <ApartmentIcon />
              </Tooltip>
              {open ? ('Companies') : ''}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>}

        {userRole === "MotionAdmin" && <ListItem>
          <ListItemButton key='Users'
            to='/Users'
            component={Link}
            aria-label='Users'
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >

              <Tooltip title="Users" arrow placement="right">
                <PeopleIcon />
              </Tooltip>
              {open ? ('Users') : ''}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      </Box>
    </Box>

  );
}
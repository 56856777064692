import { Box, Button, Dialog, DialogActions,DialogContent, DialogTitle, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { ButtonDatePicker } from "../Grid/GridHeader/GridHeaderDate";

import styles from "../Popup/Popup.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

type Props = {
  setDelayedDate: Dispatch<SetStateAction<Dayjs | null>>;
  setDelayReason: Dispatch<SetStateAction<string>>;
  handleApprove: () => void;
};

export default function DelayedOrderButton(props: Props) {
  const [delayedDate, setDelayedDate] = useState<Dayjs | null>(null);
  const [delayReason, setDelayReason] = useState<string>("");
  const [displayApproveAction, setDisplayApproveAction] = useState(false);

  const title = "Delay Order(s)";

  function handleClick() {
    setDisplayApproveAction(true);
  }

  function handleApproveAction() {
    props.handleApprove();
    setDisplayApproveAction(false);
  }

  function handleDenyAction() {
    setDisplayApproveAction(false);
    setDelayReason("");
    setDelayedDate(null);
    setDelayReason("");
    props.setDelayedDate(null);
  }

  return (
    <>
      <Button
        sx={{ borderRadius: "5px", fontFamily: "Metronic Pro", width: "200px", margin: "10px 0 10px 10px", textTransform: "none", float: "right" }}
        size="small" variant="contained" onClick={handleClick}
      >
        {title}
      </Button>

      <Dialog maxWidth="sm" open={displayApproveAction} fullWidth>
        <DialogTitle className={styles.dialog} sx={{ fontWeight: "600 !important" }}>
          {title}
        </DialogTitle>

        <Box>
          <DialogContent className={styles.dialog} sx={{ display: "flex", alignItems: "start", justifyContent: "space-around" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ fontSize: "15px", fontWeight: 500, marginBottom: "10px" }}>Delayed Days:</Box>
              <Box sx={{ fontSize: "14px", display: "flex", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ButtonDatePicker
                    label="Select Date" value={delayedDate}
                    onChange={(newDate) => {
                      setDelayedDate(newDate);
                      props.setDelayedDate(newDate);
                    }}
                  />
                </LocalizationProvider>
                <Box sx={{ marginLeft: "5px" }}>{delayedDate?.format("DD/MM/YYYY")}</Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ fontSize: "15px", fontWeight: 500, marginBottom: "10px" }}>Delay Reason:</Box>
              <TextField
                variant="standard" placeholder="Add delayed reason..." value={delayReason}
                InputProps={{ sx: { color: "white" }, disableUnderline: true }} inputProps={{ maxLength: 50 }}
                onChange={(event) => {
                  setDelayReason(event.target.value);
                  props.setDelayReason(event.target.value);
                }}
              />
              <Box sx={{ fontSize: "13px" }}>{delayReason.length}/50</Box>
            </Box>
          </DialogContent>
        </Box>

        <DialogActions className={styles.dialog}>
          <Button sx={{ fontFamily: "Metronic Pro", fontWeight: "600", color: "white" }} onClick={handleDenyAction}>Cancel</Button>
          <Button sx={{ fontFamily: "Metronic Pro", fontWeight: "600", color: "white" }} onClick={handleApproveAction} disabled={delayReason === ""  || delayedDate === null}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

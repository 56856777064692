import IAllowedProduct from "../../domain/Product/AllowedProduct";
import http from "../Share/httpCommon";

class ProductService {
  getAllowedProducts() {
      return http.api.get<IAllowedProduct[]>('/v1/Products/getAllowedProducts');
  }
}

let productService = new ProductService();
export default productService;
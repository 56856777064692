import { Box, Grid, TextField, Autocomplete } from "@mui/material";

type Props = {
  labelText: string,
  value: any | undefined,
  selectValues?: any[],
  valueKey?: string;
  freeSolo?: boolean;
  placeholder?: string,
  onChange?: any,
  editable?: boolean,
  required?: boolean
  error?: boolean;
  helperText?: React.ReactNode;
  side?: number;
}

export default function GridProperty({ labelText, value, selectValues, valueKey, freeSolo, placeholder, onChange, editable, required, error, helperText, side }: Props)
{
  const isSelect = !!selectValues;
  freeSolo = freeSolo ?? true;
  function handleChange(input: any){
    onChange(input?.target?.value)
  }

  function getValue(value: any) {
    if (valueKey)
      return value?.[valueKey] ? value[valueKey] : null;
    return value ? value : null;
  }

  return (
    <Grid item xs={side ?? 3} style={{ padding: '10px' }} >
      <Box sx={{ textAlign: "left", marginBottom: "5px" }}>
        {required ? '* ' : null }{labelText}
      </Box>
      <Box sx={{ position: "relative" }}>
        { !isSelect ? 
          <TextField fullWidth value={value ?? ''} onChange={editable ? handleChange : undefined}
          InputProps={{ readOnly: !editable }} placeholder={editable ? placeholder : undefined} error={error} helperText={error ? helperText : ''}
          sx={{ '& .MuiOutlinedInput-root': { 
              boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
              borderRadius: "4px", }
          }}
          />
          :
          <Autocomplete freeSolo={freeSolo} options={selectValues!} value={!freeSolo ? value ?? "" : ""} inputValue={value ?? ""} readOnly={!editable}
            isOptionEqualToValue={(option, value) => getValue(option) === value}
            getOptionLabel={(value) => typeof value === "string" ? getValue(selectValues.find((c) => getValue(c) === value)) ?? '' : getValue(value) ?? ''}
            onChange={(_, value)      => !freeSolo ? handleChange({ target: {value: getValue(value)} }) : undefined }
            onInputChange={(_, value) =>  handleChange({ target: {value: value} })}
            renderInput={(params) => (
              <TextField {...params} placeholder={editable ? placeholder : undefined} error={error} helperText={error ? helperText : ''}
                sx={{ '& .MuiOutlinedInput-root': { 
                  boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
                  borderRadius: "4px", }
                }} /> )} />
        }
      </Box>
    </Grid>
  )
}
import { Box, Card, CardContent, CardHeader, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import PropertyTable from "./PropertyTable";
import PairPropertyTable from "./PairPropertyTable";

type Props = {
  title: string;
  entity: any;
  setEntity?: any;
  input?: Array<any>;
  onEdit?: any;
  onCancel?: any;
  onSubmit?: any;
  ignore?: Array<string>;
  pairs?: boolean;
  titleStyle?: any;
}

const defaultTitleStyle = { fontFamily: "Metronic Pro", fontSize: "1.2rem", fontWeight: "700" };

export default function CardDetails({ title, entity, setEntity, input, onEdit, onSubmit, onCancel, ignore, pairs, titleStyle }: Props) {
  const [editable, setEditable] = useState(false);
  const [oldEntity, setOldEntity] = useState();

  function handleCancel() {
    setEditable(false);
    if (onCancel)
      onCancel(oldEntity);
    else setEntity(oldEntity);
  }

  function handleEdit() {
    setEditable(true);
    setOldEntity(entity);
    onEdit?.();
  }

  function handleSubmit() {
    setEditable(false);
    if (JSON.stringify(oldEntity) !== JSON.stringify(entity))
      onSubmit();
  }

  return (
    <Card sx={{ padding: '0 10px 0 10px', boxShadow: 'none' }}>
      <CardHeader title={title} titleTypographyProps={titleStyle ?? defaultTitleStyle} action={
        !setEntity
          ? null
          : editable
            ? <Box sx={{ position: "relative" }}>
                <IconButton sx={{ position: "absolute", right: "30px" }} aria-label="cancel" onClick={handleCancel}><CloseIcon /></IconButton>
                <IconButton aria-label="submit" onClick={handleSubmit}><DoneIcon /></IconButton>
              </Box>
            : <IconButton aria-label="edit" onClick={handleEdit}><EditIcon /></IconButton>
        }
      />
      <CardContent>
        {!pairs && <PropertyTable entity={entity} setEntity={setEntity} input={input} editable={editable} ignore={ignore} />}
        {pairs && <PairPropertyTable entity={entity} />}
      </CardContent>
    </Card>
  )
}
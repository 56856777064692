import { Box, Grid, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { NavigateFunction, useParams } from "react-router-dom";
import ordersService from "../../../services/Orders/OrdersService";
import CardDetails from "../../Components/CardDetails/CardDetails";
import DesignParameters from "../../Components/DesignParameters/DesignParameters";
import storage from "../../../services/Share/storageWithEncryption";
import { UserRoles } from "../../../domain/Users/userRoles";

interface Props {
  navigate: NavigateFunction;
}

export default function OrderDetails({ navigate }: Props)
{
  const [order, setOrder] = useState<any>({});
  const [tabIndex, setTabIndex] = useState(0);
  const { orderId } = useParams();
  const userRole = storage.getDataFromLocalStorage("userRole");
  const remarkRoles = [UserRoles.MTLSEngineers, UserRoles.MotionAdmin];

  useEffect(() => {
    ordersService.getOrderDetails(orderId!)
      .then(o => setOrder(o))
    },[orderId])

  function dateFormat(date: string, onlyDate?: boolean) {
    if(!date) return '';
    if(onlyDate)
      return new Date(date).toLocaleString('es', {day: '2-digit', month: '2-digit', year: 'numeric'});
    return new Date(date).toLocaleString('es',
      {day: '2-digit', month: '2-digit', year: 'numeric', hour:'2-digit', minute:'2-digit', second: '2-digit'});
  }

  function handleOrdersClick() {
    navigate("/Orders");
  }

  return (
    <Box style={{ width: "100vw-50px", padding: "0px 30px 30px 30px", marginLeft: 50, }} >
      <Box component="label" className="navigation-link" sx={{ textDecoration: "none" }} onClick={handleOrdersClick}>Orders</Box>
      <Box sx={{ display: 'flex', marginLeft: '2rem', marginBottom: '1rem', alignItems: 'center' }}>
        <Box sx={{ fontSize: '1.5rem' }}>
          <b>Case Details</b>
        </Box>
        <Box sx={{ fontSize: '1rem', alignContent: "center", marginLeft: '5rem' }}>
          <b>ID: {order.orderCode}</b>
        </Box>
        <Box sx={{ fontSize: '1rem', marginLeft: '5rem' }}>
            <b>Status:</b> {order.status?.replace(/([A-Z])/g, ' $1').trim()}
        </Box>
      </Box>

      <Tabs className="custom-tabs" value={tabIndex} onChange={(_, e) => setTabIndex(e)} >
        <Tab className="custom-tab" label="General Info" />
        <Tab className="custom-tab" label="History" />
        <Tab className="custom-tab" label="Design Parameters" />
        <Tab className="custom-tab" label="Comments" />
      </Tabs>

      {/* GENERAL INFO */}
      <div role="tabpanel" hidden={tabIndex !== 0} style={{ margin: 30 }}>
        <Grid container spacing={4}>
          <Grid item xs={4.5}>
            <CardDetails
              title="Practitioner"
              entity={{ Name: `${order.orderHistory?.[0].applicationUser.name} ${order.orderHistory?.[0].applicationUser.lastName}`, Delivery: order.office, '': '' }}
            />
          </Grid>
          <Grid item xs={4.5}>
            <CardDetails
              title="Patient"
              entity={{ 'Full Name': `${order.endCustomer?.firstName} ${order.endCustomer?.middleName} ${order.endCustomer?.lastName}`, Gender: order.endCustomer?.gender, Email: order.endCustomer?.email }}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={4.5}>
            <CardDetails
              title="Product"
              entity={{ Name: order.product?.name, Type: order.product?.productType, Category: order.product?.productCategory }}
            />
          </Grid>
          <Grid item xs={4.5}>
            <CardDetails
              title="Schedule"
              entity={{'Order Created': dateFormat(order.dateOfCreation), 'Target Shipping Date': dateFormat(order.targetShippingDate, true), 'Shipped Date': dateFormat(order.shippedDate) }}
            />
          </Grid>
        </Grid>
      </div >

      {/* HISTORY */}
      <div role="tabpanel" hidden={tabIndex !== 1} style={{ margin: 30 }}>
        <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell><b>Status</b></TableCell>
                {remarkRoles.includes(userRole) ? <TableCell><b>Reason</b></TableCell> : undefined}
                <TableCell><b>Date</b></TableCell>
                <TableCell><b>User</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
              order.orderHistory?.map((o: any, i : number) => (
                <TableRow key={i} style={ (i % 2) === 0 ? { backgroundColor: "#bcd9f73f" } : {}}>
                  <TableCell style={{ paddingLeft: 30, borderBottom: 'none' }} ><b>{i+1}</b></TableCell>
                  <TableCell className="custom-table-cell" >{o.status.replace(/([A-Z])/g, ' $1').trim()}</TableCell>
                  {remarkRoles.includes(userRole) ? <TableCell className="custom-table-cell" >{o.reason}</TableCell> : undefined}
                  <TableCell className="custom-table-cell" >{dateFormat(o.date)}</TableCell>
                  <TableCell className="custom-table-cell" >{o.applicationUser ? `${o.applicationUser.name} ${o.applicationUser.lastName}` : 'System'}</TableCell>
                </TableRow>
                ))
            }
            </TableBody>
          </Table>
      </div >

      {/* DESIGN PARAMETERS */}
      <div role="tabpanel" hidden={tabIndex !== 2} style={{ margin: 30 }}>
        {order.designParameters && <DesignParameters
          parameters={order.designParameters.reduce((acc: any, current: any) => {
            acc[current.key] = current.value;
            return acc;
          }, {})}
        />}
      </div>

      {/* REMARKS */}
      <div role="tabpanel" hidden={tabIndex !== 3} style={{ margin: 30, maxWidth: '800px' }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell><b>User</b></TableCell>
              <TableCell><b>Date</b></TableCell>
              <TableCell><b>Type</b></TableCell>
              <TableCell><b>Comment</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
            order.comments?.map((o: any, i : number) => (
              <TableRow key={i} style={ (i % 2) === 0 ? { backgroundColor: "#bcd9f73f" } : {}}>
                <TableCell className="custom-table-cell" >{`${o.applicationUser.name} ${o.applicationUser.lastName}`}</TableCell>
                <TableCell className="custom-table-cell" >{dateFormat(o.commentDate)}</TableCell>
                <TableCell className="custom-table-cell" >{o.commentType}</TableCell>
                <TableCell className="custom-table-cell" >{o.comment}</TableCell>
              </TableRow>
              ))
          }
          </TableBody>
        </Table>
      </div>

    </Box>
  )
}



export class UserRoles {
  public static CustomerSales = 'CustomerSales';
  public static ProductionOperator = 'ProductionOperator';
  public static MotionAdmin = 'MotionAdmin';
  public static MTLSEngineers = 'MTLSEngineers';
  public static CustomerSupport = 'CustomerSupport';
  public static ChiefPractitioner = 'ChiefPractitioner';
  public static Practitioner = 'Practitioner';

  public static addSpace(role: string) {
    const uppercases = role.match(/[A-Z]/g) || [];
    const lastUppercase = role.lastIndexOf(uppercases[uppercases.length - 1]);
    return role.slice(0, lastUppercase) + ' ' + role.slice(lastUppercase);
  }

  public static removeSpace(role: string) {
    return role.replace(/\s+/g, '');
  }
}
import { SetStateAction, useState } from "react";
import {
  Box,
  TextField,
  Checkbox,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  IFilter,
  IListRequestBody,
} from "../../../../domain/Common/RequestBody";

type Props = {
  field: string;
  headerName: string;
  filterList: string[];
  filterLabels?: any;
  setQueryOptions: (value: SetStateAction<IListRequestBody>) => void;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const inputHeight = "30px";

export default function GridHeaderList(props: Props) {
  const [isFiltered, setIsFiltered] = useState(false);

  function handleChange(newValue: string[]) {
    const newFilter = {
      filterType: props.field,
      filterValues: newValue,
    } as IFilter;

    props.setQueryOptions((s) => {
      const existingFilter = s.filters.find(
        (filter: IFilter) => filter.filterType === props.field
      );

      if (existingFilter) {
        const filters = s.filters.filter(
          (filter: IFilter) => filter.filterType !== props.field
        );

        if (newValue.length) filters.push(newFilter);
        else setIsFiltered(false);

        return {
          ...s,
          filters: filters,
          page: 0,
        };
      } else {
        setIsFiltered(true);
        return {
          ...s,
          filters: [...s.filters, newFilter],
          page: 0,
        };
      }
    });
  }

  return (
    <Box sx={{ lineHeight: "31px", textAlign: "left", maxWidth: "100%", width: "7vw" }}>
      <Box sx={{ fontSize: "13px", paddingLeft: "12px" }}>
        {props.headerName}
      </Box>
      <Box>
        <Autocomplete
          multiple
          size="small"
          options={props.filterList}
          getOptionLabel={(option) => option}
          onChange={(event, newValue) => handleChange(newValue)}
          componentsProps={{ popper: { style: { width: "fit-content" } } }}
          disableClearable
          limitTags={0}
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {props.filterLabels ? props.filterLabels[option] : option}
            </li>
          )}
          sx={{
            background: "white",
            borderRadius: "5px",
            caretColor: "transparent",
            height: inputHeight,
            ".MuiInputBase-input": { boxSizing: "inherit" },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled
              sx={{ height: inputHeight }}
              placeholder={isFiltered ? "    Filtered" : "Select Filter"}
              onClick={(event) => event.stopPropagation()}
              inputProps={{
                ...params.inputProps,
                sx: { fontSize: "13px", paddingLeft: "6px" },
              }}
              InputProps={
                isFiltered
                  ? {
                      ...params.InputProps,
                      style: { height: inputHeight },
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ position: "fixed" }}
                        >
                          <FilterAltIcon sx={{ height: "20px" }} />
                        </InputAdornment>
                      ),
                    }
                  : { ...params.InputProps, style: { height: inputHeight } }
              }
            />
          )}
        />
      </Box>
    </Box>
  );
}

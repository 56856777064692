import Grid from '@mui/material/Grid'
import IAddress from '../../../domain/Common/Address';
import { useEffect, useState } from 'react';
import { Country, State, City, IState, ICity } from 'country-state-city';
import { ICompany } from '../../../domain';
import GridProperty from '../../Components/GridProperty/GridProperty';
import { getErrorMessage } from '../../Components/Common/helper';

type Props = {
  entity: IAddress | undefined;
  updateState: Function;
  updateValidations: Function;
  validations?: { [key: string]: string[] };
}

export default function AddCompanyAddress({ entity, updateState, updateValidations, validations }: Props) {
  const countries = Country.getAllCountries();
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  useEffect(() => {
    updateState((oldState: ICompany) => ({ ...oldState, address: { ...entity, state: '', city: '' } }));
    setAddressCountry(entity);
  }, [entity?.country])

  useEffect(() => {
    updateState((oldState: ICompany) => ({ ...oldState, address: { ...entity, city: '' } }));
    setAddressCountry(entity);
  }, [entity?.state])

  function setAddressCountry(_entity?: any) {
    const e = _entity ?? entity;
    const country = countries.find(f => f.name.toLowerCase() === e.country?.toLowerCase());
    if (country) {
      updateState((oldState: ICompany) => ({ ...oldState, address: { ...entity, countryCode: country.isoCode } }));
      const states = State.getStatesOfCountry(country.isoCode);
      setStates(states);
      const state = states.find(s => s.name.toLowerCase() === e.state?.toLowerCase())
      if (state) {
        setCities(City.getCitiesOfState(country.isoCode, state.isoCode));
      } else {
        setCities([]);
      }
    } else {
      setStates([]);
      setCities([]);
    }
  }

  function handleChange(fieldName: string, value?: string) {
    updateState((oldState: ICompany) => ({ ...oldState, address: { ...entity, [fieldName]: value } }));
    if(fieldName === "streetName")
      updateValidations({ ...validations, street: undefined});
    else
      updateValidations({ ...validations, [fieldName]: undefined});
  }

  return (
    <Grid container spacing={1}>
      <GridProperty labelText='Street Name' onChange={(value: any) => handleChange("streetName", value)} side={4}
        required={true} editable={true} value={entity?.streetName} placeholder='Enter Street Name'
        error={!!validations?.street} helperText={getErrorMessage(validations?.street)} />
      <GridProperty labelText='Appartment Number' onChange={(value: any) => handleChange("appartmentNumber", value)} side={4}
        required={false} editable={true} value={entity?.appartmentNumber} placeholder='Enter Appartment Number' />

      <GridProperty labelText='Postal Code' onChange={(value: any) => handleChange("postalCode", value)} side={4}
        required={true} editable={true} value={entity?.postalCode} placeholder='Enter Postal Code'
        error={!!validations?.postalCode} helperText={getErrorMessage(validations?.postalCode)} />
      <GridProperty labelText='Country' onChange={(value: any) => handleChange("country", value)} side={4}
        required={true} editable={true} value={entity?.country} placeholder='Enter Country' selectValues={countries} valueKey='name' freeSolo={false}
        error={!!validations?.country} helperText={getErrorMessage(validations?.country)} />

      <GridProperty labelText='State' onChange={(value: any) => handleChange("state", value)} side={4}
        editable={true} value={entity?.state} placeholder='Enter State' selectValues={states} valueKey='name' freeSolo={true}
        error={!!validations?.state} helperText={getErrorMessage(validations?.state)} />
      <GridProperty labelText='City' onChange={(value: any) => handleChange("city", value)} side={4}
        required={true} editable={true} value={entity?.city} placeholder='Enter City' selectValues={cities} valueKey='name' freeSolo={true}
        error={!!validations?.city} helperText={getErrorMessage(validations?.city)} />
    </Grid>
  )
}


import React from 'react';
import { Navbar } from 'react-bootstrap';

export class Header extends React.Component {
  render() {
    return (
      <div className="topnav">
        <Navbar
          fixed="top"
          expand="lg"
          bg="dark"
          variant="dark"
          className="topnav"
        >
          <Navbar.Brand href=""></Navbar.Brand>
        </Navbar>
      </div>
    );
  }
}


import { Box } from "@mui/material";

type Props = {
  title: string;
};
export default function GridHeaderTitle(props: Props) {
  return (
    <Box sx={{ lineHeight: "31px", fontSize: "13px" }}>
      {props.title}
    </Box>
  );
}

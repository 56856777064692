import "./LicenseAgreement.module.css"
import { materialiseLogo } from "../../../statics/images";
import { Box } from "@mui/system";

export default function LicenseAgreement() {
  return (
    <>
      <Box sx={{ position: 'absolute', right: '78px', top: '55px' }}>
        <img alt="MaterialiseLogo" width='150px' src={materialiseLogo} />
      </Box>
      <Box sx={{ marginLeft: '50px', marginTop: '70px' }}>
        <div style={{ clear: "both" }}>
          <p
            style={{
              marginTop: "0pt",
              marginLeft: "21.25pt",
              marginBottom: "0pt",
              textAlign: "right",
            }}
          >
            <span style={{ width: "432.35pt", display: "inline-block" }}>
              &nbsp;
            </span>
          </p>
        </div>
        <div style={{ clear: "both" }}>
        <h3
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                paddingTop: "1pt",
                paddingLeft: "4pt",
              }}
            >
              <strong>MATERIALISE MOTION END-USER LICENSE AGREEMENT</strong>
          </h3>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "0pt",
              paddingLeft: "4pt",
            }}
          >
            <strong>&nbsp;</strong>
          </p>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "0pt",
              paddingLeft: "4pt",
            }}
          >
              ATTENTION – TO BE READ CAREFULLY BEFORE RENTING, PURCHASING OR
              USING THE LICENSED MATERIAL
          </p>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "0pt",
              paddingLeft: "4pt",
            }}
          >
            <strong>&nbsp;</strong>
          </p>
          <p
            style={{
              marginTop: "0pt",
              marginBottom: "0pt",
              paddingLeft: "4pt",
              paddingBottom: "1pt",
            }}
          >
              Materialise Order Flow Management Software – Other Materialise Motion Products
              designated on the Order Documents
          </p>
        </div>
        <p
          style={{
            marginTop: "0pt",
            marginBottom: "0pt",
            pageBreakAfter: "avoid",
          }}
        >
          &nbsp;
        </p>
      </Box>
      <Box sx={{ marginLeft: '55px', marginRight: '55px', marginTop: '50px', textAlign: 'justify' ,textJustify: 'inter-word' }}>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          1
          <span
            style={{
              width: "17.15pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Scope and Definitions
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.1</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Scope</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          This EULA, together with the Order Documents and the applicable sales
          terms and conditions, shall regulate the entire relationship between
          Materialise Motion and the Customer for the use of the Licensed
          Material. By installing or otherwise using the Licensed Material, the
          Customer agrees to be bound by the terms and conditions of this EULA.
          If the Customer does not accept the terms of this EULA, the Customer
          shall not be authorized to install or use the Licensed Material.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>General Definitions</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Terms shall be defined below and further throughout the EULA. For the
          purpose of this EULA, the following terms shall have the following
          meaning:
        </p>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.1</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “Acceptance”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall mean the acceptance of the Licensed Material by the
            Customer after Delivery as described in Article 2.2 of this EULA.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.2</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “API”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;means application programming interface or a technological
            equivalent as described in the Order Documents.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.3</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “Clinical Use’’
          <span style={{ fontWeight: "normal" }}>
            &nbsp;means the use for the diagnosis, the cure, the mitigation, the
            treatment or the prevention of any disease or other conditions of
            patients (man or animal) from whom the used medical images
            originate, as authorized by the proper regulatory authorities in
            accordance with the laws and regulations of the relevant
            jurisdiction(s).
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.4</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “Customer”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall refer to the individual, firm, association, corporation
            or legal entity that is the rightful holder of the License and who
            wishes to install and/or use the Licensed Material.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.5</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “Delivery”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall mean the moment when Materialise Motion makes the
            Licensed Material available to the Customer as described in Article
            2.1 of this EULA.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.6</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “Documentation”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall refer to the technical publications prepared, delivered
            or made available via the dedicated web-based portal by Materialise
            Motion to the Customer relating to the correct use of the Software,
            such as reference- and (embedded) tutorial manuals.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.7</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “Effective Date”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall refer to the date on which this EULA shall enter into
            force between the Customer and Materialise Motion as described in
            Article 10.1 of this EULA.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.8</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal" }}>“</span>EULA
          <span style={{ fontWeight: "normal" }}>
            ” shall refer to the End-User License Agreement between Materialise
            Motion and the Customer as described herein.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.9</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal" }}>“</span>Customer
          <span style={{ fontWeight: "normal" }}>&nbsp;</span>License
          <span style={{ fontWeight: "normal" }}>
            ” shall refer to the specific license(s) granted by Materialise
            Motion to the Customer. The license term, access and type of this
            Customer License shall be further specified in the applicable Order
            Documents.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.10</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal" }}>“</span>Licensed Material
          <span style={{ fontWeight: "normal" }}>
            ” shall mean the combination of the Software and the Documentation.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.11</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal" }}>“</span>License Region
          <span style={{ fontWeight: "normal" }}>
            ” shall refer to the region(s) for which a wide area network (WAN)
            License or other license type is granted by Materialise Motion to
            the Customer. WAN Licenses or other license types can be granted for
            (all of) the following regions:
          </span>
        </h3>
        <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "56.05pt",
              marginBottom: "6pt",
              paddingLeft: "6.3pt",
              fontSize: "8pt",
            }}
          >
            “<strong>Americas Region</strong>” means the region that is
            comprised of all areas situated in the time zones
            <br />
            -11 up to and including -3, based on the Coordinated Universal Time
            (UTC).
            <br />
            (The continents of North and South America, excluding Greenland.)
          </li>
          <li
            style={{
              marginLeft: "56.4pt",
              paddingLeft: "5.95pt",
              fontSize: "8pt",
            }}
          >
            “<strong>EMEA Region</strong>” means the region that is comprised of
            all areas situated in the time zones -2 up to and including +4,
            based on the Coordinated Universal Time (UTC).
            <br />
            (The continents of Europe, including Russian Federation and
            Greenland, Africa, and the Middle East.)
          </li>
          <li
            style={{
              marginLeft: "56.4pt",
              paddingLeft: "5.95pt",
              fontSize: "8pt",
            }}
          >
            “<strong>APAC Region</strong>” means the region that is comprised of
            all areas situated in the time zones +5 up to and including UTC +12,
            based on the Coordinated Universal Time (UTC) (all Asia- and Pacific
            based territory that is not included in the definitions of the
            Americas Region or the EMEA Region.)
          </li>
          <li
            style={{
              marginLeft: "56.4pt",
              paddingLeft: "5.95pt",
              fontSize: "8pt",
            }}
          >
            <strong>“Global”</strong> means the entire world (all License
            Regions).
          </li>
        </ol>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.12</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “Maintenance”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall mean the support, bug fixes and upgrades of the
            purchased Software modules.
          </span>
          <span style={{ fontWeight: "normal" }}>&nbsp;&nbsp;</span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.13</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal" }}>“</span>Materialise Motion
          <span style={{ fontWeight: "normal" }}>
            ” shall mean Materialise Motion and its affiliated companies.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.14</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “Order Documents”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall refer to all web-based, digital or physical quotations,
            purchase orders, confirmation orders, contracts and invoices issued
            by and between the parties to conclude the agreement in which
            Materialise Motion grants the Customer License to the Customer.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.15</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal" }}>“</span>Password
          <span style={{ fontWeight: "normal" }}>
            ” shall refer to the password delivered by Materialise Motion in the
            form of a license code (CC-Key or voucher or other activation- or
            licensing mechanism) to the Customer that allows the Customer access
            to
          </span>
          <span style={{ fontWeight: "normal" }}>&nbsp;&nbsp;</span>
          <span style={{ fontWeight: "normal" }}>
            use the Licensed Material.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.16</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “SDK”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;means software development kit or a technological equivalent
            as described in the Order Documents.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.17</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal" }}>“</span>Software
          <span style={{ fontWeight: "normal" }}>
            ” shall mean the licensed computer program(s) in machine-readable
            form (object code) or as a web-based application as specified in the
            Order Documents from Materialise Motion or one of its distributors,
            and any successor computer programs or web-based applications of
            Materialise Motion.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>1.2.18</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          “Warranty Period”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall mean the period of ninety (90) days following the
            Effective Date.
          </span>
        </h3>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          2
          <span
            style={{
              width: "17.15pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Delivery and Acceptance
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>2.1</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Delivery</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          The Delivery shall be a fact from the moment that Materialise Motion
          notifies the Customer that the Licensed Material (i) is placed at the
          disposal of the Customer on the Materialise Motion- or appointed
          third-party download web page or dedicated web portal for installation
          on a computer or hosting server by the Customer
          <strong>,&nbsp;</strong>or (ii) is physically made available on a
          media device (USB, DVD, other) or (iii) is digitally made available as
          a web-based application for use by the Customer.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>2.2</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Acceptance</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          The use of the Licensed Material, even partial, shall be considered
          Acceptance of the Licensed Material by the Customer
          <strong>.&nbsp;</strong>Unless agreed otherwise in the Order
          Documents, if the Customer does not activate the Customer Licenses via
          the Password within 3 months after Delivery, the Customer Licenses
          shall automatically be deemed as Accepted by the Customer.&nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>&nbsp;</strong>
        </p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          3
          <span
            style={{
              width: "17.15pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Licensing Conditions
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.1</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>License Fees</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The license fees paid by the Customer are paid in consideration of the
          Customer License(s). Communication of a valid Password is subject to
          payment of the license fees. The Customers’ failure to pay the license
          fees entitles Materialise Motion to suspend or terminate the Customer
          License(s) granted without prejudice to any other rights that may be
          available to Materialise Motion by law.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.2</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Non-Permitted or Illegal Use
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          In the event that the Customer fails to comply with the terms and
          conditions of this EULA or uses the Licensed Material for any purpose
          other than the purpose allowed in this EULA corresponding to the
          Customer License granted to the Customer as specified in the
          applicable&nbsp; Order Documents, the Customer shall be obliged to
          purchase a Commercial License for the full period of non-permitted or
          illegal use by the Customer of the Licensed Material, at a license fee
          that is in accordance with the Materialise Motion list price of a
          Commercial License, increased with penalty of calculated as a
          percentage of the applicable license fee, depending on the type of
          infringement. Furthermore, Materialise Motion shall be entitled to
          suspend or terminate the Customer License at its sole discretion.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.3</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>License Term</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The Licensed Material shall be licensed on an annual, thirty (30) day,
          perpetual, monthly, custom term, or pay-per use basis. Unless
          explicitly indicated otherwise in the Order Documents, the Customer
          License shall be an annual license.&nbsp;
        </p>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.3.1</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>“Annual License”</u>.
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer is granted an Annual License, the Customer
            is entitled to use the Licensed Material for a period of twelve (12)
            consecutive months as of the Effective Date. Annual Licenses shall
            be automatically renewed in accordance with Article 8 of this EULA.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.3.2</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>“Thirty Day License”.</u>
          <u>
            <span style={{ fontWeight: "normal" }}>&nbsp;</span>
          </u>
          <span style={{ fontWeight: "normal" }}>&nbsp;</span>
          <span style={{ fontWeight: "normal" }}>
            When the Customer is granted a Thirty Day License the Customer shall
            be entitled to use the Licensed Material for a period of thirty (30)
            calendar days as of the Effective Date. After this thirty day
            period, the Thirty Day License shall automatically terminate, unless
            the license is renewed in accordance with Article 8 of this
            EULA.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.3.3</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>“Perpetual License”</u>.
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer is granted a Perpetual License, the Customer
            is entitled to use the Licensed Material continuously as of the
            Effective Date, until the Perpetual License is terminated in
            accordance with this EULA.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.3.4</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>“Monthly License”.</u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer is granted a Monthly License, the Customer
            is entitled to use the Licensed Material for a period of one (1)
            month. Monthly Licenses will be automatically renewed in accordance
            with Article 8 of this EULA.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.3.5</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>“Custom Term License”.</u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer is granted a Custom Term License, the
            Customer is entitled to use the Licensed Material for a limited
            period of time as agreed upon between the Customer and Materialise
            Motion in the Order Documents, as of the Effective Date. After this
            custom period, the Custom Term License shall automatically
            terminate, unless the license is renewed in accordance with Article
            8 of this EULA. Alternatively, when agreed otherwise between
            Materialise Motion and Customer in the Order Documents, the Custom
            Term License shall automatically renew.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.3.6</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>“Pay-Per-Use License”.</u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer is granted a Pay-Per-Use license, the
            Customer is entitled to use the Licensed Material for the limited
            number of usages as agreed upon between the Customer and Materialise
            Motion (via a credit system) and/or during a maximum period of
            twelve (12) continuous months as of the Effective Date. After the
            use of all the limited number of usages and/or the twelve (12) month
            period, whichever takes place first, the Pay-Per-Use License shall
            automatically terminate, unless the license is renewed in accordance
            with Article 8 of this EULA.
          </span>
        </h3>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.4</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>License Access</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The Licensed Material shall be licensed as a Single Computer or
          (Single/Multiple) User License, a Floating License or as a WAN
          License. Unless explicitly indicated otherwise in the Order Documents,
          the Customer License shall be a Single Computer or (Single/Multiple)
          User License. Materialise Motion may permit Customer’s usage of a
          virtual private network (VPN), provided such use of the Licensed
          Material shall be in full accordance with this EULA and the license
          (access) types described herein. The Licensed Material may also be
          made available as a web-based application and/or via a dedicated web
          portal.
        </p>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.4.1</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <a id="_Ref6927190">
            <u>“Single Computer or (Single/Multiple) User License”</u>.
            <span style={{ fontWeight: "normal" }}>
              &nbsp;The Licensed Material may be installed and used only on a
              single computer owned, leased or otherwise controlled by the
              Customer. A single computer is defined as one seat with one
              central computer processing unit and one keyboard in a fixed
              location. Unless expressly stipulated otherwise in writing, the
              fixed location is presumed to be the address of the Customer as
              stated on the Order Documents. As a general rule, neither
              concurrent use on two or more computers nor use in a local area
              network (LAN), wide area network (WAN) or another network is
              permitted. Alternatively, the Licensed Material may be made
              available for use via a dedicated web portal for use by specific
              users identified by the Customer. The amount and type of users for
              which the license is granted shall be specified in the Order
              Documents. If no amount and type of users is specified, the amount
              of users shall be limited to one (1) user.&nbsp;
            </span>
          </a>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.4.2</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>“Floating License”.</u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;The Customer is authorised to install a single copy of the
            Licensed Material on one (1) or several computer and is permitted
            access to the Licensed Material under the following cumulative
            conditions:
          </span>
        </h3>
        <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "56.05pt",
              paddingLeft: "6.3pt",
              fontSize: "8pt",
            }}
          >
            All computers (including the hosting server) are owned, leased or
            otherwise controlled by the Customer;
          </li>
          <li
            style={{
              marginLeft: "56.05pt",
              paddingLeft: "6.3pt",
              fontSize: "8pt",
            }}
          >
            The computers and hosting server are connected by a local area
            network (LAN) only and are located or registered in one (1) physical
            location and in one (1) legal entity. Unless expressly stipulated
            otherwise in writing, the physical location is presumed to be the
            address of the Customer as stated on the Order Documents; and
          </li>
        </ol>
        <ol start={3} type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "56.05pt",
              marginBottom: "6pt",
              paddingLeft: "6.3pt",
              fontSize: "8pt",
            }}
          >
            Concurrent use is not permitted.
          </li>
        </ol>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>3.4.3</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>“Wide Area Network License or WAN License”</u>.
          <span style={{ fontWeight: "normal" }}>
            &nbsp;The Customer is authorised to install a single copy of the
            Licensed Material on one (1) computer and is permitted access to the
            Licensed Material under the following cumulative conditions:
          </span>
        </h3>
        <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "56.05pt",
              paddingLeft: "6.3pt",
              fontSize: "8pt",
            }}
          >
            All computers (including the hosting server) are owned, leased or
            otherwise controlled by the Customer;
          </li>
        </ol>
        <ol start={2} type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "56.05pt",
              paddingLeft: "6.3pt",
              fontSize: "8pt",
            }}
          >
            The computers and hosting server are connected by a WAN network
            within one or more of the License Regions, and the Customer holds a
            License for access within the respective License Region(s); and
          </li>
          <li
            style={{
              marginLeft: "56.05pt",
              marginBottom: "6pt",
              paddingLeft: "6.3pt",
              fontSize: "8pt",
            }}
          >
            Concurrent use is not permitted.
          </li>
        </ol>
        <p
          style={{
            marginTop: "0pt",
            marginLeft: "35.45pt",
            marginBottom: "0pt",
            fontSize: "8pt",
          }}
        >
          For a wide area network (WAN) license, the Customer can opt between
          the following regional license types:
        </p>
        <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "56.05pt",
              paddingLeft: "6.3pt",
              fontSize: "8pt",
            }}
          >
            Single Region: access is granted for only one (1) of the License
            Regions.
          </li>
        </ol>
        <ol start={2} type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "56.05pt",
              paddingLeft: "6.3pt",
              fontSize: "8pt",
            }}
          >
            Double Region: access is granted for only two (2) of the License
            Regions.
          </li>
          <li
            style={{
              marginLeft: "56.05pt",
              marginBottom: "6pt",
              paddingLeft: "6.3pt",
              fontSize: "8pt",
            }}
          >
            Global: access is granted for all three (3) of the License Regions.
          </li>
        </ol>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          4
          <span
            style={{
              width: "17.15pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          License Type: Hospital and Commercial Licenses
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.1</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Standard Category</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The License shall be a Hospital License, except if explicitly
          indicated otherwise in the Order Documents.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.2</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Commercial Licenses</span>
          </u>
        </h2>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.2.1</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Scope.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer License is a commercial license&nbsp;
          </span>
          (“Commercial License”)
          <span style={{ fontWeight: "normal" }}>
            , Materialise Motion grants to the Customer, who accepts, a
            non-exclusive, non-transferable, non-sublicenseable license to
            install and use, or use via the web-based application, the Licensed
            Material for Commercial Purposes only. Any other use&nbsp;
          </span>
          <a id="_Hlk115446303">
            <span style={{ fontWeight: "normal" }}>
              is excluded and shall constitute a material breach of this EULA by
              the Customer
            </span>
          </a>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.2.2</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Purpose.&nbsp;</span>
          </u>
          “Commercial Purposes”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall mean the selling, renting, leasing or lending the output
            of the Software for any kind of consideration or the provision of
            services for any kind of consideration, excluding Educational
            Purposes and Academic Purposes, as authorised in the Order
            Documents, this EULA.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.2.3</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Access.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;Commercial Licenses shall be Single Computer or
            (Single/Multiple User) Licenses, except when explicitly indicated
            otherwise on the Order Documents.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.2.4</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Term.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;Commercial Licenses shall be Annual Licenses, except when
            explicitly indicated otherwise in the Order Documents.
          </span>
        </h3>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.3</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Hospital License</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The following conditions shall apply to all Hospital Licenses:
        </p>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.3.1</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Scope.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer License is a hospital license&nbsp;
          </span>
          (“Hospital License”)
          <span style={{ fontWeight: "normal" }}>
            , Materialise grants to the Customer, who accepts, a non-exclusive,
            non-transferable, non-sublicenseable Commercial License to install
            and use the Licensed Material for Clinical Use only. Any other use
            of the Licensed Material is excluded and shall constitute a material
            breach of this EULA by the Customer. Medical device software should
            only be used by trained medical professionals.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.3.2</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Single Copy.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;The Hospital License comes with a user ID and a single-use
            activation link that only works on a single copy of the Software.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.3.3</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Access.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;Hospital Licenses shall only be granted as Single Computer or
            (Single/Multiple) User Licenses, except if explicitly indicated
            otherwise in the Order Documents.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.3.4</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Term.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;Hospital Licenses shall be Annual Licenses or for a mutually
            agreed upon other period between Materialise and Customer.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>4.3.5</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h3>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>&nbsp;</strong>
        </p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          5
          <span
            style={{
              width: "17.15pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          License Type: Other Licenses
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.1</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Indicated Category</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Only in the event that it is indicated in the Order Documents that the
          Customer License is a (Benchmark) Demonstration, Evaluation,
          Commercial, Academic, Educational License or API/SDK License, shall
          the Customer License not be a Hospital License. For all other
          categories other than a Hospital License, Clinical Use is excluded,
          absolutely prohibited, and shall constitute a material breach of this
          EULA by the Customer.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.2</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              (Benchmark) Demonstration License
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The following conditions shall apply to all (Benchmark) Demonstration
          Licenses:
        </p>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.2.1</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Scope.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer License is a (benchmark) demonstration
            license&nbsp;
          </span>
          (“(Benchmark) Demonstration License”)
          <span style={{ fontWeight: "normal" }}>
            , Materialise Motion grants to the Customer, who accepts, a
            non-exclusive, non-transferable, non-sublicenseable license to
            install and use the Licensed Material for (Benchmark) Demonstration
            Purposes only.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.2.2</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Purpose.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>&nbsp;</span>“(Benchmark)
          Demonstration Purposes”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall mean that the Licensed Material may only be used by the
            Customer to demonstrate the Licensed Material to potential end
            customers in order to allow those potential end customers to verify
            whether the Licensed Material suits their needs or not. Any other
            use of the Licensed Material is explicitly excluded. Restrictions to
            the Software functionalities may apply.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.2.3</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Access</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . (Benchmark) Demonstration Licenses shall be Single Computer or
            (Single/Multiple) User Licenses, except if explicitly indicated
            otherwise in the Order Documents.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.2.4</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Term</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . (Benchmark) Demonstration licenses shall be Thirty Day Licenses,
            except if explicitly indicated otherwise in the Order Documents.
          </span>
        </h3>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.3</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Evaluation License&nbsp;
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The following conditions shall apply to all Evaluation Licenses:
        </p>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.3.1</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Scope.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer License is an evaluation license&nbsp;
          </span>
          (“Evaluation License”),
          <span style={{ fontWeight: "normal" }}>
            &nbsp;Materialise Motion grants to the Customer, who accepts, a
            non-exclusive, non-transferable, non-sublicenseable license to
            install and use the Licensed Material for Evaluation Purposes only.
            Any other use of the Licensed Material is explicitly excluded.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.3.2</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Purpose.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>&nbsp;</span>“Evaluation
          Purposes”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall mean the Licensed Material may be used by the Customer
            in order to test whether the Software suits the Customer’s personal
            needs or not.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.3.3</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Access</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . Evaluation Licenses shall be Single Computer or (Single/Multiple)
            User Licenses, except if explicitly indicated otherwise in the Order
            Documents.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.3.4</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Term</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . Evaluation Licenses shall be Thirty Day Licenses, except if
            explicitly indicated otherwise in the Order Documents.
          </span>
        </h3>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.4</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Academic License</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The following conditions shall apply to all Academic Licenses:
        </p>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.4.1</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Scope.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer License is an academic license&nbsp;
          </span>
          (“Academic License”)<span style={{ fontWeight: "normal" }}>,</span>{" "}
          <span style={{ fontWeight: "normal" }}>
            Materialise Motion grants to the Customer, who accepts, a
            non-exclusive, non-transferable, non-sublicensable license to
            install and use the Licensed Material for Academic Purposes only.
            Any other use of the Licensed Material is explicitly excluded.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.4.2</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Purpose.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>&nbsp;</span>“Academic
          Purposes”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall mean use of the Licensed Material for research in an
            academic setting, or any other academic purposes as otherwise agreed
            upon between the Customer and Materialise Motion in the Order
            Documents.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.4.3</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Access</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . Academic Licenses shall be Floating Licenses, except if explicitly
            indicated otherwise in the Order Documents.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.4.4</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Term.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;Academic Licenses shall be Annual Licenses, except if
            explicitly indicated otherwise in the Order Documents.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.4.5</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Password</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . Materialise Motion shall provide one (1) Password per Academic
            License.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.4.6</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Additional Obligations.
            </span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;From Customers who hold Academic Licenses, Materialise Motion
            may request that:
          </span>
        </h3>
        <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "33.35pt",
              paddingLeft: "9.2pt",
              fontSize: "8pt",
            }}
          >
            The Customer makes available its facilities free-of-charge to
            Materialise Motion to conduct trainings or workshops;
          </li>
          <li
            style={{
              marginLeft: "33.35pt",
              paddingLeft: "9.2pt",
              fontSize: "8pt",
            }}
          >
            The Customer informs students of the interest of Materialise Motion
            to assist students willing to publish articles in which reference is
            made to the use of the Licensed Material;
          </li>
          <li
            style={{
              marginLeft: "33.35pt",
              paddingLeft: "9.2pt",
              fontSize: "8pt",
            }}
          >
            The Customer publishes and/or presents the results of the work,
            achieved by using the Licensed Material. In all such publications
            and presentations, the Customer shall mention that Materialise
            Motion granted the right to use the Licensed Material, and refer to
            the Licensed Material with the appropriate trademark notices;
          </li>
          <li
            style={{
              marginLeft: "33.35pt",
              paddingLeft: "9.2pt",
              fontSize: "8pt",
            }}
          >
            The Customer shall provide Materialise Motion with a copy of the
            publication; and
          </li>
          <li
            style={{
              marginLeft: "33.35pt",
              paddingLeft: "9.2pt",
              fontSize: "8pt",
            }}
          >
            The Customer entitles Materialise Motion to refer in public without
            restriction to the Customer as an official Materialise Motion
            software user.
          </li>
        </ol>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.5</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Educational License&nbsp;
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The following conditions shall apply to all Educational Licenses:
        </p>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.5.1</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Scope.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;When the Customer License is an educational license&nbsp;
          </span>
          (“Educational License”)
          <span style={{ fontWeight: "normal" }}>
            , Materialise Motion grants to Customer, who accepts, a
            non-exclusive, non-transferable, non-sublicensable license to
            install and use the Licensed Material for Educational Purposes only.
            Any other use of the Licensed Material is explicitly excluded.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.5.2</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Purpose.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>&nbsp;</span>“Educational
          Purposes”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;shall mean the use of the Licensed Material for the teaching
            and training of students in an educational setting, or any other
            educational purposes as otherwise agreed upon between the Customer
            and Materialise Motion in the Order Documents.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.5.3</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Access.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;Educational Licenses shall be Floating Licenses, except if
            explicitly indicated otherwise in the Order Documents.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.5.4</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Term</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . Educational Licenses shall be Annual Licenses, except when
            explicitly indicated otherwise in the Order Documents.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.5.5</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Additional Obligations</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . From Customers who hold Educational Licenses, Materialise Motion
            may request that:
          </span>
        </h3>
        <ol type="i" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "33.35pt",
              paddingLeft: "2.1pt",
              fontSize: "8pt",
            }}
          >
            The Customer makes available its facilities free-of-charge to
            Materialise Motion to conduct trainings or workshops;
          </li>
          <li
            style={{
              marginLeft: "33.35pt",
              paddingLeft: "2.1pt",
              fontSize: "8pt",
            }}
          >
            The Customer informs students of the interest of Materialise Motion
            to assist students willing to publish articles in which reference is
            made to the use of the Licensed Material;
          </li>
          <li
            style={{
              marginLeft: "33.35pt",
              paddingLeft: "2.1pt",
              fontSize: "8pt",
            }}
          >
            The Customer publishes and/or presents the results of the work,
            achieved by using the Licensed Material. In all such publications
            and presentations, the Customer shall mention that Materialise
            Motion granted the right to use the Licensed Material, and refer to
            the Licensed Material with the appropriate trademark notices;
          </li>
          <li
            style={{
              marginLeft: "33.35pt",
              paddingLeft: "2.1pt",
              fontSize: "8pt",
            }}
          >
            Customer shall provide Materialise Motion with a copy of the
            publication; and
          </li>
          <li
            style={{
              marginLeft: "33.35pt",
              paddingLeft: "2.1pt",
              fontSize: "8pt",
            }}
          >
            The Customer entitles Materialise Motion to refer in public without
            restriction to the Customer as an official Materialise Motion
            software user.
          </li>
        </ol>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.6</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>API/SDK License</span>
          </u>
        </h2>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.6.1</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Scope</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . When the Customer License is an API/SDK license&nbsp;
          </span>
          (“API/SDK License”)
          <span style={{ fontWeight: "normal" }}>
            , Materialise Motion grants to Customer, who accepts, a
            non-exclusive, non-transferable, non-sublicensable license to
            install and use the Licensed Material for API/SDK Use Purposes only.
            Any other use of the Licensed Material is explicitly excluded except
            if explicitly indicated otherwise on the Order Documents.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.6.2</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Purpose.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>&nbsp;</span>“API/SDK Use
          Purposes”
          <span style={{ fontWeight: "normal" }}>
            &nbsp;means, but are not limited to, the following use purposes: (i)
            the implementation and use of Materialise Motion APIs and API
            specifications to allow a software application to interoperate with
            Materialise Motion Software or other products, (ii) the right of the
            Customer to integrate Materialise Motion SDK into a component to
            allow such component to interoperate with Materialise Motion
            Software or another product.
          </span>{" "}
          <span style={{ fontWeight: "normal" }}>
            Further details governing use cases shall and must be agreed upon
            between the Customer and Materialise Motion in the Order Documents
            prior to any use of the API/SDK License.
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.6.3</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Access.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;API/SDK Licenses shall be as agreed upon between the Customer
            and Materialise Motion in the Order Documents.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.6.4</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Term</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . API/SDK Licenses shall be as agreed upon between the Customer and
            Materialise Motion in the Order Documents.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>5.6.5</span>
          <span
            style={{
              width: "18.21pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Additional Obligations</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            . Any additional obligations governing an API/SDK License must and
            shall be stipulated in the Order Documents.&nbsp;
          </span>
        </h3>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          &nbsp;
        </h1>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          6
          <span
            style={{
              width: "17.15pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Password
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>6.1</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Password</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Access to the Licensed Material is granted to the Customer by a
          Password provided by Materialise Motion, unless agreed otherwise in
          the Order Documents.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>6.2</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Validity Period</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Each Password has a specified validity period. On its request, and
          provided that the Customer is in full compliance with all applicable
          agreements between said Customer and Materialise Motion, the Customer
          shall be given a new Password at the expiration date of the validity
          period. The Customer can then install, and use, the Licensed Material
          for a new validity period, as per the applicable conditions of the
          type of license.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>6.3</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Access Transfer and Restrictions
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Should the single computer or hosting server, on which the Licensed
          Material is installed, be out of order, or replaced, during the
          Password validity period, the Customer can obtain a new Password for
          installation of the Licensed Material on another single computer or
          hosting server of the Customer, subject to the payment of a transfer
          fee and after having certified, in writing to Materialise Motion, that
          the previous computer or hosting server is no longer in use by the
          Customer. Materialise Motion shall waive the transfer fee if the
          Customer has a valid Maintenance agreement at the time of the
          requested transfer. This transfer fee is not applicable in the event
          of use of to the web-based Licensed Material via a dedicated
          web-portal. Customer shall not share, make available or otherwise
          disclose Passwords to third parties except if and when agreed between
          Materialise Motion and the Customer in the Order Documents.
        </p>
        <p
          style={{
            marginTop: "0pt",
            marginLeft: "28.35pt",
            marginBottom: "0pt",
          }}
        >
          <strong>&nbsp;</strong>
        </p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          7
          <span
            style={{
              width: "17.15pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Rights of Materialise Motion – Restrictions
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>7.1</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Intellectual Property</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          All right, title, interest and associated intellectual property rights
          in, and to, the Licensed Material are, and shall always remain with,
          Materialise Motion and the third-party (kernel) technology providers
          from which Materialise Motion has validly licensed technology. This
          EULA does not convey to the Customer any right, title and interest in,
          or to, the Licensed Material, but only grants the Customer a limited
          right of use of the Licensed Material in accordance with the terms of
          this EULA, as specified in the applicable Order Documents, and
          revocable by Materialise Motion in accordance with the terms of this
          EULA.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>7.2</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Third Party Terms and Conditions
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The Licensed Materials (or portions thereof incorporating third-party
          (kernel) technology) may be subject to terms that are in addition to
          the terms set forth in this EULA, and the Customer hereby agrees to
          comply with such terms. These terms are included in the Licensed
          Material and may include, but are not limited to, end-user software
          licenses of third party (free/open-source) software incorporated in
          the Licensed Materials. Such third-party software shall be licensed,
          and the Customer shall use such third-party software, in accordance
          with the applicable terms and conditions of the respective
          third-party-licensor(s).
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>7.3</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Verification - Audit</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Materialise Motion shall have the right to request verification of,
          and audit, the computer(s) or hosting server(s) on which the Licensed
          Material has been installed, with the purpose to ensure compliance by
          the Customer with the terms and conditions of this EULA.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>7.4</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Compliance</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Materialise Motion monitors compliance with this EULA. If unauthorized
          usage of the Software is detected, this may cause the Software to
          operate in an incorrect or non-stable way, and data may become
          corrupted.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>7.5</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              No Reverse Engineering - Modification
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The Customer shall not adapt, disassemble, reverse assemble, reverse
          compile, reverse engineer, decompile, alter, modify or otherwise
          translate the Licensed Material, or any part thereof, it being
          understood that deviations to this rule concerning the API/SDK Use
          Purposes may be permitted only if explicitly stipulated in the Order
          Documents. The Customer shall not utilize any equipment, software or
          other means to circumvent or remove, or try to do so, any form of
          technical protection used by Materialise Motion in connection with the
          Software. Any such action shall constitute a material breach of this
          EULA by the Customer.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>7.6</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>No Illegal Copies</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Upon execution of this EULA, the Customer agrees and undertakes (i) to
          immediately delete and permanently remove from the Customer’s own
          computers and servers (on premise as well as in the cloud) all and any
          software constituting infringement of Intellectual Property Rights of
          Materialise Motion with respect to the Licensed Material and/or not
          compliant with the Customer License and the Order Documents; (ii) to
          refrain from making use, or making copies and/or downloads, of any
          Licensed Material not previously and regularly licensed by Materialise
          Motion; and (iii) to represent and warrant that the Customer’s own
          representatives, employees, agents and/or consultants do not have, or
          use, any unauthorized copies of the Licensed Material.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>7.7</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>No Transfer/Sublicense</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Except if otherwise agreed upon between the Customer and Materialise
          Motion in the Order Documents, the Customer shall have no right to
          assign, sublicense, transfer, pledge, lease, rent or share the rights
          granted under this EULA, nor sell the Licensed Material or any part or
          copy thereof to any third party. Any such action shall constitute a
          material breach of this EULA by the Customer.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>&nbsp;</strong>
        </p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          8
          <span
            style={{
              width: "17.15pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <a id="_Ref7008440">Additional Terms – Upgrades and Renewals</a>
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>8.1</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Modifications</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Materialise Motion, as the sole and exclusive owner of the Licensed
          Material, reserves the right to modify and update the Licensed
          Material.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>8.2</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Additional Materials</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          When Materialise Motion makes available any additional materials
          associated with the Licensed Materials to the Customer, Materialise
          Motion shall use its best efforts to identify and include such
          additional materials in the Licensed Materials. This may include any
          corrections, patches, updates, upgrades to, third party software
          incorporated in, or new versions of the Licensed Materials. Such
          additional materials (i) may include or be subject to other terms that
          are in addition to the terms as set forth in this EULA, and the
          Customer agrees to comply with such terms, or (ii) if there are no
          other terms for such additional materials, they will be subject to the
          same terms as the Licensed Material.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>8.3</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Renewal of Annual Licenses
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Annual Licenses may be automatically renewed every year for twelve
          (12) months, unless terminated in writing by either party at least
          three (3) months before the expiration date of the running Customer
          License. An invoice covering the renewal charge may be sent to the
          Customer at the beginning of each new Customer License period. A
          renewed Customer License commences on the end date of the previous
          Customer License.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>8.4</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Renewal of Thirty-Day/Monthly/Custom Term/Pay-per-Use Licenses
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Thirty-Day/Monthly/Custom Term/Pay-per-Use Licenses shall be
          automatically renewed every period for one (1) more equal period,
          unless either party cancels the renewal before the expiration date of
          the running Customer License. An invoice covering the renewal charge
          shall be sent to the Customer at the beginning of each new Customer
          License period. A renewed Customer License commences on the end date
          of the previous Customer License.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>8.5</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Customer Renewal Involvement
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          The Customer agrees that, for a Customer License that requires
          explicit renewal action from the Customer, the Customer shall inform
          Materialise Motion in a timely manner, and before the Customer License
          end date in accordance with Article 10, by placing a purchase order.
          Whenever Materialise Motion receives such a purchase order any later
          then the aforementioned time, Materialise Motion may charge an
          administrative fee per user for the Customer License.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          9
          <span
            style={{
              width: "17.15pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Privacy, Data Collection and Confidentiality
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>9.1</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Privacy Notice</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          By providing Materialise Motion with its contact information, the
          Customer agrees that Materialise Motion may use the Customer’s
          personal data (if and when provided) in accordance with the
          Materialise Motion Data and Privacy Policy (available at:
          <br />
          https://www.materialise.com/en/data-privacy-notice).&nbsp; This data
          collection by Materialise Motion derives from a legitimate interest
          and a contractual basis.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>9.2</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Data Collection for Pricing
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Materialise Motion reserves the right to use data collection
          technology to collect information such as the location, type of users,
          software- and hardware environment, third-party integrations or
          connections of Customer when using of the Software for pricing
          determination (including but not limited to pay-per-use)
          purposes.&nbsp; By installing and using the Software, the Customer
          acknowledges and agrees that Materialise Motion may collect, disclose
          to third parties, store and analyze the collected information for the
          purposes mentioned above.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>9.3</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Data Collection to Prevent Illegal Use
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Materialise Motion reserves the right to use data collection
          technology to collect anonymous technical information and data in
          order to prevent and detect any unlicensed or illegal use of the
          Software. The term “data” refers to user-related and network
          information (including, but not limited to, information about IP
          addresses, MAC addresses, host names, user names and email addresses).
          By installing and using the Software, the Customer acknowledges and
          agrees that Materialise Motion may, in user’s jurisdiction or other
          countries, collect, store, analyze, disclose to third parties (subject
          to confidentiality provisions) and use the collected information for
          the purposes mentioned above. To know how the data is managed, please
          refer to the Privacy Policy published on
          https://www.materialise.com/en/data-privacy-notice.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>9.4</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Data Collection to Improve User Experience
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Upon installation and/or use of the Licensed Material, Materialise
          Motion uses data collection technology to collect certain anonymous
          information (such as features used, error occurrence, metadata) on
          your use and troubleshooting of the Licensed Material. This
          information is solely used to improve the Licensed Material and user
          experience. No information is shared with any third parties. No
          proprietary information is collected. No personal information is
          collected. By installing and/or using the Licensed Material, the
          Customer acknowledges and agrees that Materialise Motion may collect,
          store and analyze the collected information for the purposes mentioned
          herein.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>9.5</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Confidentiality Obligations - Feedback
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Customer acknowledges that Customer may have access to, and
          Materialise Motion may disclose to the Customer, certain valuable
          information belonging to and relating to Materialise Motion which
          Materialise Motion considers confidential, including, but not limited
          to, information concerning the Licensed Material, the Software’s
          trademark(s) and trade name(s), computer programs, user manuals, sales
          and marketing plans, business plans, processes, customer lists, and
          other trade secrets (the “<strong>Confidential Information</strong>”).
          The Customer shall: (i) use the Confidential Information solely for
          using the Licensed Material in accordance with the EULA and only until
          the date of termination or expiration of the license; (ii) maintain
          the Confidential Information as confidential during the term of the
          Licensed Material and for a period of one (1) year after the term;
          (iii) limit the internal dissemination of the Confidential Information
          within its own organization only to those individuals who need to know
          such information; (iv) safeguard the Confidential Information with the
          same degree of care normally afforded such Confidential Information in
          the Customer’s custody or control, but in no event less than a
          reasonable care. Any feedback, ideas, modifications, suggestions,
          improvements made by the Customer with respect to the Licensed
          Material may be freely used by Materialise Motion for further
          improving and perfecting the Licensed Material.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>9.6</span>
          <span
            style={{
              width: "17.68pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Security.&nbsp;</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Parties shall make reasonable efforts to physically and digitally
          secure and protect the Licensed Material in a manner consistent their
          ordinary business practices in order to satisfy the obligations under
          this EULA.
        </p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          10
          <span
            style={{
              width: "12.7pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Term and Termination
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>10.1</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Effective Date</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          This EULA, and the Customer Licenses granted in accordance with this
          EULA, become effective upon the Delivery and Acceptance of the
          Licensed Materials in accordance with Article 2.&nbsp; If the Customer
          does not activate the Customer Licenses via the Password within 3
          months after Delivery and Acceptance, the Effective Date of the
          Customer Licenses shall be set at 3 months after the date of Delivery.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>10.2</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Term of Customer License
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Without prejudice to Article 10.7, the term of the Customer License,
          and subsequent expiration date, shall be determined in accordance with
          Article 3.3 of this EULA and as specified in the applicable Order
          Documents.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>10.3</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Termination by Customer
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          The Customer may terminate the Customer License, at any time by
          returning, or destroying (via permanent removal), any Licensed
          Material in its possession.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>10.4</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Termination by Materialise Motion
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Materialise Motion may terminate the Customer License immediately upon
          any breach, material or otherwise, by the Customer of any of the
          provisions set forth in this EULA. (Benchmark) Demonstration and
          Evaluation Licenses can be terminated by Materialise Motion at any
          time when the Customer fails to comply with the terms and conditions
          of this EULA, or when Materialise Motion has serious reason to believe
          that this is, or has been, the case. In addition, Materialise Motion
          retains the right to unilaterally suspend or terminate any Customer
          Licenses granted if (i) Customer claims Materialise Motion is actually
          or allegedly infringing Customer intellectual property, and/or if (ii)
          Customer otherwise directly or indirectly supports any claim made by
          any third party that Materialise Motion is actually or allegedly
          infringing intellectual property.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>10.5</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Consequences</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          In case of termination or expiration of the Customer License, the
          Customer License shall be revoked immediately and therefore all
          associated Software shall automatically become non-functional. Upon
          termination or expiration of the Customer License, the Customer shall
          (i) stop using the Licensed Materials; and (ii) return or destroy (at
          the sole discretion of Materialise Motion) all Licensed Materials.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>10.6</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>No Refund</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          No refund of any amount paid by the Customer shall be made, except
          when the Customer is entitled to a refund in accordance with Article
          12 (Warranty) of this EULA.&nbsp;
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>10.7</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>End-Of-Life</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The Customer recognizes that any Software has a given product
          lifecycle in accordance with Materialise Motion product policies and
          in operability with third party programs or -operating systems, and
          that Materialise Motion retains the right to cease the Maintenance for
          certain (older) versions of the Software. Whenever such an event
          should occur, Materialise Motion shall give a reasonable advance
          notice to the Customer concerning such cessation of Maintenance for
          certain version(s) of the Software. To continue the use of the
          Software, albeit in the newer version(s), the Customer shall be
          required to upgrade to a newer/newest Software version, for which
          Materialise Motion does provide Maintenance.
        </p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          11
          <span
            style={{
              width: "12.7pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Maintenance
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>11.1</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Maintenance Provision</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Unless (i) Maintenance is included in the license fee and/or has been
          paid by the Customer, or (ii) a Materialise Motion Service Level
          Agreement <strong>(“SLA”)</strong> covering elevated maintenance and
          support levels (as the case may be) has been concluded between the
          Customer and Materialise Motion, Materialise Motion shall have no
          obligation to provide any Maintenance to the Customer. Payable support
          may be made available by Materialise Motion upon request. Depending on
          the Software, it may also include an emergency password replacement
          service. Emergency passwords are valid for one (1) week and can be
          requested for a maximum of three (3) times per year. Maintenance also
          allows the transfer of the Software to another computer or hosting
          server of the Customer and to a computer located at the same address
          as the first computer without a transfer fee being due, in accordance
          with Article 6.3. If there are unreasonable support requests,
          Materialise Motion can ask the Customer to follow a paid training
          first before providing further Maintenance.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>11.2</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Maintenance by Third Party
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          All efforts undertaken by the Customer, or any third party not
          approved by Materialise Motion, to provide Maintenance for the
          Licensed Material shall be at Customer’s own risk and expense and
          shall make any warranty provided by Materialise Motion null and void.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>11.3</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Effective Date and Renewal
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Maintenance obligations included in the license fees become effective
          on the Effective Date and may be renewed automatically every year for
          one (1) year, unless terminated in writing by either party at least
          three (3) months before the expiration date of the Maintenance
          obligations. An invoice covering the renewal charge may be sent to the
          Customer at the beginning of each Maintenance renewal. A Maintenance
          renewal commences on the end date of the Maintenance period. Whenever
          the Customer wishes to have Maintenance for Licensed Material for
          which the Customer was previously entitled to Maintenance but is not
          currently under Maintenance, the gap shall be compensated by the
          Customer via the payment of Maintenance fees for the elapsed period of
          time after expiration of the previous Maintenance period.
          <a id="_Hlk47600081">
            &nbsp;
            <em>
              For the territory of Japan: in the case of an automatic renewal of
              Maintenance, a purchase order may be provided by the Customer to
              Materialise Motion confirming such an automatic renewal of
              Maintenance.
            </em>
          </a>
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>11.4</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>License Fee</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Fees for Maintenance are calculated as a percentage of the applicable
          list price for the Software. Modifications to the Maintenance charge
          shall be applied only for contract periods that have not yet
          commenced. The Maintenance charge is payable in accordance with the
          Materialise Motion general sales terms and conditions unless
          explicitly agreed otherwise in the Order Documents.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>11.5</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Maintenance Included in License Fee
            </span>
          </u>
        </h2>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>11.5.1</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Annual Licenses.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;For Annual Licenses, the Maintenance is included in the annual
            license fee.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>11.5.2</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Perpetual Licenses.</span>
          </u>
          <span style={{ fontWeight: "normal" }}>
            &nbsp;For Perpetual Licenses, Maintenance for a one (1) year period
            is included in the license fee unless written otherwise in the Order
            Documents.&nbsp;
          </span>
        </h3>
        <h3
          style={{
            marginTop: "6pt",
            marginLeft: "36pt",
            marginBottom: "0pt",
            textIndent: "-36pt",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>11.5.3</span>
          <span
            style={{
              width: "13.76pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Hospital License</span>
          </u>
          <span style={{ fontWeight: "normal" }}>&nbsp;</span>
        </h3>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>11.6</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>No Maintenance&nbsp;</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          There is no Maintenance for (Benchmark) Demonstration, Evaluation,
          Academic, Educational or API/SDK Licenses unless explicitly indicated
          in the Order Documents.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>&nbsp;</strong>
        </p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          12
          <span
            style={{
              width: "12.7pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Warranty
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>12.1</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Commercial Licenses&nbsp;
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          For Commercial Licenses, Materialise Motion warrants to the Customer
          that the Licensed Material is free from defects in material and
          workmanship during the Warranty Period. Materialise Motion further
          warrants that during the Warranty Period the Software shall operate
          substantially in accordance with the functional specifications in the
          Documentation. If during the Warranty Period, it appears that any part
          of the Software does not function in accordance with the functional
          specifications in the Documentation, Customer may return the Licensed
          Material to Materialise Motion for replacement or refund of the paid
          license fee, at Customer’s choice. Customer agrees that the foregoing
          constitutes its sole and exclusive remedy for breach by Materialise
          Motion of warranties made under this EULA. Except for the warranties
          set forth above, the Licensed Material is licensed “AS IS”, and
          Materialise Motion disclaims any and all other warranties, whether
          express or implied, including, without limitation, any implied
          warranties of merchantability, fitness for a particular purpose or
          non-infringement and warranties created by a course of dealing or
          performance or a trade usage.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>12.2</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Educational and Academic Licenses
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          For Educational and Academic Licenses, Materialise Motion warrants to
          Customer that during the Warranty Period the Software shall perform
          substantially in accordance with the functional specifications in the
          Documentation. Materialise Motion’s sole obligation under this
          warranty shall be to use its best efforts to correct errors in the
          Software. Except for the warranties set forth above, the Licensed
          Material is licensed “AS IS”, and Materialise Motion disclaims any and
          all other warranties, whether express or implied, including, without
          limitation, any implied warranties of merchantability or fitness for a
          particular purpose, or non-infringement and warranties created by a
          course of dealing or performance or a trade usage.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>12.3</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              (Benchmark) Demonstration, Evaluation and API/SDK Licenses
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          For (Benchmark) Demonstration, Evaluation and API/SDK Licenses, the
          Licensed Material is provided "AS IS" and with no warranties of any
          kind, whether express or implied, including, without limitation, any
          warranty of merchantability or fitness for a particular purpose, or
          non-infringement and warranties created by a course of dealing or
          performance or a trade usage.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          13
          <span
            style={{
              width: "12.7pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Limitation of Liability
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>13.1</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>No Indirect Damages</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Neither party shall be liable for any indirect, incidental, special or
          punitive damages of any kind, including but not limited to (i) lost
          profits, (ii) inability to use the Licensed Material, (iii) loss of
          data or (iv) business interruptions, even if informed of the
          likelihood of such damages and even if the limited remedies herein
          fail their purpose.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>13.2</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Liability Cap</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          The liability of any party for any damages under this EULA shall in
          any event be limited to actual amounts paid in the last twelve (12)
          months by the Customer to Materialise Motion under this EULA.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>13.3</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Force Majeure</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Neither party shall be liable in case of force majeure. Force majeure
          implies any circumstance beyond the control of the party – even if
          this circumstance was foreseeable at the time the agreement was
          concluded – which permanently or temporarily prevents fulfillment of
          this EULA.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>13.4</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Third Party Software / (Cloud) Hosting Platforms
            </span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          Materialise Motion disclaims any and all liability concerning the
          access, compatibility, uptime, downtime, availability,
          non-infringement or any other warranties created by a course of
          dealing or performance or a trade usage relating to any use by
          Materialise Motion or the Customer of any third-party products or
          (hosting/cloud) platforms in combination with the Licensed Material.
        </p>
        <h1
          style={{
            marginTop: "0pt",
            marginLeft: "21.6pt",
            marginBottom: "0pt",
            textIndent: "-21.6pt",
            pageBreakAfter: "avoid",
            fontSize: "10pt",
          }}
        >
          14
          <span
            style={{
              width: "12.7pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Miscellaneous
        </h1>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>14.1</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Severability</span>
          </u>
          <em>
            <u>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
            </u>
          </em>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          If any provision of this EULA is held by a court of competent
          jurisdiction to be illegal, invalid or unenforceable, then such
          provision shall (in so far as it is invalid or unenforceable) be given
          no effect and shall be deemed not to be included, but without
          invalidating any of the remaining provisions of this EULA. The parties
          agree to then use all reasonable efforts to agree upon a valid and
          enforceable substitute provision of which the effect is as close as
          possible to the intended effect of the invalid or unenforceable
          provision.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>14.2</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>No Waiver</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          The failure of a party to enforce any of the provisions of this EULA,
          or to exercise any option provided in this EULA, or to require
          performance by the other party of any of the provisions in this EULA,
          is not a present or future waiver of such provisions and does not
          affect the validity of this EULA or the right of the party to enforce
          each and every provision of this EULA thereafter.&nbsp; The express
          waiver (whether one or multiple times) by a party of any provision,
          condition or requirement of this EULA does not constitute a waiver of
          any future obligation of the other party to comply with such
          provision, condition or requirement.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>14.3</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>No Assignment</span>
          </u>
          <em>
            <u>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
            </u>
          </em>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          The Customer shall not assign its rights under this EULA to any third
          party, unless explicitly agreed to by Materialise Motion in writing.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>14.4</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Hierarchy&nbsp;</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          Unless expressly stipulated otherwise in writing, in the event of a
          conflict or contradiction between the terms of this EULA and any
          quotation and/or invoice and/or other agreement entered into between
          the parties concerning the Licensed Material, the terms of this EULA
          shall take precedence.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>14.5</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Language</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "6pt", fontSize: "8pt" }}>
          In the event of any discrepancy between a translated version and the
          original English version of this EULA, the English language version of
          this EULA shall control.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>14.6</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>Compliance</span>
          </u>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          The Customer shall at all times comply with all applicable U.S., E.U.,
          U.K. and national export control, tax and trade sanctions laws, rules
          and regulations <strong>(“Export Control Laws”)</strong> where
          Customer operates or is located. The Customer warrants and represents
          to Materialise Motion that it (i) shall not use, share, make
          available, sell, modify, reverse engineer or distribute any Licensed
          Material in a manner that infringes any Export Control Laws and (ii)
          shall monitor changes and verify continued compliance with amended or
          updated Export Control Laws.
        </p>
        <h2
          style={{
            marginTop: "6pt",
            marginLeft: "28.8pt",
            marginBottom: "0pt",
            textIndent: "-28.8pt",
            pageBreakAfter: "avoid",
            fontSize: "8pt",
          }}
        >
          <span style={{ fontWeight: "normal" }}>14.7</span>
          <span
            style={{
              width: "13.23pt",
              fontSize: "8pt",
              display: "inline-block",
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span style={{ fontWeight: "normal" }}>
              Governing Law and Jurisdiction
            </span>
          </u>
          <em>
            <u>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
            </u>
          </em>
        </h2>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
          This EULA shall be governed by the laws of the registered office of
          Materialise Motion. Any dispute shall be submitted to the exclusive
          jurisdiction of the courts of the registered office of Materialise
          Motion.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "6pt" }}>&nbsp;</p>
        <div style={{ clear: "both" }}>
          <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "8pt" }}>
            Materialise Motion End-User License Agreement (version 2022)
            <span style={{ width: "1.36pt", display: "inline-block" }}>
              &nbsp;
            </span>
          </p>
        </div>
      </Box>
    </>
  );
}

import { GridColDef } from "@mui/x-data-grid";

const OfficeColumns: GridColDef[] = [
    { field: 'name', headerName: 'Name', type: 'string', flex: 0.8, description: 'listFilter', disableColumnMenu: true },
    { field: 'officeType', headerName: 'Office Type', type: 'string', flex: 0.8, description: 'listFilter', disableColumnMenu: true },
    { field: 'customerNumber', headerName: 'Customer Number', type: 'string', flex: 0.8, description: 'textFilter', disableColumnMenu: true },
    { field: 'invoiceTo', headerName: 'Invoice To', type: 'string', flex: 0.8, description: 'textFilter', disableColumnMenu: true },
    { field: 'shipTo', headerName: 'Ship To', type: 'string', flex: 0.8, description: 'textFilter', disableColumnMenu: true },
    { field: 'address', headerName: 'Address', type: 'string', flex: 2, description: 'textFilter', disableColumnMenu: true },
]

export default OfficeColumns; 


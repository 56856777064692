import { ChangeEvent, SetStateAction } from "react";
import { Box, TextField } from "@mui/material";
import {
  IFilter,
  IListRequestBody,
} from "../../../../domain/Common/RequestBody";

type Props = {
  field: string;
  headerName: string;
  setQueryOptions: (value: SetStateAction<IListRequestBody>) => void;
};

const inputHeight = "30px";

export default function GridHeaderText(props: Props) {
  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    props.setQueryOptions((s) => {
      const existingFilter = s.filters.find(
        (filter: IFilter) => filter.filterType === props.field
      );

      if (existingFilter) {
        return {
          ...s,
          filters: s.filters.map((filter: IFilter) =>
            filter.filterType === props.field
              ? {
                  filterType: props.field,
                  filterValues: [event.target.value as string],
                }
              : filter
          ),
          page: 0,
        };
      } else {
        return {
          ...s,
          filters: [
            ...s.filters,
            {
              filterType: props.field,
              filterValues: [event.target.value as string],
            },
          ],
          page: 0,
        };
      }
    });
  }

  return (
    <Box sx={{ lineHeight: "31px", textAlign: "left", maxWidth: "100%", width: "7vw" }}>
      <Box sx={{ fontSize: "13px", paddingLeft: "10px" }}>
        {props.headerName}
      </Box>
      <Box>
        <TextField
          variant="filled"
          size="small"
          onClick={(event) => event.stopPropagation()}
          onChange={(event) => handleChange(event)}
          sx={{ height: inputHeight }}
          inputProps={{
            sx: {
              height: "32px",
              paddingTop: "4px",
              fontSize: "13px",
              "::placeholder": { fontSize: "13px" },
            },
          }}
          InputProps={{
            style: {
              height: inputHeight,
              backgroundColor: "white",
              borderRadius: "5px",
            },
            disableUnderline: true
          }}
          placeholder="Filter"
        />
      </Box>
    </Box>
  );
}

import { MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import companiesService from "../../../services/Companies/CompaniesService";
import { ICompanyDetails } from "../../../domain/Company/CompanyDetalis";
import CardDetails from "../../Components/CardDetails/CardDetails";
import Popup from "../../Components/Popup/Popup";
import { useState } from "react";

type Props = {
  generalInfo: ICompanyDetails | undefined;
  setGeneralInfo: any;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const companyTypeOptions = ["Sales", "Materialise", "Production", "CustomerSupport"];


export default function GeneralInfo({ generalInfo, setGeneralInfo, setReload }: Props) {
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false);
  
  const input = [
    {
      key: "companyType",
      component:
        <TextField size="small" select variant="standard" fullWidth value={generalInfo?.companyType}
          onChange={(e) => setGeneralInfo({...generalInfo, companyType: e.target.value})}
          SelectProps={{
            IconComponent: (props) => <KeyboardArrowDownIcon {...props} sx={{ fontSize: "1rem" }} />,
            style: {fontFamily: "Metronic Pro", fontSize: "0.875rem", textAlign: "end"},
            disableUnderline: true,
          }}
          
        >
          {companyTypeOptions.map((companyType) => ( <MenuItem key={companyType} value={companyType}>{ companyType }</MenuItem> ))}
        </TextField>
    },
    {
      key: "dateOfCreation",
      component:
        <TextField size="small" fullWidth variant="standard" value={generalInfo?.dateOfCreation}
          InputProps={{ disableUnderline: true }}
          inputProps={{ style: {fontFamily: "Metronic Pro", fontSize: "0.875rem", textAlign: "end"} }}
    />
    }
  ]

  function onSubmit() {
    if(generalInfo)
      companiesService.editCompanyInfo(generalInfo)
        .then(() => setDisplayConfirmation(true))
        .catch(e => {
          alert('Something happened editing the general information, try later');
          console.error(e);
        });
  }

  function onCancel(oldGeneralInfo: ICompanyDetails) {
    setGeneralInfo(oldGeneralInfo);
  }

  function handleConfirmation() {
    setDisplayConfirmation(false);
    setReload((reload: boolean) => !reload);
  }

  return (
    <>
      <CardDetails
        title="General Information"
        entity={generalInfo}
        setEntity={setGeneralInfo}
        input={input}
        onCancel={onCancel}
        onSubmit={onSubmit}
        ignore={["companyId"]}
      />
      <Popup title="Confirmation" description={`General Information edited sucessfully!`} approveText="OK" open={displayConfirmation} handleApprove={handleConfirmation} />
    </>
  )
}
import companiesService from "../../../services/Companies/CompaniesService";
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Popup from "../../Components/Popup/Popup";
import { useEffect, useState } from "react";
import IAllowedProduct from "../../../domain/Product/AllowedProduct";
import ICompanyProductAssociation from "../../../domain/Product/CompanyProductAssociation";
import { Box, Card, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, IconButton } from "@mui/material";
import productService from "../../../services/Products/ProductsService";
import PropertyTable from "../../Components/CardDetails/PropertyTable";

type Props = {
  companyId: number;
  allowedProducts: Array<IAllowedProduct>;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const titleStyle = { fontFamily: "Metronic Pro", fontSize: "1.2rem", fontWeight: "700" };

export default function AllowedProducts({ companyId, allowedProducts, setReload }: Props) {
  const [companyProductAssociation, setCompanyProductAssociation] = useState<ICompanyProductAssociation>({} as ICompanyProductAssociation);
  const [oldCompanyProductAssociation, setOldCompanyProductAssociation] = useState<ICompanyProductAssociation>({} as ICompanyProductAssociation);
  const [productList, setProductList] = useState<IAllowedProduct[]>([]);
  const [editAllowedProducts, setEditAllowedProducts] = useState<boolean>(false);
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false);

  useEffect(() => {
    setCompanyProductAssociation({companyId: companyId, productIds: allowedProducts.map((product) => product.id)});
    productService.getAllowedProducts().then((products) => setProductList(products));
  }, [allowedProducts]);

  function onSubmit() {
    if (JSON.stringify(companyProductAssociation) !== JSON.stringify(oldCompanyProductAssociation))
      companiesService.editCompanyProductAssociation(companyProductAssociation)
        .then(() => {
          setDisplayConfirmation(true);
          setReload((oldState) => !oldState);
        })
        .catch(e => {
          alert('Something happened editing the products, try later');
          console.error(e);
        });
    setEditAllowedProducts(false);
  }

  function onCancel() {
    setCompanyProductAssociation(oldCompanyProductAssociation);
    setEditAllowedProducts(false);
  }

  function onEdit() {
    setEditAllowedProducts(true);
    setOldCompanyProductAssociation(companyProductAssociation);
  }

  function handleConfirmation() {
    setDisplayConfirmation(false);
    setReload((reload: boolean) => !reload);
  }

  return (
    <>
      <Card sx={{ boxShadow: 'none' }}>
        <CardHeader title="Products" titleTypographyProps={titleStyle} action={
          editAllowedProducts
            ? <Box sx={{ position: "relative" }}>
                <IconButton sx={{ position: "absolute", right: "30px" }} aria-label="edit" onClick={onCancel}><CloseIcon /></IconButton>
                <IconButton aria-label="edit" onClick={onSubmit}><DoneIcon /></IconButton>
              </Box>
            : <IconButton aria-label="edit" onClick={onEdit}><EditIcon /></IconButton>
          }
        />
        <CardContent>
          {editAllowedProducts
            ? <FormGroup>
                {productList.map((product) =>
                  <FormControlLabel key={product.id} label={product.productName} control={
                    <Checkbox value={product.id}
                      checked={companyProductAssociation?.productIds.indexOf(product.id) > -1}
                      onChange={(e) => setCompanyProductAssociation({
                        companyId: companyId,
                        productIds: e.target.checked ? companyProductAssociation?.productIds.concat(Number(e.target.value)) : companyProductAssociation?.productIds.filter((id) => id !== Number(e.target.value))
                      } as ICompanyProductAssociation)}
                    />
                  }
                />)}
              </FormGroup>
            : allowedProducts?.map((product: IAllowedProduct, index: number) => (
              <Box key={index} sx={{ marginBottom: "25px" }}>
                <PropertyTable entity={product} ignore={["id", "prefixProductCode", "itemNumber", "prices", "designConfigurations"]} />
              </Box>
            ))
          }
        </CardContent>
      </Card>
      <Popup title="Confirmation" description={`Products edited sucessfully!`} approveText="OK" open={displayConfirmation} handleApprove={handleConfirmation} />
    </>
  )
}
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import companiesService from "../../../services/Companies/CompaniesService";
import IAddress from "../../../domain/Common/Address";
import { City, Country, ICity, IState, State } from "country-state-city";
import CardDetails from "../../Components/CardDetails/CardDetails";
import Popup from "../../Components/Popup/Popup";

type Props = {
  address: IAddress | undefined;
  setAddress: any;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}


export default function Address({ address, setAddress, setReload }: Props) {
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false);

  useEffect(() => {
    setAddressCountry(address);
  }, []);

  useEffect(() => {
    if (!isEditing)
      return;
    setAddress({...address, state: '', city: ''})
    setAddressCountry(address);
  }, [address?.country])

  useEffect(() => {
    if (!isEditing)
      return;
    setAddress({...address, city: ''})
    setAddressCountry(address);
  }, [address?.state])

  const countries = Country.getAllCountries();

  function setAddressCountry(_address?: any) {
    const a = _address ?? address;
    const country = countries.find(f => f.name.toLowerCase() === a.country?.toLowerCase());
    if(country){
      const states = State.getStatesOfCountry(country.isoCode);
      setStates(states);
      const state = states.find(s => s.name.toLowerCase() === a.state?.toLowerCase())
      if(state){
        setCities(City.getCitiesOfState(country.isoCode, state.isoCode));
      }else{
        setCities([]);
      }
    }else{
      setStates([]);
      setCities([]);
    }
  }

  function onSubmit() {
    if(address)
      companiesService.editCompanyAddress(address)
        .then(() => setDisplayConfirmation(true))
        .catch(e => {
          alert('Something happened editing the address, try later');
          console.error(e);
        });
  }

  function onCancel(oldAddress: IAddress) {
    setIsEditing(false);
    setAddress(oldAddress);
  }

  function handleConfirmation() {
    setDisplayConfirmation(false);
    setReload((reload: boolean) => !reload);
  }

  const input = [
    {
      key: "country",
      component:
        <Autocomplete size="small" fullWidth freeSolo options={countries} value={address?.country} getOptionLabel={(country) => typeof country === "string" ? country : country.name}
          onInputChange={(_, value) => {
            const country = countries.find(f => f.name === value);
            setAddress({...address, country: value, countryCode: country?.phonecode});
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" InputProps={{ ...params.InputProps, disableUnderline: true, style: {fontFamily: "Metronic Pro", fontSize: "0.875rem"} }}
              inputProps={{ ...params.inputProps, style: {textAlign: "end"} }}
            />
          )}
        />
    },
    {
      key: "state",
      component:
        <Autocomplete size="small" fullWidth freeSolo options={states} value={address?.state} getOptionLabel={(state) => typeof state === "string" ? state : state.name}
          onInputChange={(_, value) => {
            setAddress({...address, state: value });
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" InputProps={{ ...params.InputProps, disableUnderline: true, style: {fontFamily: "Metronic Pro", fontSize: "0.875rem"} }}
              inputProps={{ ...params.inputProps, style: {textAlign: "end"} }}
            />
          )}
        />
    },
    {
      key: "city",
      component:
        <Autocomplete size="small" fullWidth freeSolo options={cities} value={address?.city} getOptionLabel={(city) => typeof city === "string" ? city : city.name}
          onInputChange={(_, value) => {
            setAddress({...address, city: value });
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" InputProps={{ ...params.InputProps, disableUnderline: true, style: {fontFamily: "Metronic Pro", fontSize: "0.875rem"} }}
              inputProps={{ ...params.inputProps, style: {textAlign: "end"} }}
            />
          )}
        />
    },
    {
      key: "countryCode",
      component:
        <TextField size="small" variant="standard" fullWidth InputProps={{ readOnly: true, disableUnderline: true }} style={{ textAlign: 'left' }}
        inputProps={{ style: {fontFamily: "Metronic Pro", fontSize: "0.875rem", textAlign: "end"} }} value={ address?.countryCode ?? '' }
      />
    },
  ]

  return (
    <>
      <CardDetails
        title="Address"
        entity={address}
        setEntity={setAddress}
        input={input}
        onEdit={() => setIsEditing(true)}
        onCancel={onCancel}
        onSubmit={onSubmit}
        ignore={["companyId", "addressId"]}
      />
      <Popup title="Confirmation" description={`Address edited sucessfully!`} approveText="OK" open={displayConfirmation} handleApprove={handleConfirmation} />
    </>
  )
}
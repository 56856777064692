import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import { AxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormControl, FormHelperText, InputLabel, SelectChangeEvent } from "@mui/material";

import AddCompanyAddress from "./AddCompanyAddress";
import Popup from "../../Components/Popup/Popup";
import { ICompany } from "../../../domain";
import INewCompanyLabel from "../../../domain/Company/newCompany";
import IAllowedProduct from "../../../domain/Product/AllowedProduct";
import companiesService from "../../../services/Companies/CompaniesService";
import productService from "../../../services/Products/ProductsService";
import IAddress from "../../../domain/Common/Address";
import GridProperty from "../../Components/GridProperty/GridProperty";
import { getErrorMessage } from "../../Components/Common/helper";
import DetailContainer from "../../Components/Common/DetailContainer";

const companyTypeOptions = ["Sales", "Materialise", "Production", "CustomerSupport"];

const textFieldStyle = {
  boxShadow: "3",
  height: "60px",
  borderRadius: "5px",
  justifyContent: "center",
  textAlign: "left",
  paddingLeft: "20px",
  paddingRight: "10px",
};

interface Props {
  navigate: NavigateFunction;
}

export default function AddCompany(props: Props) {
  const [tabIndex, setTabIndex] = useState(0);
  const [newCompany, setNewCompany] = useState<ICompany>({
    name: '',
    companyType: '',
    customerNumber: '',
    allowedProducts: [],
    address: {} as IAddress
  });
  const [displayLabel, setDisplayLabel] = useState<INewCompanyLabel>({
    name: true,
    companyType: true,
    customerNumber: true,
    allowedProducts: true,
  });
  const [companyTypes, setCompanyTypes] = useState<string[]>([]);
  const [allowedProducts, setAllowedProducts] = useState<IAllowedProduct[]>([]);
  const [displayAction, setDisplayAction] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [validations, setValidations] = useState<any>({});

  useEffect(() => {
    setCompanyTypes(companyTypeOptions);
    productService.getAllowedProducts().then((products) => setAllowedProducts(products));
  }, []);

  useEffect(() => {
    setNewCompany(newCompany);
  }, [newCompany.companyType])

  function onSubmit() {
    setDisplayAction(false);

    companiesService.addCompany(newCompany)
      .then(() => setDisplayConfirmation(true))
      .catch((error: AxiosError<any>) => {
        setValidations(error.response?.data?.errors);
      });
  }

  function onSubmitClick(event: any) {
    event.preventDefault();
    setDisplayAction(true);
  }

  function handleChange(value: any, fieldName: string) {
    setNewCompany((oldState) => ({ ...oldState, [fieldName]: value }));
    if (fieldName === "allowedProducts" && value.length === 0)
      setDisplayLabel({ ...displayLabel, [fieldName]: true })
    else value
      ? setDisplayLabel({ ...displayLabel, [fieldName]: false })
      : setDisplayLabel({ ...displayLabel, [fieldName]: true });

    setValidations({ ...validations, [fieldName]: undefined})
  }

  function handleConfirmation() {
    setDisplayConfirmation(false);
    props.navigate("/Companies");
  }

  function handleCompaniesClick() {
    props.navigate("/Companies");
  }

  return (
    <DetailContainer>
      <Box sx={{ marginLeft: "30px", marginRight: "20px" }}>
        <Box sx={{ display: "flex" }}>
          <Box component="span" className="navigation-link" style={{ marginLeft: "50px", textDecoration: "none" }} onClick={handleCompaniesClick}>Companies</Box>
          <Box component="span" className="navigation-link" style={{ marginLeft: "50px" }} onClick={() => window.location.reload()}>Add New Company</Box>
        </Box>
        <Box sx={{ boxShadow: 1, minHeight: "500px", paddingBottom: "20px", marginLeft: "50px" }}>
          <Tabs value={tabIndex} onChange={(_, e) => setTabIndex(e)} sx={{ paddingLeft: "15px", marginBottom: "10px" }}>
            <Tab label="Information" />
          </Tabs>
          <Grid container spacing={1} sx={{ padding: "0 40px" }}>
            <GridProperty labelText='Company Name' onChange={(event: any) => handleChange(event, "name")} side={4}
              required={true} editable={true} value={newCompany.name} placeholder='Enter Company Name'
              error={!!validations?.name} helperText={getErrorMessage(validations?.name)} />
            <GridProperty labelText='Company Type' onChange={(event: any) => handleChange(event, "companyType")} side={4} selectValues={companyTypes}
              required={true} editable={true} value={newCompany.companyType} placeholder='Select Company Type' freeSolo={false}
              error={!!validations?.companyType} helperText={getErrorMessage(validations?.companyType)} />
            <Grid item xs={4}>
              <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* Allowed Products</Box>
              <Box sx={{ position: "relative" }}>
                <FormControl fullWidth error={!!validations?.allowedProducts}>
                  <InputLabel id="allowed-products-label" style={{ color: "black" }} shrink={false}
                    sx={ newCompany.allowedProducts?.length ? {display: 'none !important' } : undefined } >
                      Select Allowed Products
                  </InputLabel>
                  <Select required multiple fullWidth sx={textFieldStyle} value={newCompany.allowedProducts} IconComponent={KeyboardArrowDownIcon}
                    onChange={(event: SelectChangeEvent<number[]>) => handleChange(event?.target?.value, "allowedProducts")} labelId="allowed-products-label"
                    renderValue={(selectedProducts) => 
                      allowedProducts.filter((product) => selectedProducts.includes(product.id)).map((product) => product.productName).join(', ')}
                    >
                    {allowedProducts.map((product) => (
                      <MenuItem key={product.id} value={product.id}><Checkbox checked={newCompany.allowedProducts!.indexOf(product.id) > -1} />{ product.productName }</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{getErrorMessage(validations?.allowedProducts)}</FormHelperText>
                </FormControl>
              </Box>
            </Grid>
            <GridProperty labelText='Customer Number' onChange={(event: any) => handleChange(event, "customerNumber")} side={4}
              required={true} editable={true} value={newCompany.customerNumber} placeholder='Enter Customer Number'
              error={!!validations?.customerNumber} helperText={getErrorMessage(validations?.customerNumber)} />
            <Grid item xs={12}>
              <Divider sx={{ marginRight: "25px" }} flexItem />
            </Grid>
            <Grid item xs={12}>
              <AddCompanyAddress entity={newCompany.address} updateState={setNewCompany} updateValidations={setValidations} validations={validations} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
          <Button sx={{ borderRadius: "5px", fontFamily: "Metronic Pro", width: "200px", margin: "10px 0 10px 0", textTransform: "none" }} size="small" variant="contained" onClick={onSubmitClick}>Submit</Button>
        </Box>
        <Popup title="Confirmation" description="Are you sure you want to perform this action?" approveText="YES" denyText="NO" open={displayAction} handleApprove={onSubmit} handleDeny={() => setDisplayAction(false)} />
        <Popup title="Confirmation" description="Company added sucessfully!" approveText="OK" open={displayConfirmation} handleApprove={handleConfirmation} />
      </Box>
    </DetailContainer>
  );
}

import { useEffect, useState } from 'react';
import { NavigateFunction, useParams } from 'react-router-dom';
import OfficesService from '../../../services/Offices/OfficesService';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import GridProperty from '../../Components/GridProperty/GridProperty';
import { Country, State, City } from 'country-state-city';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import companiesService from '../../../services/Companies/CompaniesService';
import ICustomer from '../../../domain/Common/Customer';
import { ICompanyDetails } from '../../../domain/Company/CompanyDetalis';
import Popup from '../../Components/Popup/Popup';
import DetailContainer from '../../Components/Common/DetailContainer';
import { getErrorMessage } from "../../Components/Common/helper";
import { AxiosError } from 'axios';

const textFieldStyle = {
  boxShadow: "3",
  height: "60px",
  borderRadius: "5px",
  justifyContent: "center",
  textAlign: "left",
  paddingLeft: "20px",
  paddingRight: "10px",
};

const shipToOptions = ["Ship to Company", "Ship To Office", "Other Customer"];
const invoiceToOptions = ["Invoice Company", "Invoice Office", "Other Customer"];

type Props = {
  navigate: NavigateFunction;
}

export default function OfficeDetail({ navigate }: Props){
  const { companyId, officeId } = useParams();

  const [office, setOffice] = useState<any>({companyId: companyId});
  const [oldOffice, setOldOffice] = useState<any>(office);
  const [editable, setEditable] = useState(true);
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [invoiceOption, setInvoiceOption] = useState<any>();
  const [shipOption, setShipOption] = useState<any>();
  const [customers, setCustomers] = useState<{label: string, value: string}[]>([]);
  const [companyNumber, setCompanyNumber] = useState<string>('');
  const [displayAction, setDisplayAction] = useState<boolean>(false);
  const [displayConfirmation, setDisplayConfirmation] = useState<boolean>(false);
  const [validations, setValidations] = useState<any>({});
  

  useEffect(() => {
    companiesService.getCompany(Number(companyId))
      .then((company: ICompanyDetails) => setCompanyNumber(company.customerNumber));
    companiesService.getAllCustomers().then((_customers: ICustomer[]) => 
      setCustomers(_customers?.map((customer: ICustomer) => (
        {label: `${customer.customerNumber} - ${customer.customerName} - ${customer.customerType}`, value: customer.customerNumber}
      )))
    );
    if(officeId){
      OfficesService.getOffice(officeId)
        .then(o => {
          setOffice(o);
          setAddressCountry(o);
      });
      setEditable(false);
    }
  }, []);

  useEffect(() => {
    if (editable) {
      setOffice({...office, address: {...office.address, state: '', city: ''}})
      setAddressCountry(office);
    }
  }, [office.address?.country])

  useEffect(() => {
    if (editable) {
      setOffice({...office, address: {...office.address, city: ''}})
      setAddressCountry(office);
    }
  }, [office.address?.state])

  useEffect(() => {
    if (invoiceOption === invoiceToOptions[1])
      setOffice({...office, invoiceTo: office.customerNumber});
    if (shipOption === shipToOptions[1])
      setOffice((oldState: any) => ({...oldState, shipTo: office.customerNumber}))
  }, [office.customerNumber])

  useEffect(() => {
    setOffice(office);
  }, [office.officeType])

  useEffect(() => {
    setInvoiceOption(invoiceOption)
  }, [invoiceOption])

  useEffect(() => {
    setShipOption(shipOption)
  }, [shipOption])

  const officesType = [ 'SalesStore', 'Production', 'CustomerSupport', 'MTLS' ];
  const countries = Country.getAllCountries();

  function setAddressCountry(_office?: any) {
    const o = _office ?? office;
    const country = countries.find(f => f.name.toLowerCase() === o.address?.country?.toLowerCase());
    if (country) {
      const states = State.getStatesOfCountry(country.isoCode);
      setStates(states);
      const state = states.find(s => s.name.toLowerCase() === o.address?.state?.toLowerCase())
      if (state) {
        setCities(City.getCitiesOfState(country.isoCode, state.isoCode));
      } else {
        setCities([]);
      }
    } else {
      setStates([]);
      setCities([]);
    }
  }

  function handleInvoice(value: string) {
    setInvoiceOption(value);
    setValidations({ ...validations, invoiceTo: undefined});
    if (value === invoiceToOptions[0]) {
      setOffice({...office, invoiceTo: companyNumber});
      return;
    }
    if (value === invoiceToOptions[1]) {
      setOffice({...office, invoiceTo: office.customerNumber});
      return;
    }
    setOffice({...office, invoiceTo: null});
    return;
  }

  function handleShip(value: string) {
    setShipOption(value);
    setValidations({ ...validations, shipTo: undefined});
    if (value === shipToOptions[0]) {
      setOffice({...office, shipTo: companyNumber});
      return;
    }
    if (value === shipToOptions[1]) {
      setOffice({...office, shipTo: office.customerNumber});
      return;
    }
    setOffice({...office, shipTo: null});
    return;
  }

  function onSubmitClick(event: any) {
    event.preventDefault();
    setDisplayAction(true);
  }

  function handleChange(value: any, fieldName: string) {
    setOffice((oldState: any) => ({ ...oldState, [fieldName]: value }));
    setValidations({ ...validations, [fieldName[0].toLowerCase() + fieldName.slice(1)]: undefined});
  }

  function handleAddressChange(value: any, fieldName: string) {
    setOffice({...office, address: {...office.address, [fieldName]: value} });
    setValidations({ ...validations, [fieldName[0].toLowerCase() + fieldName.slice(1)]: undefined });
  }

  function onSubmit() {
    setDisplayAction(false);
    if (officeId)
      OfficesService.editOffice(office)
      .then(_ => {setDisplayConfirmation(true); setEditable(false);})
      .catch((error: AxiosError<any>) => {
        const errorMessages = error.response?.data?.errors;
          const keyValues = Object.keys(errorMessages).map(key => {
            const newKey = key[0].toLowerCase() + key.slice(1);
            return { [newKey]: errorMessages[key] };
          });
          setValidations(Object.assign({}, ...keyValues));
      });
    else
      OfficesService.addOffice(office)
        .then(_ => {setDisplayConfirmation(true); setEditable(false);})
        .catch((error: AxiosError<any>) => {
          const errorMessages = error.response?.data?.errors;
          const keyValues = Object.keys(errorMessages).map(key => {
            const newKey = key[0].toLowerCase() + key.slice(1);
            return { [newKey]: errorMessages[key] };
          });
          setValidations(Object.assign({}, ...keyValues));
        });
  }

  function handleConfirmation() {
    setDisplayConfirmation(false);
    navigate(-1);
  }

  function validationInformation() {
    return !!validations.name || !!validations.officeType || !!validations.customerNumber || !!validations.invoiceTo || !!validations.shipTo;
  }

  function validationAddress() {
    return !!validations.street || !!validations.postalCode || !!validations.city || !!validations.state || !!validations.country;
  }

  return (
    <DetailContainer>
      <Box style={{ width: '100vw-50px', padding: '0px 30px 30px 30px' }} >
        <Box sx={{ display: 'flex', marginLeft: '1rem', marginBottom: '1rem', alignItems: 'center' }}>
          <b style={{ fontSize: '1.5rem' }}>Office</b>
        </Box>
        <form onSubmit={onSubmitClick}>
          <Box sx={{ boxShadow: 1, minHeight: "400px", paddingBottom: "20px" }}>
            <div style={{ display: 'flex'}}>
              <Tabs value={tabIndex} onChange={(_, e) => setTabIndex(e)}>
                <Tab label='Information' sx={{ color: validationInformation() ? 'red !important' : null }} />
                <Tab label='Address' sx={{ color: validationAddress() ? 'red !important' : null }} />
              </Tabs>
              {officeId
                ? <div style={{display: 'flex', flex: 1, justifyContent: 'end' }}>
                  {!editable
                    ? <IconButton style={{ marginRight: '10px', alignSelf: 'self-end' }} aria-label='edit'
                        onClick={() => {setEditable(true); setOldOffice(office);}}
                      >
                        <EditIcon />
                      </IconButton>
                    : <IconButton style={{ marginRight: '10px', alignSelf: 'self-end' }} aria-label='cancel'
                        onClick={() => {setEditable(false); setOffice(oldOffice); setInvoiceOption(''); setShipOption('');}}
                      >
                        <CloseIcon />
                      </IconButton>
                  }
                </div>
                : null
              }
            </div>

            {/* Information */}
            <Grid container spacing={1} role="tabpanel" sx={{ padding: '0 40px', marginTop: '0px', display: tabIndex !== 0 ? "none" : "auto" }}>
              <GridProperty labelText='Name' onChange={(value: string) => handleChange(value, "name")} side={4}
                required={true} editable={editable} value={office.name} placeholder='Enter Office Name'
                error={!!validations.name} helperText={getErrorMessage(validations.name)} />
              <GridProperty labelText='Office Type' freeSolo={false} onChange={(value: string) => handleChange(value, "officeType")} side={4} selectValues={officesType}
                required={true} editable={editable} value={office.officeType} placeholder='Enter Office Type'
                error={!!validations.officeType} helperText={getErrorMessage(validations.officeType)} />
              <GridProperty labelText='Customer Number' onChange={(value: string) => handleChange(value, "customerNumber")} side={4}
                required={true} editable={editable} value={office.customerNumber} placeholder='Enter Customer Number'
                error={!!validations.customerNumber} helperText={getErrorMessage(validations.customerNumber)} />
              <GridProperty labelText='Invoice Options' freeSolo={false} selectValues={invoiceToOptions} onChange={handleInvoice} side={4}
                editable={editable} value={invoiceOption ?? null} placeholder='Enter Invoice To' />
              <Grid item xs={4} style={{ padding: '10px' }} >
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* Invoice To</Box>
                  {invoiceOption === invoiceToOptions[2] && editable ?
                    <Autocomplete options={customers} value={office.invoiceTo ?? null}
                    getOptionLabel={(customer) => typeof customer === "string" ? customers.find((c) => c.value === customer)?.label : customer?.label}
                      onChange={(_, value) => handleChange(value?.value, "invoiceTo")}
                      isOptionEqualToValue={(option, value) => option?.value === value}
                      renderInput={(params) => ( 
                        <TextField {...params} variant="standard" sx={textFieldStyle} error={!!validations.invoiceTo} helperText={getErrorMessage(validations.invoiceTo)}
                          InputProps={{ ...params.InputProps, disableUnderline: true }} placeholder="Enter Invoice Customer" /> )} />
                    : <TextField fullWidth variant="standard" sx={textFieldStyle} value={office.invoiceTo ?? ''}
                    InputProps={{ disableUnderline: true, readOnly: true }} placeholder="Enter Invoice Customer"
                    error={!!validations.invoiceTo} helperText={getErrorMessage(validations.invoiceTo)} />
                  }
              </Grid>
              <Grid item xs={4} ></Grid>
              <GridProperty labelText='Ship Options' freeSolo={false} selectValues={shipToOptions} onChange={handleShip} side={4}
                editable={editable} value={shipOption ?? null} placeholder='Enter Ship To' />
              <Grid item xs={4} style={{ padding: '10px' }} >
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* Ship To</Box>
                {shipOption === shipToOptions[2] && editable
                  ? <Autocomplete options={customers} value={office.shipTo ?? null}
                    getOptionLabel={(customer) => typeof customer === "string" ? customers.find((c) => c.value === customer)?.label : customer?.label}
                    onChange={(_, value) => handleChange(value?.value, "shipTo")}
                    isOptionEqualToValue={(option, value) => option?.value === value}
                    renderInput={(params) => ( 
                      <TextField {...params} variant="standard" sx={textFieldStyle} error={!!validations.shipTo} helperText={getErrorMessage(validations.shipTo)}
                        InputProps={{ ...params.InputProps, disableUnderline: true }} placeholder="Enter Ship Customer" /> )} />
                  : <TextField fullWidth variant="standard" sx={textFieldStyle} value={office.shipTo ?? ''}
                      InputProps={{ disableUnderline: true, readOnly: true }} placeholder="Enter Ship Customer"
                      error={!!validations.shipTo} helperText={getErrorMessage(validations.shipTo)} />
                }
              </Grid>
            </Grid>

            {/* Address */}
            <Grid container spacing={1} role="tabpanel" sx={{ padding: '0 40px', marginTop: '0px', display: tabIndex !== 1 ? "none" : "auto" }}>
              <GridProperty labelText='Street Name' onChange={(value: string) => handleAddressChange(value, "streetName")} side={4}
                required={true} editable={editable} value={office.address?.streetName} placeholder='Enter Office Street Name'
                error={!!validations.street} helperText={getErrorMessage(validations.street)} />
              <GridProperty labelText='Appartment Number' onChange={(value: string) => handleAddressChange(value, "appartmentNumber")} side={4}
                required={false} editable={editable} value={office.address?.appartmentNumber} placeholder='Enter Office Appartment Number' />
              <GridProperty labelText='Postal Code' onChange={(value: string) => handleAddressChange(value, "postalCode")} side={4}
                required={true} editable={editable} value={office.address?.postalCode} placeholder='Enter Office Postal Code'
                error={!!validations.postalCode} helperText={getErrorMessage(validations.postalCode)} />
              <GridProperty labelText='Country' valueKey='name' freeSolo={false} required={true} editable={editable} side={4}
                value={office.address?.country} placeholder='Enter Office Country' selectValues={countries}
                error={!!validations.country} helperText={getErrorMessage(validations.country)}
                onChange={(value: string) => {
                  const country = countries.find(f => f.name === value);
                  setOffice({...office, address: {...office.address, country: value, countryCode: country?.phonecode }});
                  setValidations({ ...validations, country: undefined});
                }}/>
              <GridProperty labelText='State' valueKey='name' editable={editable} value={office.address?.state} placeholder='Enter Office State' selectValues={states} side={4}
                error={!!validations.state} helperText={getErrorMessage(validations.state)}
                onChange={(value: string) => handleAddressChange(value, "state")}/>
              <GridProperty labelText='City' valueKey='name' required={true} editable={editable} value={office.address?.city} placeholder='Enter Office City' selectValues={cities} side={4}
                error={!!validations.city} helperText={getErrorMessage(validations.city)}
                onChange={(value: string) => handleAddressChange(value, "city")} />
              <GridProperty labelText='Country Code' value={office.address?.countryCode} side={4} />
            </Grid>
          </Box>
          <Box sx={{ display: editable ? 'flex' : 'none', justifyContent: 'end' }}>
            <Button sx={{ borderRadius: '5px', fontFamily: 'Metronic Pro', width: '200px', margin: '10px 0 10px 0', textTransform: 'none' }} size='small' type='submit' variant='contained'>Submit</Button>
          </Box>
        </form>
        <Popup title="Confirmation" description={`Are you sure you want to ${officeId ? 'edit' : 'add'} this office?`} approveText="YES" denyText="NO" open={displayAction} handleApprove={onSubmit} handleDeny={() => setDisplayAction(false)} />
        <Popup title="Confirmation" description={`Office ${officeId ? 'edited' : 'added'} sucessfully!`} approveText="OK" open={displayConfirmation} handleApprove={handleConfirmation} />
      </Box>
    </DetailContainer>
  );
}


import Box from "@mui/material/Box";

type Props = {
  displayLabel: boolean;
  labelText: string;
  disabled?: boolean;
}

export default function CustomLabel({ displayLabel, labelText, disabled }: Props) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "20px",
          transform: "translate(0, -50%)",
          display: displayLabel ? "block" : "none",
          color: disabled ? "gray" : "black"
        }}
      >
        {labelText}
      </Box>
    );
  }
import { Box } from "@mui/material";

type Props = {
  children : JSX.Element
}

export default function DetailContainer({children}: Props) {
  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Box style={{ maxWidth: '70vw' }}>
        {children}
      </Box>
    </Box>
  );
}


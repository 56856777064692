import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Health from './ui/Health';
import Home from './ui/Pages/Home/Home';
import Login from './ui/Pages/Login/Login';
import Application from './ui/Pages/Application/Application';
import ResetPassword from './ui/Pages/ResetPassword/ResetPassword';
import SetupPassword from './ui/Pages/SetupPassword/SetupPassword';
import storage from './services/Share/storageWithEncryption';



function getToken() {
  const token = storage.getDataFromLocalStorage('token');  //localStorage.getItem('token');
  const refreshToken =storage.getDataFromLocalStorage('refreshToken');// localStorage.getItem('refreshToken');
  return {token, refreshToken};
}


function App() {
  const {token, refreshToken} = getToken();
  const [isLogged, setIsLogged] = useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {
    let currentUrl = window.location.href;
    let needToSetCredentions  = !currentUrl.includes("ResetPassword") && !currentUrl.includes("SetNewPasswordWithCode") &&  !currentUrl.includes("SetupPassword");
    if (!token || !refreshToken ) {
      
      setIsLogged(false);
      if(needToSetCredentions)
      navigate("/Login");
      return;    
  }
  if(needToSetCredentions)
    setIsLogged(true);
  }, [token, refreshToken, isLogged, navigate]);
  if(!isLogged) {
    return (
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/health" Component={Health} />
        <Route path="/Login" element={<Login setIsLogged={setIsLogged}  />} />
        <Route path="/ResetPassword" element={<ResetPassword />}   caseSensitive={false}/>
        <Route path="/SetupPassword" element={<SetupPassword />}   caseSensitive={false}/>
        <Route path="/SetNewPasswordWithCode" element={<SetupPassword />}   caseSensitive={false}/>
      </Routes>
    );
  }
  return <Application />
}

export default App;

import Box from "@mui/material/Box";
import { NavigateFunction } from "react-router-dom";
import Grid from "../../Components/Grid/Grid";
import companyService from "../../../services/Companies/CompaniesService";
import defaultColumnPreferences from "./ColumnDefaultPreferences";
import storage from "../../../services/Share/storageWithEncryption";
import { IFilter, IListRequestBody } from "../../../domain/Common/RequestBody";
import { UserRoles } from "../../../domain/Users/userRoles";
import { useState } from "react";
import { GridRowSelectionModel } from "@mui/x-data-grid";

interface Props {
  navigate: NavigateFunction;
}

export default function Companies(props: Props) {
  const userRole = storage.getDataFromLocalStorage("userRole");
  const companyColumns = require(`./CompaniesColumns`).default;
  const gridPageSize = 50;
  const paginationSize = 100;

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: gridPageSize });
  const [query, setQuery] = useState<IListRequestBody>({
    page: 0,
    pageSize: 100,
    sortingDirection: 0,
    sortBy: "",
    filters: [] as IFilter[],
  });

  function handleCompaniesClick() {
    props.navigate("/Companies");
  }

  function handleAddCompanyClick() {
    props.navigate("/AddCompany");
  }

  function handleCompanyDetails(params: any) {
    props.navigate(`/CompanyDetails/${params.id}`);
  }


  const onRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel((r) => {
      if (newRowSelectionModel.length - r.length > gridPageSize) {
        const firstRow = paginationModel.page * gridPageSize;
        return newRowSelectionModel.slice(firstRow, firstRow + gridPageSize);
      }
      return newRowSelectionModel;
    });
  };

  return (
    <Box sx={{ marginLeft: "30px", marginRight: "20px" }}>
      <Box sx={{ display: "flex" }}>
        <Box component="span" className="navigation-link" style={{ marginLeft: "50px" }} onClick={handleCompaniesClick}>Companies</Box>
        <Box component="span" className="navigation-link" style={{ marginLeft: "50px", textDecoration: "none" }} onClick={handleAddCompanyClick}>Add New Company</Box>
      </Box>
      <Box style={{ height: "75vh", width: "100vw-50px", padding: "0px 10px 30px 0px", marginLeft: 50 }}>
        <Grid
          defaultColumnPreferences={defaultColumnPreferences}
          columnPreferencesName="companiesColumnPreferences"
          columns={companyColumns}
          gridPageSize={gridPageSize}
          paginationSize={paginationSize}
          isServerSideFilterData={true}
          isServerSideOrderData={true}
          getData={companyService.getCompanyList}
          getFilters={companyService.getCompanyFilters}
          onRowDoubleClick={userRole === UserRoles.MotionAdmin ? handleCompanyDetails : undefined}
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
          paginationModel={[paginationModel, setPaginationModel]}
          queryOptions={[query, setQuery]}
          getRowId="id"
        />
      </Box>
    </Box>
  );
}

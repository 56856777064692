import { GridColDef } from "@mui/x-data-grid";
import OrderStatus from "../../Components/OrderStatus/OrderStatus";
import { Box } from "@mui/material";

const OrdersColumnsAdmin: GridColDef[] = [
    { field: 'orderCode', headerName: 'Order Code', type: 'string', flex: .9, align: 'center', description: 'textFilter', disableColumnMenu: true},
    { field: 'productionCurrentActor', headerName: 'Production Actor', type: 'string', flex: 1, description: 'textFilter', disableColumnMenu: true},
    { field: 'practitioner', headerName: 'Practitioner', type: 'string', flex: 1, description: 'textFilter', disableColumnMenu: true},
    { field: 'orderDate', headerName: 'Order Date', flex: 1.1, description: 'dateFilter', disableColumnMenu: true, align: 'center',
        valueFormatter: rowdata => rowdata.value ? new Date(rowdata.value).toLocaleString('es', {day: '2-digit', month: '2-digit', year: 'numeric', hour:'2-digit', minute:'2-digit'}) : null},
    { field: 'targetShipmentDate', headerName: 'Target Shipping', flex: 1.1, description: 'dateFilter', disableColumnMenu: true, align: 'center', renderCell: (params) => {
        let value = new Date(params.value).toLocaleString('es', {day: '2-digit', month: '2-digit', year: 'numeric'});
        return !!params.row.delayedDays ? <Box sx={{ fontWeight: 700, fontStyle: 'italic' }}>{value}</Box> : value }},
    { field: 'delayedDays', headerName: 'Delayed Days', type: 'number', flex: 1, description: 'listFilter', disableColumnMenu: true, align: 'center'},
    { field: 'delayReason', headerName: 'Delayed Reason', type: 'string', flex: 1, disableColumnMenu: true},
    { field: 'status', headerName: 'Status', type: 'string', flex: 1.5, description: 'listFilter', disableColumnMenu: true, renderCell: (value) => <OrderStatus status={value.value} />},
    { field: 'store', headerName: 'Store', type: 'string', flex: .85, description: 'textFilter', disableColumnMenu: true},
    { field: 'shippedDate', headerName: 'Shipped Date', flex: 1.1, description: 'dateFilter', disableColumnMenu: true, align: 'center',
        valueFormatter: rowdata => rowdata.value ? new Date(rowdata.value).toLocaleString('es', {day: '2-digit', month: '2-digit', year: 'numeric', hour:'2-digit', minute:'2-digit'}) : null},
    { field: 'topLayerSize', headerName: 'Top Layer Size', type: 'string', flex: 1, description: 'listFilter', disableColumnMenu: true, align: 'center'},
    { field: 'customerName', headerName: 'End-Customer Name', type: 'string', flex: 1, description: 'listFilter', disableColumnMenu: true },
    { field: 'customerEmail', headerName: 'End-Customer Email', type: 'string', flex: 1, description: 'listFilter', disableColumnMenu: true },
    { field: 'customerPhoneNumber', headerName: 'End-Customer Phone Number', type: 'string', disableColumnMenu: true, flex: 1},
    { field: 'shippingAddress', headerName: 'Shipping Address', type: 'string', disableColumnMenu: true, flex: 1, filterable: false, sortable: false},
    { field: 'country', headerName: 'Country', type: 'string', disableColumnMenu: true, flex: 1},
    { field: 'designChangeActor', headerName: 'Design change Actor', type: 'string', disableColumnMenu: true, flex: 1, description: 'textFilter'},
    { field: 'currentActor', headerName: 'Current Actor', type: 'string', flex: .9, description: 'textFilter', disableColumnMenu: true},
]
export default OrdersColumnsAdmin;
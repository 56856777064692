export class OrderStatus {
  public static New = 'New';
  public static NewWaitingForRsdbFile = 'Receiving Design Parameters';
  public static OrderCreated = 'Created';
  public static AutoDesign = 'Auto Design Started';
  public static AutoDesignRunning = 'In Auto Design';
  public static AutoDesignCanceled = 'Auto Design Canceled';
  public static AutoDesignCreated = 'Auto Design Requested';
  public static AutoDesignFailed = 'Auto Design Failed';
  public static AutoDesignFailedNotified = 'Auto Design Failed';
  public static AutoDesignFinished = 'Auto Design Finished';
  public static WaitingForManualDesign = 'Waiting Manual Design';
  public static WaitingForManualDesignNotified = 'Waiting Manual Design';
  public static ManualDesign = 'In Manual Design';
  public static ManualDesignFinished = 'Manual Design Finished';
  public static WaitingForAutoDesignQC = 'Waiting Auto Design QC';
  public static WaitingForAutoDesignQCNotified = 'Waiting Auto Design QC';
  public static AutoDesignQC = 'In Auto Design QC';
  public static AutoDesignQCDesignFilesReady = 'Auto Design QC Design Files Ready';
  public static AutoDesignQCFilesDownloaded = 'A. Design QC Files Downloaded';
  public static AutoDesignQCFinished = 'A. Design QC Finished';
  public static OrderProductionFilesReceived = 'Files Received';
  public static OrderProductionFilesReady = 'Files Ready';
  public static OrderProductionFilesReadyNotified = 'Files Ready';
  public static Production = 'Production';
  public static InProduction = 'In Production';
  public static Canceled = 'Canceled';
  public static CanceledNotified = 'Canceled Notified';
}
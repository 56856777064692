import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import CardDetails from "../CardDetails/CardDetails";

type Props = {
  parameters: any;
}

const titleStyle = { fontFamily: "Metronic Pro", fontSize: "1rem", fontWeight: "700", textAlign: "start" };
const cellStyle = { fontFamily: "Metronic Pro", padding: "10px 16px", borderBottom: 'none' };

export default function DesignParameters({ parameters }: Props) {

  const displayValue = (value: string, units?: string): string => {
    const displayValue = units ? `${value}${units}` : value;
    return value ? displayValue : "-";
  }

  return (
    <div style={{ width: "40vw" }}>
        <CardDetails
          title="General"
          titleStyle={titleStyle}
          entity={{
            "Shoe size": displayValue(parameters.ShoeSize, ` ${parameters.SizeBase}`),
            "Shoe usage": displayValue(parameters.ShoeUsage),
            "Shoe type": displayValue(parameters.InsoleType)
          }}
        />
        <CardDetails
          title="Top cover"
          titleStyle={titleStyle}
          entity={{
            "Top layer size": displayValue(parameters.TopLayerSize, ` ${parameters.SizeBase}`),
            "Top layer hardness": displayValue(parameters.TopLayerHardness),
            "Top layer assembly": displayValue(parameters.TopLayerAssembly),
            "Top layer material": displayValue(parameters.TopLayerMaterial),
            "Top layer thickness": displayValue(parameters.TopLayerThickness),
            "Top layer contour": ""
          }}
        />
      <Card sx={{ padding: '0 10px 0 10px', boxShadow: 'none' }}>
        <CardHeader title="General information" titleTypographyProps={titleStyle as any} />
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow style={{ backgroundColor: "#bcd9f73f" }}>
                  <TableCell sx={{ fontWeight: "700", ...cellStyle }}>Insole order side</TableCell>
                  <TableCell sx={cellStyle} align="right" colSpan={2}>{displayValue(parameters.InsoleOrderSide)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "700", ...cellStyle }}>Base size</TableCell>
                  <TableCell sx={cellStyle} align="right">{displayValue(parameters.BaseSizeLeft, ` ${parameters.SizeBase}`)}</TableCell>
                  <TableCell sx={cellStyle} align="right">{displayValue(parameters.BaseSizeRight, ` ${parameters.SizeBase}`)}</TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "#bcd9f73f" }}>
                  <TableCell sx={{ fontWeight: "700", ...cellStyle }}>Personal ID</TableCell>
                  <TableCell sx={cellStyle} align="right">{displayValue(parameters.PersonalIdLeft)}</TableCell>
                  <TableCell sx={cellStyle} align="right">{displayValue(parameters.PersonalIdRight)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
        <CardDetails
          title="Local stiffness"
          titleStyle={titleStyle}
          pairs={true}
          entity={{
            "A": [displayValue(parameters.LocalThicknessMidfootLeft), displayValue(parameters.LocalThicknessMidfootRight)],
            "B": [displayValue(parameters.LocalThicknessAnteroMedialLeft), displayValue(parameters.LocalThicknessAnteroMedialRight)],
            "C": [displayValue(parameters.LocalThicknessPosteroMedialLeft), displayValue(parameters.LocalThicknessPosteroMedialRight)],
            "D": [displayValue(parameters.LocalThicknessLateralLeft), displayValue(parameters.LocalThicknessLateralRight)],
            "E": [displayValue(parameters.LocalThicknessHeelLeft), displayValue(parameters.LocalThicknessHeelRight)],
          }}
        />
        <CardDetails
          title="Width"
          titleStyle={titleStyle}
          pairs={true}
          entity={{
            "Zone width metatarsal": [displayValue(parameters.ZoneWidthMetatarsalLeft, " mm"), displayValue(parameters.ZoneWidthMetatarsalRight, " mm")],
            "Zone width midfoot": [displayValue(parameters.ZoneWidthMidfootLeft, " mm"), displayValue(parameters.ZoneWidthMidfootRight, " mm")],
            "Zone width heel": [displayValue(parameters.ZoneWidthHeelLeft, " mm"), displayValue(parameters.ZoneWidthHeelRight, " mm")],
          }}
        />
        <CardDetails
          title="Heel corrections"
          titleStyle={titleStyle}
          pairs={true}
          entity={{
            "Top layer heel padding": [displayValue(parameters.HeelPaddingLeft), displayValue(parameters.HeelPaddingRight)],
            "Wedge angle": [displayValue(parameters.HeelWedgeAngleLeft, "º"), displayValue(parameters.HeelWedgeAngleRight, "º")],
            "Raise height": [displayValue(parameters.HeelRaiseHeightLeft, " mm"), displayValue(parameters.HeelRaiseHeightRight, " mm")],
            "Cup height": [displayValue(parameters.HeelCupHeightLeft, " mm"), displayValue(parameters.HeelCupHeightRight, " mm")],
            "Skive side": [displayValue(parameters.HeelSkiveSideLeft), displayValue(parameters.HeelSkiveSideRight)],
            "Skive height": [displayValue(parameters.HeelSkiveHeightLeft, " mm"), displayValue(parameters.HeelSkiveHeightRight, " mm")]
          }}
        />
        <CardDetails
          title="Midfoot corrections"
          titleStyle={titleStyle}
          pairs={true}
          entity={{
            "Navicular support height": [displayValue(parameters.NavicularSupportHeightLeft, " mm"), displayValue(parameters.NavicularSupportHeightRight, " mm")],
            "Navicular support anteroposterior shift": [displayValue(parameters.NavicularSupportAnteroPosteriorShiftLeft, " mm"), displayValue(parameters.NavicularSupportAnteroPosteriorShiftRight, " mm")],
            "Lateral edge type": [displayValue(parameters.LateralEdgeTypeLeft), displayValue(parameters.LateralEdgeTypeRight)],
            "Lateral edge height": [displayValue(parameters.LateralEdgeHeightLeft, " mm"), displayValue(parameters.LateralEdgeHeightRight, " mm")],
            "Medial edge height": [displayValue(parameters.MedialEdgeHeightLeft, " mm"), displayValue(parameters.MedialEdgeHeightRight, " mm")],
          }}
        />
        <CardDetails
          title="Metatarsal corrections"
          titleStyle={titleStyle}
          pairs={true}
          entity={{
            "Bar height": [displayValue(parameters.MetatarsalBarHeightLeft, " mm"), displayValue(parameters.MetatarsalBarHeightRight, " mm")],
            "Bar anteroposterior stretch": [displayValue(parameters.MetatarsalBarAnteroPosteriorStretchLeft, " mm"), displayValue(parameters.MetatarsalBarAnteroPosteriorStretchRight, " mm")],
            "Pad shape": [displayValue(parameters.MetatarsalPadShapeLeft), displayValue(parameters.MetatarsalPadShapeRight)],
            "Pad height": [displayValue(parameters.MetatarsalPadHeightLeft, " mm"), displayValue(parameters.MetatarsalPadHeightRight, " mm")],
            "Pad anteroposterior shift": [displayValue(parameters.MetatarsalPadAnteroPosteriorShiftLeft, " mm"), displayValue(parameters.MetatarsalPadAnteroPosteriorShiftRight, " mm")],
            "Pad mediolateral shift": [displayValue(parameters.MetatarsalPadMedioLateralShiftLeft, " mm"), displayValue(parameters.MetatarsalPadMedioLateralShiftRight, " mm")],
            "Cutout": [displayValue(parameters.MetatarsalCutoutLeft), displayValue(parameters.MetatarsalCutoutRight)],
          }}
        />
        <CardDetails
          title="Forefoot corrections"
          titleStyle={titleStyle}
          pairs={true}
          entity={{
            "Type": [displayValue(parameters.ForefootCorrectionTypeLeft), displayValue(parameters.ForefootCorrectionTypeRight)],
            "Height": [displayValue(parameters.ForefootCorrectionHeightLeft, " mm"), displayValue(parameters.ForefootCorrectionHeightRight, " mm")],
            "Stiffness": [displayValue(parameters.ForefootCorrectionStiffnessLeft), displayValue(parameters.ForefootCorrectionStiffnessRight)],
          }}
        />
    </div>
  );
}
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ErrorIcon from "@mui/icons-material/Error";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import LensRoundedIcon from "@mui/icons-material/LensRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import storage from "../../../services/Share/storageWithEncryption";
import { UserRoles } from "../../../domain/Users/userRoles";

type Props = {
  status: string;
};

const iconStyle = {
  fontSize: 20,
  marginRight: "5px",
};

const svgStyle = {
  marginLeft: "2px",
  marginRight: "6px",
  marginTop: "1px",
};

function CircleSemiFilled({ color, id }: { color: string; id: string }) {
  return (
    <svg x="130" y="120" width="16" height="16" viewBox="0 0 100 100">
      <defs>
        <linearGradient id={id} x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="50%" style={{ stopColor: color, stopOpacity: "1" }} />
          <stop
            offset="50%"
            style={{ stopColor: "#FFFFFF", stopOpacity: "1" }}
          />
        </linearGradient>
      </defs>
      <circle
        cx="50"
        cy="50"
        r="45"
        fill={`url(#${id})`}
        stroke={color}
        strokeWidth="10"
      />
    </svg>
  );
}

export default function OrderStatus(props: Props) {
  const userRole = storage.getDataFromLocalStorage("userRole");

  if (
    userRole === UserRoles.MotionAdmin ||
    userRole === UserRoles.MTLSEngineers
  )
    switch (props.status) {
      case "New":
        return (
          <>
            <PanoramaFishEyeIcon sx={{ ...iconStyle, color: "#00B1EA" }} />
            {props.status}
          </>
        );
      case "NewWaitingForRsdbFile":
      case "Receiving Design Parameters":
        return (
          <>
            <div
              style={{
                ...iconStyle,
                marginLeft: "2px",
                marginRight: "6px",
                marginTop: "1px",
              }}
            >
              <CircleSemiFilled color="#00B1EA" id="newWaitingForRsdbFile" />
            </div>
            {props.status}
          </>
        );
      case "OrderCreated":
      case "Created":
        return (
          <>
            <div style={{ ...iconStyle, ...svgStyle }}>
              <CircleSemiFilled color="#00B1EA" id="orderCreated" />
            </div>
            {props.status}
          </>
        );
      case "AutoDesign":
      case "Auto Design Started":
        return (
          <>
            <PanoramaFishEyeIcon sx={{ ...iconStyle, color: "#FF9900" }} />
            {props.status}
          </>
        );
      case "AutoDesignCreated":
      case "Auto Design Requested":
        return (
          <>
            <ArrowCircleUpIcon sx={{ ...iconStyle, color: "#FF9900" }} />
            {props.status}
          </>
        );
      case "AutoDesignRunning":
      case "In Auto Design":
        return (
          <>
            <BlurCircularIcon sx={{ ...iconStyle, color: "#FF9900" }} />
            {props.status}
          </>
        );
      case "AutoDesignFinished":
      case "Auto Design Finished":
        return (
          <>
            <div style={{ ...iconStyle, ...svgStyle }}>
              <CircleSemiFilled color="#FF9900" id="autoDesignFinished" />
            </div>
            {props.status}
          </>
        );
      case "AutoDesignCanceled":
      case "Auto Design Canceled":
        return (
          <>
            <RemoveCircleOutlineIcon sx={{ ...iconStyle, color: "#FF9900" }} />
            {props.status}
          </>
        );
      case "AutoDesignFailed":
      case "Auto Design Failed":
        return (
          <>
            <ErrorOutlineIcon sx={{ ...iconStyle, color: "#FF9900" }} />
            {props.status}
          </>
        );
      case "AutoDesignFailedNotified":
        return (
          <>
            <ErrorIcon sx={{ ...iconStyle, color: "#FF9900" }} />
            {props.status}
          </>
        );
      case "WaitingForManualDesign":
      case "Waiting Manual Design":
        return (
          <>
            <PanoramaFishEyeIcon sx={{ ...iconStyle, color: "#7B61FF" }} />
            {props.status}
          </>
        );
      case "WaitingForManualDesignNotified":
        return (
          <>
            <TaskAltIcon sx={{ ...iconStyle, color: "#7B61FF" }} />
            {props.status}
          </>
        );
      case "ManualDesign":
      case "In Manual Design":
        return (
          <>
            <div style={{ ...iconStyle, ...svgStyle }}>
              <CircleSemiFilled color="#7B61FF" id="manualDesign" />
            </div>
            {props.status}
          </>
        );
      case "ManualDesignFinished":
      case "Manual Design Finished":
        return (
          <>
            <LensRoundedIcon sx={{ ...iconStyle, color: "#7B61FF" }} />
            {props.status}
          </>
        );
      case "WaitingForAutoDesignQC":
      case "Waiting Auto Design QC":
        return (
          <>
            <PanoramaFishEyeIcon sx={{ ...iconStyle, color: "#EEDC82" }} />
            {props.status}
          </>
        );
      case "WaitingForAutoDesignQCNotified":
        return (
          <>
            <TaskAltIcon sx={{ ...iconStyle, color: "#EEDC82" }} />
            {props.status}
          </>
        );
      case "AutoDesignQCDesignFilesReady":
      case "Auto Design QC Design Files Ready":
        return (
          <>
            <ArrowCircleDownRoundedIcon
              sx={{ ...iconStyle, color: "#EEDC82" }}
            />
            {props.status}
          </>
        );
      case "AutoDesignQCFilesDownloaded":
      case "A. Design QC Files Downloaded":
        return (
          <>
            <DownloadForOfflineRoundedIcon
              sx={{ ...iconStyle, color: "#EEDC82" }}
            />
            {props.status}
          </>
        );
      case "AutoDesignQC":
      case "In Auto Design QC":
        return (
          <>
            <div style={{ ...iconStyle, ...svgStyle }}>
              <CircleSemiFilled color="#EEDC82" id="autoDesignQC" />
            </div>
            {props.status}
          </>
        );
      case "AutoDesignQCFinished":
      case "A. Design QC Finished":
        return (
          <>
            <LensRoundedIcon sx={{ ...iconStyle, color: "#EEDC82" }} />
            {props.status}
          </>
        );
      case "OrderProductionFilesReceived":
      case "Files Received":
        return (
          <>
            <PanoramaFishEyeIcon sx={{ ...iconStyle, color: "#1B981F" }} />
            {props.status}
          </>
        );
      case "OrderProductionFilesReady":
      case "Files Ready":
        return (
          <>
            <LensRoundedIcon sx={{ ...iconStyle, color: "#1B981F" }} />
            {props.status}
          </>
        );
      case "OrderProductionFilesReadyNotified":
        return (
          <>
            <CheckCircleIcon sx={{ ...iconStyle, color: "#1B981F" }} />
            {props.status}
          </>
        );
      case "Production":
        return (
          <>
            <LensRoundedIcon sx={{ ...iconStyle, color: "#BDBDBD" }} />
            {props.status}
          </>
        );
      case "Canceled":
        return (
          <>
            <RemoveCircleOutlineIcon sx={{ ...iconStyle, color: "#DB210A" }} />
            {props.status}
          </>
        );
      case "CanceledNotified":
      case "Canceled Notified":
        return (
          <>
            <RemoveCircleIcon sx={{ ...iconStyle, color: "#DB210A" }} />
            {props.status}
          </>
        );
      case "Shipped":
        return (
          <>
            <LocalShippingRoundedIcon sx={{ ...iconStyle, color: "#808080" }} />
            {props.status}
          </>
        );
    }
  switch (props.status) {
    case "New":
      return (
        <>
          <PanoramaFishEyeIcon sx={{ ...iconStyle, color: "#00B1EA" }} />
          {props.status}
        </>
      );
    case "Created":
      return (
        <>
          <div style={{ ...iconStyle, ...svgStyle }}>
            <CircleSemiFilled color="#00B1EA" id="created" />
          </div>
          {props.status}
        </>
      );
    case "Design":
      return (
        <>
          <BlurCircularIcon sx={{ ...iconStyle, color: "#FF9900" }} />
          {props.status}
        </>
      );
    case "Files Ready":
      return (
        <>
          <LensRoundedIcon sx={{ ...iconStyle, color: "#1B981F" }} />
          {props.status}
        </>
      );
    case "In Production":
      return (
        <>
          <LensRoundedIcon sx={{ ...iconStyle, color: "#BDBDBD" }} />
          {props.status}
        </>
      );
    case "Canceled":
      return (
        <>
          <RemoveCircleOutlineIcon sx={{ ...iconStyle, color: "#DB210A" }} />
          {props.status}
        </>
      );
    case "Shipped":
      return (
        <>
          <LocalShippingRoundedIcon sx={{ ...iconStyle, color: "#808080" }} />
          {props.status}
        </>
      );
  }
  return <></>;
}

import { SetStateAction, useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  BaseSingleInputFieldProps,
  DatePickerProps,
  DateValidationError,
  FieldSection,
  UseDateFieldProps,
} from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {
  IFilter,
  IListRequestBody,
} from "../../../../domain/Common/RequestBody";

type Props = {
  field: string;
  headerName: string;
  setQueryOptions: (value: SetStateAction<IListRequestBody>) => void;
};

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <Button
      size="small"
      variant="text"
      sx={{
        height: "30px",
        minWidth: "2.4vw",
        fontFamily: "Metronic Pro ",
        fontWeight: 200,
        textTransform: "none",
        fontSize: "12px",
        marginRight: "4px",
        border: 0.5,
        borderColor: "white",
        color: "white;",
        "&:hover": {
          backgroundColor: "#468ed5"
        },
        "&:disabled": {
          color: "#bdbdbd"
        }
      }}
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label ? label : "From"}
    </Button>
  );
}

export function ButtonDatePicker(
  props: Omit<DatePickerProps<Dayjs>, "open" | "onOpen" | "onClose">
) {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } as any }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function GridHeaderDate(props: Props) {
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [filtered, setFiltered] = useState(false);

  function handleChange(newDate: Dayjs) {
    const newFilter = {
      filterType: props.field,
      filterValues: [
        fromDate?.format("YYYY-MM-DD"),
        newDate.format("YYYY-MM-DD"),
      ],
    } as IFilter;

    setFiltered(true);

    props.setQueryOptions((s) => {
      const existingFilter = s.filters.find(
        (filter: IFilter) => filter.filterType === props.field
      );

      if (existingFilter) {
        const filters = s.filters.filter(
          (filter: IFilter) => filter.filterType !== props.field
        );
        filters.push(newFilter);

        return {
          ...s,
          filters: filters,
          page: 0,
        };
      } else {
        return {
          ...s,
          filters: [...s.filters, newFilter],
          page: 0,
        };
      }
    });
  }

  function handleClear(event: any) {
    event.stopPropagation();
    setFiltered(false);
    setFromDate(null);
    setToDate(null);
    props.setQueryOptions((s) => {
      const filters = s.filters.filter(
        (filter: IFilter) => filter.filterType !== props.field
      );
      return {
        ...s,
        filters: filters,
      };
    });
  }

  return (
    <Box sx={{ lineHeight: "29.5px", textAlign: "left", height: "130px" }}>
      <Box sx={{ fontSize: "13px", display: "flex" }}>
        {filtered ? (
          <IconButton
            size="small"
            sx={{
              padding: "5px",
              "&:hover": {
                backgroundColor: "#0069d9",
              },
            }}
            onClick={handleClear}
          >
            <FilterAltOffIcon sx={{ height: "12px", width: "17px" }} />
          </IconButton>
        ) : null}
        <Box sx={{ marginLeft: filtered ? "0px" : "4px" }}>
          {props.headerName}
        </Box>
      </Box>
      <Box onClick={(event) => event.stopPropagation()}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ButtonDatePicker
            label={"From"}
            value={fromDate}
            onChange={(newDate) => setFromDate(newDate)}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ButtonDatePicker
            label={"To"}
            value={toDate}
            disabled={fromDate === null}
            onChange={(newDate) => {setToDate(newDate); handleChange(newDate!)}}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}

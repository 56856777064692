import { Button, CircularProgress } from "@mui/material";
import Popup from "../Popup/Popup";
import { useState } from "react";

type Props = {
  buttonText: string;
  handleApprove: () => void;
  extraStyling?: any;
  approveTitle?: string;
  approveDescription?: string;
  confirmationDescription?: string;
  loadingState?: boolean;
  disabled?: boolean;
};

export default function CustomActionButton(props: Props) {
  const [displayApproveAction, setDisplayApproveAction] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  function handleClick() {
    setDisplayApproveAction(true);
  }

  function handleApproveClick() {
    props.handleApprove();
    setDisplayApproveAction(false);
    setDisplayConfirmation(true);
  }

  function handleDenyAction() {
    setDisplayApproveAction(false);
  }

  function handleConfirmation() {
    setDisplayConfirmation(false);
  }

  return (
    <>
      <Button
        sx={{
          borderRadius: "5px",
          fontFamily: "Metronic Pro",
          width: "200px",
          margin: "10px 0 10px 10px",
          textTransform: "none",
          float: "right",
          ...props.extraStyling,
        }}
        size="small"
        variant="contained"
        onClick={handleClick}
        disabled={(props.loadingState || props.disabled) ?? false}
      >
        {props.buttonText}
        {props.loadingState ? (
          <CircularProgress
            size={24}
            sx={{
              position: "fixed",
              marginRight: "5px",
            }}
          />
        ) : null}
      </Button>
      <Popup
        title={props.approveTitle ?? props.buttonText}
        description={
          props.approveDescription ??
          "Are you sure you want to perform this action?"
        }
        approveText="YES"
        denyText="NO"
        open={displayApproveAction}
        handleApprove={handleApproveClick}
        handleDeny={handleDenyAction}
      />
      {props.confirmationDescription ? (
        <Popup
          title={props.approveTitle ?? props.buttonText}
          description={
            props.confirmationDescription ?? "Action performed successfully!"
          }
          approveText="OK"
          open={displayConfirmation}
          handleApprove={handleConfirmation}
        />
      ) : null}
    </>
  );
}

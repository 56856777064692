import Box from "@mui/material/Box";
import LensIcon from "@mui/icons-material/Lens";

type Props = {
  status: boolean;
};

export default function AccountStatus(props: Props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <LensIcon
        sx={{
          fontSize: 15,
          color: props.status ? "green" : "red",
          marginRight: "5px",
        }}
      />
      {props.status ? "Enabled" : "Disabled"}
    </Box>
  );
}

import Box from "@mui/material/Box";
import LensIcon from "@mui/icons-material/Lens";

type Props = {
  credentials: string;
};

export default function AccountCredentials(props: Props) {
  const isConfirmed = props.credentials === "CONFIRMED";
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <LensIcon
        sx={{
          fontSize: 15,
          color: isConfirmed ? "green" : "yellow",
          marginRight: "5px",
        }}
      />
      {isConfirmed ? "OK" : "In Resetting"}
    </Box>
  );
}

import React from 'react';

function Health() {
  return (
    <div>
      <h1>Health Check</h1>
      <p>Your application is healthy and running.</p>
    </div>
  );
}

export default Health;
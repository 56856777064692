import { useState } from "react";
import Grid from "../../Components/Grid/Grid";
import ordersService from "../../../services/Orders/OrdersService";
import defaultColumnPreferences from "./ColumnDefaultPreferences";
import { Box, Button } from "@mui/material";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import storage from "../../../services/Share/storageWithEncryption";
import { UserRoles } from "../../../domain/Users/userRoles";
import { OrderStatus } from "../../../domain/Orders/orderStatus";
import { IFilter, IListRequestBody } from "../../../domain/Common/RequestBody";
import CustomGridButton from "../../Components/Buttons/CustomGridButton";
import Popup from "../../Components/Popup/Popup";
import { IMultiselectOrders } from "../../../domain/Orders/OrderIds";
import { IDelayOrders } from "../../../domain/Orders/DelayOrders";
import DelayedOrderButton from "../../Components/Buttons/DelayedOrderButton";
import { Dayjs } from "dayjs";
import { NavigateFunction } from "react-router-dom";

type Props = {
  navigate: NavigateFunction;
};

export default function OrdersPage(props: Props) {
  const userRole = storage.getDataFromLocalStorage("userRole");
  const userId = storage.getDataFromLocalStorage("userId");
  const gridPageSize = 50;
  const paginationSize = 100;

  const [currentOrder, setCurrentOrder] = useState<any>({});
  const [multiselectedOrders, setMultiselectedOrders] = useState<IMultiselectOrders>({} as IMultiselectOrders);
  const [multiselectedShippedOrders, setMultiselectedShippedOrders] = useState<IMultiselectOrders>({} as IMultiselectOrders);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [items, setItems] = useState<any[]>([]);
  const [uploadFileLeftSTL, setUploadFileLeftSTL] = useState<File>();
  const [uploadFileRightSTL, setUploadFileRightSTL] = useState<File>();
  const [uploadFilesConfirmation, setUploadFilesConfirmation] = useState<boolean>(false);
  const [downloadFileButton, setDownloadFileButton] = useState<Boolean>(false);
  const [designParametersButton, setDesignParametersButton] = useState<Boolean>(false);
  const [downloadFileSpinner, setDownloadFileSpinner] = useState<boolean>(false);
  const [designParametersSpinner, setDesignParametersSpinner] = useState<boolean>(false);
  const [uploadFilesButton, setUploadFilesButton] = useState<Boolean>(false);
  const [uploadFileSpinner, setuploadFileSpinner] = useState<boolean>(false);
  const [takeOrderButton, setTakeOrderButton] = useState<Boolean>(false);
  const [takeManualDesignButton, setTakeManualDesignButton] = useState<Boolean>(false);
  const [validateButtons, setValidateButtons] = useState<Boolean>(false);
  const [productionButton, setProductionButton] = useState<Boolean>(false);
  const [cancelButton, setCancelButton] = useState<Boolean>(false);
  const [shippedButton, setShippedButton] = useState<Boolean>(false);
  const [delayedButton, setDelayedButton] = useState<Boolean>(false);
  const [delayedDate, setDelayedDate] = useState<Dayjs | null>(null);
  const [delayReason, setDelayReason] = useState<string>("");
  const [query, setQuery] = useState<IListRequestBody>({
    page: 0,
    pageSize: 100,
    sortingDirection: 0,
    sortBy: "orderDate",
    filters: [] as IFilter[],
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: gridPageSize,
  });

  const autoDesignQCStatus = [OrderStatus.WaitingForAutoDesignQC, OrderStatus.WaitingForAutoDesignQCNotified];
  const validateStatus = [OrderStatus.AutoDesignQCFilesDownloaded, OrderStatus.AutoDesignQC];
  const manualDesignStatus = [OrderStatus.WaitingForManualDesign, OrderStatus.WaitingForManualDesignNotified];
  const manualDesignRoles = [UserRoles.MTLSEngineers];
  const productionFilesStatus = [OrderStatus.OrderProductionFilesReady, OrderStatus.OrderProductionFilesReadyNotified];
  const productionFilesRoles = [UserRoles.MotionAdmin, UserRoles.MTLSEngineers, UserRoles.ProductionOperator, UserRoles.ChiefPractitioner];
  const cancelDisableStatus = [OrderStatus.Canceled, OrderStatus.CanceledNotified];
  const cancelRoles = [UserRoles.CustomerSupport, UserRoles.MotionAdmin];
  const shipStatus = [OrderStatus.Production, OrderStatus.InProduction];
  const shipRoles = [UserRoles.MotionAdmin, UserRoles.ProductionOperator, UserRoles.ChiefPractitioner];
  const delayRoles = [UserRoles.MotionAdmin, UserRoles.ProductionOperator, UserRoles.ChiefPractitioner];

  let getData: string = "";
  let getFilters: string = "";
  let ordersColumns = new Array<GridColDef>();
  const productionOperatorRoles = [UserRoles.CustomerSales, UserRoles.ProductionOperator, UserRoles.ChiefPractitioner];
  const adminRoles = [UserRoles.MotionAdmin, UserRoles.MTLSEngineers];
  const practitionerRoles = [UserRoles.CustomerSupport, UserRoles.Practitioner];
  const getGetEndPointsByRole = function () {
    if (productionOperatorRoles.includes(userRole)) {
      getData = 'getOrdersForProductionOperatorRole';
      getFilters = 'getFiltersForProductOperatorRole';
      ordersColumns=require(`./OrdersColumnsProductOperator`).default;
      return;
    }
    if (adminRoles.includes(userRole)) {
      getData = 'getOrdersForAdminRole';
      getFilters = 'getFiltersForAdminRole';
      ordersColumns=require(`./OrdersColumnsAdmin`).default;
      return;
    }
    if (practitionerRoles.includes(userRole)) {
      getData = 'getOrdersForCustomerSupportRole';
      getFilters = 'getFiltersForCustomerSupportRole';
      ordersColumns=require(`./OrdersColumnsCustomerSupport`).default;
    }
  }

  const itemsCallback = (items: any[] ) => setItems(items);
  const onRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {

    setRowSelectionModel((r) => {
      if (newRowSelectionModel.length - r.length > gridPageSize) {
        const firstRow = paginationModel.page * gridPageSize;
        return newRowSelectionModel.slice(firstRow, firstRow + gridPageSize);
      }
      return newRowSelectionModel;
    });
    if(!newRowSelectionModel.length) {
      setProductionButton(false);
      setDesignParametersButton(false);
      setShippedButton(false);
      setDelayedButton(false);
    }

    if(!!newRowSelectionModel.length) handleMultiselection(newRowSelectionModel);

    if(!newRowSelectionModel.length || newRowSelectionModel.length > 1){
      setDownloadFileButton(false);
      setUploadFilesButton(false);
      setTakeOrderButton(false);
      setTakeManualDesignButton(false);
      setValidateButtons(false);
      setCancelButton(false);
      setCurrentOrder({});
      return;
    }
    let order = items.find( (x: any) => x.orderCode === newRowSelectionModel[0]);
    setCurrentOrder(order);
    setDownloadFileButton(
      (autoDesignQCStatus.includes(order.status) && userRole === UserRoles.MotionAdmin) ||
      (order.status === OrderStatus.AutoDesignQCDesignFilesReady && userRole === UserRoles.MTLSEngineers && order.currentActorId === userId) ||
      (order.status === OrderStatus.ManualDesign && manualDesignRoles.includes(userRole) && order.currentActorId === userId)
    );
    setUploadFilesButton(order.status === OrderStatus.ManualDesign && manualDesignRoles.includes(userRole) && order.currentActorId === userId);
    setTakeOrderButton(autoDesignQCStatus.includes(order.status) && userRole === UserRoles.MTLSEngineers);
    setTakeManualDesignButton(manualDesignStatus.includes(order.status) && manualDesignRoles.includes(userRole));
    setValidateButtons(validateStatus.includes(order.status) && userRole === UserRoles.MTLSEngineers && order.currentActorId === userId);
    setProductionButton(productionFilesStatus.includes(order.status) && productionFilesRoles.includes(userRole));
    setDesignParametersButton(userRole === UserRoles.MotionAdmin)
    setCancelButton(cancelRoles.includes(userRole) && !cancelDisableStatus.includes(order.status));
  }

  const downloadFileStreamResult = (file: any, lastDownload: boolean = true) => {
    let contentType = file.headers['content-type'] as string;
    let contentDisposition = file.headers['content-disposition'] as string;
    let fileNameKey = 'filename=';
    let start = contentDisposition.indexOf(fileNameKey) + fileNameKey.length;
    let end = contentDisposition.indexOf(';', start);
    if(end < 0)
      end = contentDisposition.length;
    let fileName = contentDisposition.slice(start, end);
    let blob = new Blob([file.data], { type: contentType });

    let link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    link.click();
    if (lastDownload) {
      setDownloadFileSpinner(false);
      setDesignParametersSpinner(false);
    }
  }

  const downloadFile = () => {
    setDownloadFileSpinner(true);

    if(downloadFileButton) {
      ordersService.getDesignFile(currentOrder.id)
      .then(file => {
        refresh();
        downloadFileStreamResult(file);
      })
      .catch(e => {
        alert('Something happened downloading the design file, please try again');
        console.error(e);
        setDownloadFileSpinner(false);
      });
      return;
    }

    const numberOfOrders = multiselectedOrders.orderIds.orderIds.length;
    for (let i = 0; i < numberOfOrders; i++) {
      let orderId = multiselectedOrders.orderIds.orderIds[i];
      ordersService.getProductionFile(parseInt(orderId))
        .then(file => {
          downloadFileStreamResult(file, i === 0);
          if(i === 0 && userRole === UserRoles.ProductionOperator || UserRoles.ChiefPractitioner){
            refreshMultiple(multiselectedOrders.orderCodes);
          }
        })
        .catch(e => {
          alert('Something happened downloading the production file, please try again');
          console.error(e);
          setDownloadFileSpinner(false);
        });
    }
  }

  const downloadDesignParameters = () => {
    setDesignParametersSpinner(true);

    const numberOfOrders = multiselectedOrders.orderIds.orderIds.length;
      for (let i = 0; i < numberOfOrders; i++) {
        let orderId = multiselectedOrders.orderIds.orderIds[i];
        ordersService.getOrderFile({ orderId: parseInt(orderId), fileType: 0})
          .then(file => {
            downloadFileStreamResult(file, i === 0);
            if(i === 0 && userRole === UserRoles.MotionAdmin){
              refreshMultiple(multiselectedOrders.orderCodes);
            }
          })
          .catch(e => {
            alert('Something happened downloading the design parameters, please try again');
            console.error(e);
            setDesignParametersSpinner(false);
          });
      }
  }

  const loadManualFileLeftSTL = (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files as FileList;
    setUploadFileLeftSTL(files?.[0]);
  }

  const loadManualFileRightSTL = (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files as FileList;
    setUploadFileRightSTL(files?.[0]);
  }

  const uploadFiles = () => {
    setuploadFileSpinner(true);
    ordersService.uploadDesignFile({orderId: currentOrder.id, fileType: 2, data: uploadFileLeftSTL!})
      .then(() => {
        ordersService.uploadDesignFile({orderId: currentOrder.id, fileType: 3, data: uploadFileRightSTL!})
          .then(() => {
            setuploadFileSpinner(false);
            setUploadFilesConfirmation(true);
          })
          .catch(e => {
            alert('Something happened uploading the files, please try again');
            console.error(e);
            setuploadFileSpinner(false);
          });
      })
      .catch(e => {
        alert('Something happened uploading the files, please try again');
        console.error(e);
        setuploadFileSpinner(false);
      });
  }

  const finishManualDesign = () => {
    ordersService.finishManualDesign(currentOrder.id)
      .then(() => {
        refresh();
      })
      .catch(e => {
        alert('Something happened with the Manual Design, please try again');
        console.error(e);
      });
  }

  const takeOrder = () => {
    ordersService.takeAutoDesignQC(currentOrder.id)
      .then(r => {
        refresh();
      })
      .catch(e => {
        alert('Something happened taking the order, please try again');
        console.error(e);
      });
  }

  const takeManualDesign = () => {
    ordersService.takeManualDesign(currentOrder.id)
      .then(r => {
        refresh();
      })
      .catch(e => {
        alert('Something happened taking the order, please try again');
        console.error(e);
      });
  }

  const validateQC = () => {
    ordersService.approveAutoDesignQC(currentOrder.id)
    .then(r => {
      refresh();
    })
    .catch(e => {
      alert('Something happened approving auto design QC, please try again');
      console.error(e);
    });
  }

  const failQC = () => {
    ordersService.failedAutoDesignQC(currentOrder.id)
    .then(r => {
      refresh();
    })
    .catch(e => {
      alert('Something happened failed auto design QC, please try again');
      console.error(e);
    });
  }

  const refresh = () => {
    setQuery((s) => ({
      ...s,
      page: 0,
      filters: [{
        filterType : "orderCode",
        filterValues : [currentOrder.orderCode]
      }]
    }));
    onRowSelectionModelChange([]);
  }

  const refreshMultiple = (multiselectedOrdersCodes: string[]) => {
    setQuery((s) => ({
      ...s,
      page: 0,
      filters: [{
        filterType : "orderCode",
        filterValues : multiselectedOrdersCodes
      }]
    }));
    onRowSelectionModelChange([]);
  }

  const cancelOrder = () => {
    ordersService.cancelOrder(currentOrder.id)
    .then(r => {
      refresh();
    })
    .catch(e => {
      alert('Something happened failed cancel order, please try again');
      console.error(e);
    });
  }

  const handleMultiselection = (newRowSelectionModel: GridRowSelectionModel) => {
    let shipOrders = items.filter((x: any) => {
      let order = newRowSelectionModel.find((selectedOrder) => selectedOrder === x.orderCode);
      return !!order ? shipStatus.includes(x.status) && x.orderFlow === "Default" : undefined;
    });
    let orders = items.filter((x: any) => {
      let order = newRowSelectionModel.find((selectedOrder) => selectedOrder === x.orderCode);
      return !!order;
    });

    if (shipOrders.length === newRowSelectionModel.length) {
      setShippedButton(shipRoles.includes(userRole));
      setMultiselectedShippedOrders({
        orderCodes: shipOrders.map((order) => order.orderCode),
        orderIds: {orderIds: shipOrders.map((order) => order.id)},
      });
    }
    else setShippedButton(false);
    
    if (!!orders.length) {
      setMultiselectedOrders({
        orderCodes: orders.map((order) => order.orderCode),
        orderIds: {orderIds: orders.map((order) => order.id)},
      });
      if (!!orders.find((order) => !productionFilesStatus.includes(order.status)))
        setProductionButton(false);
      else setProductionButton(productionFilesRoles.includes(userRole));
      if (!!orders.find((order) => cancelDisableStatus.includes(order.status)))
        setDelayedButton(false);
      if (!!orders.find((order) => order.orderFlow !== "Default"))
        setDelayedButton(false);
      else setDelayedButton(delayRoles.includes(userRole));
    }
    
  }

  const shipOrders = () => {
    ordersService.shipOrders(multiselectedShippedOrders.orderIds)
    .then(r => {
      setTimeout(() => refreshMultiple(multiselectedShippedOrders.orderCodes), 1000);
    })
    .catch(e => {
      alert('Something happened failed shipping order, please try again');
      console.error(e);
    });
  }

  const delayOrders = () => {
    const delayedOrdersInfo = {
      orderIds: multiselectedOrders.orderIds.orderIds,
      delayDate: delayedDate?.format('YYYY-MM-DD'),
      delayReason: delayReason,
    } as IDelayOrders;
    ordersService.delayOrders(delayedOrdersInfo)
    .then(r => {
      refreshMultiple(multiselectedOrders.orderCodes);
    })
    .catch(e => {
      alert('Something happened failed delaying order, please try again');
      console.error(e);
    });
  }

  const translateFilters = (filters: IFilter[]) => {
    let active = filters.find(f => f.filterType === 'delayedDays');
    if(!!active)
      active.filterValues = ['Delayed Orders', 'On-time Orders'];
  }

  const unTranslateFilters = (filters: IFilter[]) => {
    let active = filters.find(f => f.filterType === 'delayedDays');
    if(!!active) {
      active.filterValues = active.filterValues.map(f => {
        if(f === 'Delayed Orders')
          return 'true';
        if(f === 'On-time Orders')
          return 'false';
        return f;
      });
    }
  }

  function handleOrderDetails(params: any) {
    props.navigate(`/OrderDetails/${params.row.id}`);
  }

  const userRolePreferences = defaultColumnPreferences[userRole as keyof typeof defaultColumnPreferences];
  getGetEndPointsByRole();
  return (
    <Box style={{ height: "75vh", width: "100vw-50px", padding: "0px 30px 30px 30px", marginLeft: 50, }} >
      <Box component="label" className="navigation-link" onClick={() => window.location.reload()}>
        Orders
      </Box>
      <Grid
        getRowId="orderCode"
        columnPreferencesName={`orders${userRole}ColumnPreferences`}
        defaultColumnPreferences={userRolePreferences}
        columns={ordersColumns}
        paginationModel={[paginationModel, setPaginationModel]}
        gridPageSize={gridPageSize}
        paginationSize={paginationSize}
        isServerSideFilterData={true}
        isServerSideOrderData={true}
        getData={ordersService[getData]}
        getFilters={ordersService[getFilters]}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
        itemsCallback={itemsCallback}
        queryOptions={[query, setQuery]}
        translateFilters={translateFilters}
        unTranslateFilters={unTranslateFilters}
        onRowDoubleClick={handleOrderDetails}
      />
      {
        ( downloadFileButton || productionButton ?
          (<CustomGridButton
            buttonText="Download File"
            handleApprove={downloadFile}
            loadingState={downloadFileSpinner}
          />) : null )
      }
      {
        ( designParametersButton ?
          (<CustomGridButton
            buttonText="Download D. Parameters"
            handleApprove={downloadDesignParameters}
            loadingState={designParametersSpinner}
          />) : null )
      }
      {
        ( uploadFilesButton ?
          (<>
            <Button
              variant="contained"
              component="label"
              size="small"
              sx={{
                borderRadius: "5px",
                fontFamily: "Metronic Pro",
                width: "200px",
                margin: "10px 0 10px 10px",
                textTransform: "none",
                float: "left",
              }}>
              Select STL_L
              <input hidden multiple={false} type="file"
                accept=".stl"
                onChange={loadManualFileLeftSTL}
              />
            </Button>
            <Button
              variant="contained"
              component="label"
              size="small"
              sx={{
                borderRadius: "5px",
                fontFamily: "Metronic Pro",
                width: "200px",
                margin: "10px 0 10px 10px",
                textTransform: "none",
                float: "left",
              }}>
              Select STL_R
              <input hidden multiple={false} type="file"
                accept=".stl"
                onChange={loadManualFileRightSTL}
              />
            </Button>
            <CustomGridButton
              buttonText="Upload File"
              handleApprove={uploadFiles}
              loadingState={uploadFileSpinner}
              disabled={!uploadFileLeftSTL || !uploadFileRightSTL}
            />
            <Popup
              title="Upload File"
              description="Files Uploaded Successfully!"
              approveText="OK"
              open={uploadFilesConfirmation}
              handleApprove={finishManualDesign}
            />
          </>) : null )
      }
      {
        ( takeOrderButton ?
          (<CustomGridButton
            buttonText="Take Order"
            handleApprove={takeOrder}
          />) : null )
      }
      {
        ( takeManualDesignButton ?
          (<CustomGridButton
            buttonText="Take Order"
            handleApprove={takeManualDesign}
          />) : null )
      }
      {
        ( cancelButton ?
          (<CustomGridButton
            buttonText="Cancel Order"
            handleApprove={cancelOrder}
            extraStyling={{ backgroundColor: "red", "&:hover": { backgroundColor: "#d10e00" } }}
          />) : null )
      }
      {
        ( validateButtons ?
          (<Box>
            <CustomGridButton
              buttonText="Validate QC"
              handleApprove={validateQC}
              extraStyling={{ backgroundColor: "green", "&:hover": { backgroundColor: "darkgreen" } }}
            />
            <CustomGridButton
              buttonText="Fail QC"
              handleApprove={failQC}
              extraStyling={{ backgroundColor: "red", "&:hover": { backgroundColor: "#d10e00" } }}
            />
          </Box>
          ) : null )
      }
      {
        ( shippedButton ?
          (<CustomGridButton
            buttonText="Orders Shipped"
            approveDescription="Are you sure you want to promote this/these orders to shipped?"
            handleApprove={shipOrders}
            extraStyling={{ backgroundColor: "green", "&:hover": { backgroundColor: "darkgreen" } }}
          />) : null )
      }
      {
        ( delayedButton ?
          (<DelayedOrderButton
            setDelayedDate={setDelayedDate}
            setDelayReason={setDelayReason}
            handleApprove={delayOrders}
          />) : null )
      }
    </Box>
  );
}

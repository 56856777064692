import Box from "@mui/material/Box";
import { NavigateFunction, useParams } from "react-router-dom";
import { ICompanyDetails } from "../../../domain/Company/CompanyDetalis";
import { useEffect, useState } from "react";
import companiesService from "../../../services/Companies/CompaniesService";
import { AxiosError } from "axios";
import IAllowedProduct from "../../../domain/Product/AllowedProduct";
import Offices from "./Offices";
import IAddress from "../../../domain/Common/Address";
import GeneralInfo from "./GeneralInfo";
import Address from "./Address";
import { Grid, Tab, Tabs } from "@mui/material";
import AllowedProducts from "./AllowedProducts";

type Props = {
  navigate: NavigateFunction;
}

export default function CompanyDetails({ navigate }: Props) {
  const { companyId } = useParams();

  const [generalInfo, setGeneralInfo] = useState<ICompanyDetails>();
  const [address, setAddress] = useState<IAddress>();
  const [allowedProducts, setAllowedProducts] = useState<Array<IAllowedProduct> | undefined>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    companiesService.getCompany(Number(companyId))
      .then((companyDetails: ICompanyDetails) => {
        setGeneralInfo({
          companyId: companyDetails.id,
          name: companyDetails.name,
          dateOfCreation: new Date(companyDetails.dateOfCreation!).toLocaleString('es', {day: '2-digit', month: '2-digit', year: 'numeric'}),
          companyType: companyDetails.companyType,
          customerNumber: companyDetails.customerNumber,
        });
        setAddress({
          companyId: companyDetails.id,
          addressId: companyDetails.address?.id,
          streetName: companyDetails.address?.streetName ?? '',
          appartmentNumber: companyDetails.address?.appartmentNumber ?? '',
          postalCode: companyDetails.address?.postalCode ?? '',
          city: companyDetails.address?.city ?? '',
          state: companyDetails.address?.state ?? '',
          country: companyDetails.address?.country ?? '',
          countryCode: companyDetails.address?.countryCode ?? ''
        });
        setAllowedProducts(companyDetails.allowedProducts);
      })
      .catch((error: AxiosError<any>) => console.error(error));
  }, [reload]);

  const handleCompaniesClick = () => {
    navigate('/Companies');
  }

  const handleAddCompanyClick = () => {
    navigate("/AddCompany");
  }

  return (
    <Box sx={{ marginLeft: "30px", marginRight: "20px" }}>
      <Box sx={{ display: "flex", height: "10px", marginBottom: "10px" }}>
        <Box component="span" className="navigation-link" style={{ marginLeft: "50px", textDecoration: "none" }} onClick={handleCompaniesClick}>Companies</Box>
        <Box component="span" className="navigation-link" style={{ marginLeft: "50px", textDecoration: "none" }} onClick={handleAddCompanyClick}>Add New Company</Box>
      </Box>

      <Box sx={{ display: 'flex', marginLeft: 'calc(2rem + 50px)', alignItems: 'center' }}>
        <Box sx={{ fontSize: '1.5rem' }}><b>Company Details</b></Box>
      </Box>

      <Box style={{ width: "100vw-50px", marginTop: "1rem", marginLeft: "70px" }}>
        <Tabs className="custom-tabs" value={tabIndex} onChange={(_, e) => setTabIndex(e)} sx={{ marginBottom: "20px" }}>
          <Tab className="custom-tab" label="General Info" />
          <Tab className="custom-tab" label="Address" />
          <Tab className="custom-tab" label="Products" />
        </Tabs>
        <Grid container spacing={5} sx={{ width: "100%", height: "68vh" }}>
          <Grid item xs={3.5}>
            <div role="tabpanel" hidden={tabIndex !== 0}>
              <GeneralInfo generalInfo={generalInfo} setGeneralInfo={setGeneralInfo} setReload={setReload} />
            </div>
            <div role="tabpanel" hidden={tabIndex !== 1}>
              {address ? <Address address={address} setAddress={setAddress} setReload={setReload} /> : null}
            </div>
            <div role="tabpanel" hidden={tabIndex !== 2}>
              {allowedProducts ? <AllowedProducts companyId={Number(companyId)} allowedProducts={allowedProducts} setReload={setReload} /> : null}
            </div>
          </Grid>
          <Grid item xs={0.5}/>
          <Grid item xs={8}>
            <Offices navigate={navigate} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
import { Component } from "react";

export default class ErrorPage extends Component {

  render() {
    return (
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p><b>Order Flow Management team is fixing it </b></p>
        <p>
          <i>Error</i>
        </p>
      </div>
    );
  }


}
import { Office } from "../../domain/Common/Office";
import { IFilter, IListRequestBody } from "../../domain/Common/RequestBody";
import { IRequestResponse } from "../../domain/Common/RequestResponse";
import IOffice from "../../domain/Office/IOffice";
import http from "../Share/httpCommon";

class OfficesService {
    getOffices(company: string) {
        return http.api.get<Office[]>('/v1/office/GetOffices', `company=${company}`);
    }

    async getOfficeList(body: IListRequestBody): Promise<IRequestResponse<IOffice>> {
        return (await http.api.post<IRequestResponse<IOffice>>('/v1/office/getOfficeList', body));
    }

    async getOfficeFilters() : Promise<IFilter[]> {
        return await http.api.get<IFilter[]>('/v1/office/getOfficeListFilters');
    }

    getOffice(officeId: string) {
        return http.api.get<any>('/v1/office/getOffice', `officeId=${officeId}`);
    }

    addOffice(office: any) {
        return http.api.post<any>('/v1/office/addOffice', office);
    }

    editOffice(office: any) {
        return http.api.post<any>('/v1/office/editOffice', office);
    }
}

let officesService = new OfficesService();
export default officesService;
import http from "../Share/httpCommon";
import { LoginResponse, RefreshTokenResponse } from "../../domain/Login/LoginResponse";

class AccountService {
  async getToken(user: string, password: string): Promise<void> {
    let response = await http.api.post<LoginResponse>('/v1/userAction/login/', { Email: user, Password: password });
    if (response)
      http.api.setCredentials(response);
  }
  async resetPassword(user: string): Promise<boolean> {
    let resetPasswordResponse = await http.api.post<boolean>('/v1/userAction/resetPassword', { email: user });
    return resetPasswordResponse;
  }
  async setNewPasswordWithCode(user: string, code: string, newpassword: string, email: string): Promise<boolean> {
    let resetPasswordResponse = await http.api.post<boolean>('/v1/userAction/resetPasswordWithConfirmationCode',
      { userId: user, newPassword: newpassword, emailAddress: email, confirmationCode: code });
    return resetPasswordResponse;
  }

  async getRefreshedToken(refreshToken: string): Promise<RefreshTokenResponse> {
    let authorizationToken = await http.api.post<RefreshTokenResponse>('/v1/userAction/refresh', { RefreshToken: refreshToken });
    return authorizationToken;
  }
}
const loginService = new AccountService();
export default loginService;
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Popup from "../../Components/Popup/Popup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LensIcon from "@mui/icons-material/Lens";
import UserService from "../../../services/Users/UsersService";
import OfficesService from "../../../services/Offices/OfficesService";
import { NewUserLabel } from "../../../domain/Users/newUser";
import { Office } from "../../../domain/Common/Office";
import { AxiosError } from "axios";
import { IUser } from "../../../domain/Users/IUser";
import ChangeUserStateButton from "../../Components/Buttons/ChangeUserStateButton";
import ResetPasswordButton from "../../Components/Buttons/ResetPasswordButton";
import { NavigateFunction, useParams } from "react-router-dom";
import { Country, getCountries, getCountryCallingCode, isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { Company } from "../../../domain/Common/Company";
import CompaniesService from "../../../services/Companies/CompaniesService";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { UserRoles } from "../../../domain/Users/userRoles";

const textFieldStyle = {
  boxShadow: "3",
  height: "60px",
  borderRadius: "5px",
  justifyContent: "center",
  textAlign: "left",
  paddingLeft: "20px",
  paddingRight: "10px",
};

interface Props {
  navigate: NavigateFunction;
}
function validateEmail(email: string): boolean {
  const emailRegExp: RegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegExp.test(email);
}
export default function EditUser(props: Props) {
  const [tabIndex, setTabIndex] = useState(0);
  const [userInfo, setUserInfo] = useState<IUser>({
    userId: "",
    emailAddress: "",
    office: "",
    name: "",
    middleName: "",
    familyName: "",
    phoneNumber: "",
    role: "",
    company: "",
    active: true,
    status: "",
    allowedCompanies: [],
  });
  const [displayLabel, setDisplayLabel] = useState<NewUserLabel>({
    emailAddress: true,
    name: true,
    familyName: true,
    middleName: true,
    phoneNumber: true,
    role: true,
    office: true,
    company: true,
    allowedCompanies: true,
  });
  const [companyNames, setCompanyNames] = useState<Company[]>([]);
  const [officeNames, setOfficeNames] = useState<Office[]>([]);
  const [displayAction, setDisplayAction] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [displaySubmitButton, setDisplaySubmitButton] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const { userId } = useParams();
  const [countryPhone, setCountryPhone] = useState<Country>('BE');
  const [phone, setPhone] = useState<string>('');
  const [phoneError, setPhoneError] = useState(false);

  useEffect(() => {
    UserService.getUserById(userId || "").then((user) => {
      CompaniesService.getCompanies(user.role).then((result: Company[]) => setCompanyNames(result));
      OfficesService.getOffices(user.company || "").then((offices: Office[]) =>
        setOfficeNames(offices)
      );
      setUserInfo(user);
      let phoneNumber = parsePhoneNumber(user.phoneNumber);
      if (!phoneNumber) return;
      setPhone(phoneNumber.nationalNumber);
      setCountryPhone(phoneNumber.country!);
    });
  }, [reloadData]);

  function handleSubmit() {
    setDisplayAction(false);

    if (!isPossiblePhoneNumber(userInfo.phoneNumber)) {
      setPhoneError(true);
      return;
    }
    if (!validateEmail(userInfo.emailAddress)) {
      alert("User`s email is not valid");
      return;
    }
    UserService.editUser(userInfo)
      .then(() => setDisplayConfirmation(true))
      .catch((error: AxiosError<any>) => {
        alert("Something happened trying to save the User");
      });
  }

  function onSubmitClick(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setDisplayAction(true);
    let completePhoneNumber = `+${getCountryCallingCode(countryPhone)}${phone}`;
    handleChange({ target : { value: completePhoneNumber} } as any, "phoneNumber");
    return;
  }

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string
  ) {
    setDisplaySubmitButton(true);
    let newValue = event.target.value;
    setUserInfo({ ...userInfo, [fieldName]: newValue });
    newValue
      ? setDisplayLabel({ ...displayLabel, [fieldName]: false })
      : setDisplayLabel({ ...displayLabel, [fieldName]: true });
  }

  function handleConfirmation() {
    setDisplayConfirmation(false);
    props.navigate("/Users");
  }

  function handleUsersClick() {
    props.navigate("/Users");
  }

  return (
    <Box sx={{ marginLeft: "30px", marginRight: "20px" }}>
      <Box sx={{ display: "flex" }}>
        <Box component="span" className="navigation-link" style={{ marginLeft: "50px", textDecoration: "none" }} onClick={handleUsersClick}>Users</Box>
        <Box component="span" className="navigation-link" style={{ marginLeft: "50px" }} onClick={() => window.location.reload()}>Edit User</Box>
      </Box>
      <Box style={{ height: "75vh", width: "100vw-50px" }}>
        <form onSubmit={onSubmitClick}>
          <Box sx={{ boxShadow: 1, minHeight: "60vh", paddingBottom: "20px", marginLeft: "50px", }}>
            <Tabs className="custom-tabs" value={tabIndex} onChange={(_, e) => setTabIndex(e)} sx={{ paddingLeft: "15px", marginBottom: "10px" }}>
              <Tab className="custom-tab" label="Information" />
            </Tabs>

            {userInfo.status !== "" ? (
              <Box sx={{ marginLeft: "20px" }}>
                <Box sx={{ display: "flex", marginBottom: "10px", alignItems: "center", height: "30px" }}>
                  <LensIcon sx={{ fontSize: 15, color: userInfo.active ? "green" : "red", marginTop: "2px", marginRight: "5px" }} />
                  Account {userInfo.active ? "Enabled" : "Disabled"}
                  {userInfo.active !== undefined && userInfo.userId !== undefined ? (
                    <Box sx={{ position: "fixed", right: "30px" }}>
                      <ChangeUserStateButton isUserActive={userInfo.active} userId={userInfo.userId} reloadData={() => setReloadData(!reloadData)} />
                    </Box>
                    ) : ( <></> )
                  }
                </Box>

                <Box sx={{ display: "flex", marginBottom: "20px", alignItems: "center", height: "30px" }}>
                  <LensIcon sx={{ fontSize: 15, color: userInfo.status === "CONFIRMED" ? "green" : "yellow", marginTop: "2px", marginRight: "5px", }} />
                  {userInfo.status === "CONFIRMED" ? "Credentials OK" : "User Resetting Password"}
                  <Box sx={{ position: "fixed", right: "30px" }}><ResetPasswordButton email={userInfo.emailAddress} reloadData={() => setReloadData(!reloadData)} /></Box>
                </Box>
              </Box>
            ) : undefined}
            <Grid container spacing={4} sx={{ paddingLeft: "25px" }}>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* User Role</Box>
                <Box sx={{ position: "relative" }}>
                  <TextField disabled required fullWidth variant="standard" sx={textFieldStyle} value={userInfo.role} InputProps={{ disableUnderline: true }} />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* Company</Box>
                <Box sx={{ position: "relative" }}>
                  <TextField disabled required fullWidth variant="standard" sx={textFieldStyle} value={userInfo.company} InputProps={{ disableUnderline: true }} />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* Office/Store</Box>
                <Box sx={{ position: "relative" }}>
                  <TextField required select fullWidth variant="standard" sx={textFieldStyle} value={officeNames.length ? userInfo.office : ""} InputProps={{ disableUnderline: true }} SelectProps={{ IconComponent: KeyboardArrowDownIcon }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, "office")}
                  >
                    {officeNames.map((officeName: Office) => ( <MenuItem key={officeName.id} value={officeName.name}>{ officeName.name }</MenuItem> ))}
                  </TextField>
                </Box>
              </Grid>
              <Box width="100%" />
              <Grid item xs={3}>
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* User First Name</Box>
                <Box sx={{ position: "relative" }}>
                  <TextField required fullWidth variant="standard" sx={textFieldStyle} value={userInfo.name} InputProps={{ disableUnderline: true }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, "name")}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>User Middle Name</Box>
                <Box sx={{ position: "relative" }}>
                  <TextField fullWidth variant="standard" sx={textFieldStyle} value={userInfo.middleName} InputProps={{ disableUnderline: true }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, "middleName")}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* User Last Name</Box>
                <Box sx={{ position: "relative" }}>
                  <TextField required fullWidth variant="standard" sx={textFieldStyle} value={userInfo.familyName} InputProps={{ disableUnderline: true }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, "familyName")}
                  />
                </Box>
              </Grid>
              <Box width="100%" />
              <Grid item xs={3}>
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* User's Email Address</Box>
                <Box sx={{ position: "relative" }}>
                  <TextField disabled required fullWidth type="email" variant="standard" sx={textFieldStyle} value={userInfo.emailAddress} InputProps={{ disableUnderline: true }} />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* User's Phone Number</Box>
                <Box sx={{ position: "relative", display: "flex" }}>
                  <Grid item xs={4}>
                    <TextField required select fullWidth variant="standard" sx={textFieldStyle} value={countryPhone} InputProps={{ disableUnderline: true }} SelectProps={{ IconComponent: KeyboardArrowDownIcon }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setCountryPhone(event.target.value as Country);
                        setDisplaySubmitButton(true);
                      }}
                    >
                      { getCountries().map( m => ( <MenuItem key={m} value={m}> {`${m} +${getCountryCallingCode(m)}`} </MenuItem> )) }
                    </TextField>
                  </Grid>
                  <Grid item xs={8}>
                    <Box sx={{ position: "absolute", top: "50%", left: "calc(20px + 33%)", transform: "translate(0, -50%)", display: !phone ? "block" : "none" }}>
                      Enter User's Phone Number
                    </Box>
                    <TextField required fullWidth variant="standard" error={phoneError} helperText={phoneError ? "User's Phone is not valid" : ""} sx={textFieldStyle} value={phone} InputProps={{ disableUnderline: true }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setPhone(event.target.value);
                        setDisplaySubmitButton(true);
                        setPhoneError(false);
                      }}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={3} sx={{ display: userInfo.role === UserRoles.MotionAdmin || userInfo.role === UserRoles.MTLSEngineers ? 'none' : '' }}>
                <Box sx={{ textAlign: "left", marginBottom: "5px" }}>* Allowed Companies</Box>
                <Box sx={{ position: "relative" }}>
                  <Select multiple fullWidth sx={textFieldStyle} value={userInfo.allowedCompanies} IconComponent={KeyboardArrowDownIcon}
                    disabled={userInfo.role !== UserRoles.CustomerSupport && userInfo.role !== UserRoles.ProductionOperator}
                    renderValue={(selectedCompanies) => companyNames.filter((company) => selectedCompanies.includes(company.id)).map((company) => company.name).join(', ')}
                    onChange={(event: SelectChangeEvent<number[]>) => {
                      setUserInfo((oldState) => ({ ...oldState, allowedCompanies: event.target.value as number[] }));
                      setDisplaySubmitButton(true);
                    }}
                  >
                    {companyNames.map((company) => (
                      <MenuItem key={company.id} value={company.id}><Checkbox checked={userInfo.allowedCompanies!.indexOf(company.id) > -1} />{ company.name }</MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
            <Button disabled={!displaySubmitButton} sx={{ borderRadius: "5px", fontFamily: "Metronic Pro", width: "200px", margin: "10px 0 10px 0", textTransform: "none" }} size="small" type="submit" variant="contained">
              Submit
            </Button>
          </Box>
          <Popup title="Confirmation" description="Are you sure you want to perform this action?" approveText="YES" denyText="NO" open={displayAction} handleApprove={handleSubmit} handleDeny={() => setDisplayAction(false)} />
          <Popup title="Confirmation" description="User details updated sucessfully!" approveText="OK" open={displayConfirmation} handleApprove={handleConfirmation} />
        </form>
      </Box>
    </Box>
  );
}

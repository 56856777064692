import encryptService from '../Share/encryptService'

class storageWithEncryption {

    getDataFromLocalStorage(key: string) {
        let data = localStorage.getItem(key) || "";
        if(!data){
            return "";
        }
        return encryptService.ecryptWithSalt.decrypt(data);
    }
    setDataToLocalStorage(key: string, value: string) {
        localStorage.setItem(key, encryptService.ecryptWithSalt.encrypt(value));
    }
}
let storage = new storageWithEncryption();
export default storage;
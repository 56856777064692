import { Box, LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  GridToolbar,
} from "@mui/x-data-grid";
import "./Grid.css";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import GridHeaderText from "./GridHeader/GridHeaderText";
import GridHeaderList from "./GridHeader/GridHeaderList";
import GridHeaderDate from "./GridHeader/GridHeaderDate";
import GridHeaderTitle from "./GridHeader/GridHeaderTitle";
import { IFilter, IListRequestBody } from "../../../domain/Common/RequestBody";
import { IRequestResponse } from "../../../domain/Common/RequestResponse";
import storage from "../../../services/Share/storageWithEncryption";

type props = {
  getRowId: string;
  columnPreferencesName: string;
  defaultColumnPreferences: GridColumnVisibilityModel;
  columns: GridColDef[];
  paginationModel?: [GridPaginationModel, Dispatch<SetStateAction<GridPaginationModel>>];
  gridPageSize: number;
  paginationSize: number;
  isServerSideFilterData?: boolean;
  isServerSideOrderData?: boolean;
  getData: (body: IListRequestBody) => Promise<IRequestResponse<any>>;
  getFilters: () => Promise<IFilter[]>;
  onRowDoubleClick?: (params: any, event: any) => void;
  onCellClick?: (params: any, event: any) => void;

  isRowSelectable?: (params: GridRowParams) => boolean;
  onRowSelectionModelChange?: (newRowSelectionModel: GridRowSelectionModel) => void;
  rowSelectionModel?: GridRowSelectionModel;
  itemsCallback?: (items: any[]) => void;
  queryOptions?: [any, Dispatch<SetStateAction<any>>];
  translateFilters?: (filters: IFilter[]) => void;
  unTranslateFilters?: (filters: IFilter[]) => void;
};


export default function Grid(props: props) {
  let [queryOptions, setQueryOptions] = useState<IListRequestBody>({
    page: 0,
    pageSize: props.paginationSize,
    sortingDirection: 0,
    sortBy: "string",
    filters: [] as IFilter[],
  });
  if(!!props.queryOptions) [queryOptions, setQueryOptions] = props.queryOptions;
  let [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: props.gridPageSize,
  });
  if(!!props.paginationModel) [paginationModel, setPaginationModel] = props.paginationModel;

  const [isGettingNextPage, setIsGettingNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [filterList, setFilterList] = useState<IFilter[]>([]);
  const [columnPreferences, setColumnPreferences] =
    useState<GridColumnVisibilityModel>({});
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const userRole = storage.getDataFromLocalStorage("userRole");

  const onSortChange = (sortModel: GridSortModel) => {
    if (sortModel.length)
      setQueryOptions((s) => ({
        ...s,
        sortBy: sortModel[0].field,
        sortingDirection: sortModel[0].sort === "asc" ? 1 : 0,
      }));
    return;
  };

  const pageChangeHandler = function (e: number) {
    let relationQueryAndPageSize = props.paginationSize / props.gridPageSize;
    if (items.length / props.gridPageSize === e) {
      setIsGettingNextPage(true);
      setQueryOptions((s) => ({
        ...s,
        page: e / relationQueryAndPageSize,
        pageSize: props.paginationSize,
      }));
    }
  };

  useEffect(() => {
    props.unTranslateFilters?.(queryOptions.filters);
    setLoading(true);
    const getData = setTimeout(() => {
      props
        .getData?.(queryOptions)
        .then((data) => {
          if (isGettingNextPage) {
            data.items = items.concat(data.items);
          }
          setIsGettingNextPage(false);
          setItems(data.items);
          if(props.itemsCallback) props.itemsCallback(data.items);
          setRowCount(data.count);
          if (filterList.length === 0)
            props
              .getFilters?.()
              .then((filters) => {
                props.translateFilters?.(filters);
                setFilterList(filters);
              })
              .catch((error) => {
                setLoading(false);
                alert("Something happened getting the data");
              });
        }).then(() => {
          if (!queryOptions.page)
            setPaginationModel({
              page: 0,
              pageSize: props.gridPageSize
            })
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setIsGettingNextPage(false);
          alert("Something happened getting the data");
        });
    }, 600);
    setColumnPreferences(getColumnPreferences(props.columnPreferencesName));
    return () => clearTimeout(getData);
  }, [queryOptions]);

  function getColumnPreferences(columnPreferences: string) {
    const rawColumnPreferences =
      storage.getDataFromLocalStorage(columnPreferences);
    if (rawColumnPreferences) return JSON.parse(rawColumnPreferences);
    return props.defaultColumnPreferences;
  }

  function saveColumnPreferences(newPreferences: GridColumnVisibilityModel) {
    setColumnPreferences(newPreferences);
    storage.setDataToLocalStorage(
      props.columnPreferencesName,
      JSON.stringify(newPreferences)
    );
    return;
  }

  return (
    <Box id="data-grid-component" style={{ height: "100%", width: "100%" }}>
      <DataGrid
        {...items}
        columns={props.columns.map((column) => {
          if (column.description === "textFilter")
            return {
              ...column,
              renderHeader: () => (
                <Box sx={{ width: "100%" }}>
                  <GridHeaderText
                    field={column.field}
                    headerName={column.headerName!}
                    setQueryOptions={setQueryOptions}
                  />
                </Box>
              ),
            };
          if (column.description === "dateFilter")
            return {
              ...column,
              renderHeader: () => (
                <Box sx={{ width: "100%" }}>
                  <GridHeaderDate
                    field={column.field}
                    headerName={column.headerName!}
                    setQueryOptions={setQueryOptions}
                  />
                </Box>
              ),
            };
          if (column.description === "listFilter") {
            const filterItems = filterList.find(
              (item) =>
                item.filterType.toLowerCase() === column.field.toLowerCase()
            );

            return {
              ...column,
              renderHeader: () => (
                <Box sx={{ width: "100%" }}>
                  <GridHeaderList
                    field={column.field}
                    headerName={column.headerName!}
                    filterList={filterItems?.filterValues || []}
                    setQueryOptions={setQueryOptions}
                  />
                </Box>
              ),
            };
          }
          return {
            ...column,
            renderHeader: () => <GridHeaderTitle title={column.headerName!} />,
          };
        })}
        columnHeaderHeight={80}
        rowCount={rowCount ?? items.length}
        rows={items}
        rowBuffer={5}
        rowThreshold={5}
        rowHeight={25}
        loading={loading}
        checkboxSelection
        isRowSelectable={props.isRowSelectable ?? (_ => true) }
        onRowSelectionModelChange={props.onRowSelectionModelChange ?? ((newRowSelectionModel: GridRowSelectionModel) => { setRowSelectionModel(newRowSelectionModel); })}
        rowSelectionModel={props.rowSelectionModel ?? rowSelectionModel}
        pageSizeOptions={[50]}
        paginationModel={paginationModel}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        onPaginationModelChange={(pagination) => {
          pageChangeHandler(pagination.page);
          setPaginationModel(pagination);
        }}
        onRowDoubleClick={props.onRowDoubleClick}
        onCellClick={props.onCellClick}
        slots={{
          loadingOverlay: LinearProgress,
          toolbar: GridToolbar,
        }}
        className="data-grid-header"
        sortingMode={props.isServerSideOrderData ? "server" : undefined}
        onSortModelChange={
          props.isServerSideOrderData
            ? (sortModel) => onSortChange(sortModel)
            : undefined
        }
        disableColumnFilter
        columnVisibilityModel={columnPreferences}
        onColumnVisibilityModelChange={(
          newPreferences: GridColumnVisibilityModel
        ) => saveColumnPreferences(newPreferences)}
        getRowId={(row) => row[props.getRowId]}
        sx={{
          ".MuiDataGrid-iconButtonContainer": {
            position: "absolute",
            right: "0%",
          },
          ".MuiDataGrid-columnHeaderTitleContainer": {
            alignItems: "flex-start",
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '.MuiDataGrid-toolbarContainer > *': {
            fontSize: "0.7rem",
          },
          "div[role=columnheader]": {
            padding: "0 4px !important"
          },
          ".MuiBox-root :nth-last-child(2)": {
            padding: "0 !important"
          },
          ".MuiBox-root :last-child": {
            margin: "0 !important"
          },
          '&, [class^=MuiDataGrid]': {
            border: 'none'
          }
        }}
      />
    </Box>
  );
}

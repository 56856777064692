import cryptoJs from "crypto-js";

class encryptionService {
    private _key: string;
    constructor(key: string) {
        this._key = key;
    }

    public encrypt(value: string): string {
        return cryptoJs.AES.encrypt(value, this._key).toString();
    }

    public decrypt(value: string): string {
        const decrypted = cryptoJs.AES.decrypt(value, this._key);
        const plaintext = decrypted.toString(cryptoJs.enc.Utf8);
        return plaintext ? plaintext : 'Decryption failed';
    }
}

let encryptService = {
    ecryptWithSalt: new encryptionService("d#4pRST24y")
}

export default encryptService;